import { createSlice } from '@reduxjs/toolkit';
import { get } from 'lodash';

import { setToastsStateType, toastErrorType } from './Notification.types';

const initialState = Object.freeze([]);

const ToastsReducers = {
	setNotification(
		state: setToastsStateType[],
		{ payload }: { payload: { data: setToastsStateType; floIndex: number } }
	) {
		console.log('setNotification');
		const { type, message, canShow } = payload.data || {};
		state[payload.floIndex] = {
			type,
			message,
			canShow,
		};
	},
	setErrorToast(
		state: setToastsStateType[],
		{ payload }: { payload: { data: toastErrorType | unknown; floIndex: number } }
	) {
		const messageStatus = get(payload, 'data.response.status', 0);
		const redirectOnError = get(payload, 'data.response.data.redirectOnError', false);
		const message =
			get(payload, 'data.response.data.data') || get(payload, 'data.message');
		if (
			!message ||
			(redirectOnError && [404, 403, 500].includes(messageStatus)) ||
			[412, 402].includes(messageStatus)
		)
			return;
		state[get(payload, 'floIndex', 0)] = { type: 'error', message, canShow: true };
	},
	setSuccessToast(
		state: setToastsStateType[],
		{ payload }: { payload: { data: toastErrorType | unknown; floIndex: number } }
	) {
		const message =
			get(payload, 'data.response.data.data') || get(payload, 'data.message');
		if (!message) return;

		state[get(payload, 'floIndex', 0)] = { type: 'success', message, canShow: true };
	},
};

export const Toasts = createSlice({
	name: 'Toast',
	initialState,
	//@ts-ignore
	reducers: ToastsReducers,
});

export const setNotification = Toasts.actions.setNotification;
export const setErrorToast = Toasts.actions.setErrorToast;
export const setSuccessToast = Toasts.actions.setSuccessToast;

export default Toasts.reducer;
