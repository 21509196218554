import { RootState } from '../store';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import { PUBLISH_ASSETS_BASE_URL } from './Common.env';

export const currentProfileSelector = (state: RootState) => ({
	name: get(state, 'routerContainer.profile.name'),
	userId: get(state, 'routerContainer.profile.userId'),
	profilePicUrl: get(state, 'routerContainer.profile.userProfile.profilePicUrl'),
	avatarColor: get(
		state,
		'routerContainer.profile.userProfile.profilePreference.avatarColor'
	),
	profilePreference: {
		avatarColor: get(
			state,
			'routerContainer.profile.userProfile.profilePreference.avatarColor'
		),
	},
	email: get(state, 'routerContainer.profile.email'),
	is_anonymous: get(state, 'routerContainer.profile.anonymous'),
});

export const enablePoweredBYSelector = (state: RootState): boolean =>
	!get(state, 'floPage.floSpace.appConfig.hidePoweredByFloik', false);

export const enableTryFloikSelector =
	(floIndex: number) =>
	(state: RootState): boolean => {
		const hideHeader = get(state, `floPage.flos.${floIndex}.floDetails["hide-header"]`);
		const showTryFloik = hideHeader
			? false
			: get(state, `floPage.flos.${floIndex}.floDetails.internalArticles`, false);
		// hideHeader ? false
		return showTryFloik || !get(state, 'floPage.floSpace.appConfig.hideTryFloik', false);
	};

export const headerBrandImageSelector =
	(floIndex: number) =>
	(state: RootState): string => {
		const internalArticles = get(
			state,
			`floPage.flos.${floIndex}.floDetails.internalArticles`,
			false
		);
		const hideHeaderLogo = get(
			state,
			'floPage.floSpace.branding.headerConfigurationData.hideHeaderLogo',
			false
		);
		if (hideHeaderLogo && !internalArticles) {
			return '';
		}
		return (
			internalArticles
				? `${PUBLISH_ASSETS_BASE_URL}/images/floik-logo.svg`
				: get(state, 'floPage.floSpace.branding.headerConfigurationData.imgPath', '')
		) as string;
	};

export const watermarkBrandImageSelector =
	(floIndex: number) =>
	(state: RootState): string => {
		const internalArticles = get(
			state,
			`floPage.flos.${floIndex}.floDetails.internalArticles`,
			false
		);
		// const customWatermarkDisabled = get(state, 'floPage.floSpace.branding.watermarkConfig.customWatermarkDisabled', false)
		// if (!internalArticles) {
		// 	return '';
		// }
		return (
			internalArticles
				? `${PUBLISH_ASSETS_BASE_URL}/images/floik-logo.svg`
				: get(
						state,
						`floPage.flos.${floIndex}.floSpace.branding.watermarkConfig.imgPath`,
						''
				  )
		) as string;
	};
