import { createSlice } from '@reduxjs/toolkit';

const initialState = Object.freeze({});

interface LogReducerType {}

const logReducer = {
	analyticsLog(
		state: LogReducerType,
		{
			payload,
		}: {
			payload: { level?: string; message?: string; value?: any; code?: string };
		}
	) {},
};

export const Log = createSlice({
	name: 'logs',
	initialState,
	//@ts-ignore
	reducers: logReducer,
});

export const analyticsLog = Log.actions.analyticsLog;

export default Log.reducer;
