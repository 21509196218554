import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Paper } from '@mui/material';
import { get } from 'lodash';
import styles from './Authenticated.module.css';
import Flo from '../../Pages/Flo/Flo';
import AppObserver from '../../Components/AppObserver/AppObserver';
import { isMicroApp } from '../../Common/Common.utils';
import { externalAppInfoType } from '../../Common/Common.types';

const AuthenticatedRoute = (props: {
	externalAppInfo?: externalAppInfoType;
	floSpaceId?: string;
}) => {
	return (
		<Paper className={styles.container}>
			<Paper className={styles.routeContent}>
				<Routes>
					<Route
						path="*"
						element={
							<Flo
								externalAppInfo={get(props, 'externalAppInfo')}
								floSpaceId={get(props, 'floSpaceId')}
							/>
						}
					/>
				</Routes>
				{isMicroApp() && <AppObserver />}
			</Paper>
		</Paper>
	);
};
export default AuthenticatedRoute;
