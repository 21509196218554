import { get } from 'lodash';
import { isMicroApp } from '../../../Common/Common.utils';

interface FloSpaceConfigOld {
	customWatermarkConfiguration: {
		customWatermarkFilePath: string;
		customWatermarkDisabled: boolean;
		defaultWatermarkEnabled: boolean;
	};
	brandColor: string;
	watermarkPosition: string;
	customGlobalScriptEnabled: boolean;
	hidePoweredByFloik: boolean;
}

interface FloSpaceConfigVersion2 {
	version: 2;
	branding: {
		brandColor: string;
		watermarkConfig: {
			imgPath: string;
			position: string;
			customWatermarkDisabled: boolean;
		};
		headerConfigurationData: {
			hideHeaderLogo: boolean;
			logoHyperLink?: string;
			imgPath: string;
		};
	};
	appConfig: {
		customGlobalScriptEnabled: boolean;
		hidePoweredByFloik: boolean;
		hideTryFloik: boolean;
	};
}

export interface FloSpaceConfigOutput {
	branding: {
		brandColor: string;
		watermarkConfig: {
			imgPath: string;
			position: string;
			customWatermarkDisabled: boolean;
		};
		headerConfigurationData: {
			hideHeaderLogo: boolean;
			logoHyperLink?: string;
			imgPath: string;
		};
	};
	appConfig: {
		customGlobalScriptEnabled: boolean;
		hidePoweredByFloik: boolean;
		hideTryFloik: boolean;
	};
	flospaceId: string | null;
	isDomainValid: boolean;
}
const isMicroFlo = isMicroApp();

const parseVersion2 = (data: FloSpaceConfigVersion2): FloSpaceConfigOutput => {
	if (isMicroFlo) {
		data.appConfig.hidePoweredByFloik = true;
		data.appConfig.hideTryFloik = true;
		data.branding.headerConfigurationData.hideHeaderLogo = true;
		data.branding.watermarkConfig.customWatermarkDisabled = true;
		data.branding.watermarkConfig.imgPath = null;
	}

	return data;
};

const parseOldVersion = (data: FloSpaceConfigOld): FloSpaceConfigOutput => {
	const hidePoweredByFloik = isMicroFlo || get(data, 'hidePoweredByFloik', false);
	const hideTryFloik = isMicroFlo || get(data, 'hideTryFloik', false);
	const hideLogo = isMicroFlo || false;
	const waterMark =
		isMicroFlo || get(data, 'customWatermarkConfiguration.customWatermarkDisabled');
	const waterMarkImgPath = isMicroFlo
		? null
		: get(data, 'customWatermarkConfiguration.customWatermarkFilePath');

	return {
		branding: {
			brandColor: get(data, 'brandColor'),
			watermarkConfig: {
				imgPath: waterMarkImgPath,
				position: get(data, 'watermarkPosition'),
				customWatermarkDisabled: waterMark,
			},
			headerConfigurationData: {
				hideHeaderLogo: hideLogo,
				logoHyperLink: 'https://www.floik.com',
				imgPath: get(data, 'customWatermarkConfiguration.customWatermarkFilePath'),
			},
		},
		appConfig: {
			customGlobalScriptEnabled: false,
			hidePoweredByFloik: hidePoweredByFloik,
			hideTryFloik: hideTryFloik,
		},
	};
};

export const parseFloSpaceJson = (data: FloSpaceConfigOld | FloSpaceConfigVersion2) => {
	switch (String(get(data, 'version', 0))) {
		case '2.0':
		case '2': {
			return parseVersion2(data as FloSpaceConfigVersion2);
		}
		default: {
			return parseOldVersion(data as FloSpaceConfigOld);
		}
	}
};
