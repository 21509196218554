import React, { useCallback, useEffect, useState } from 'react';
import { getIsMobileView, getIsTabView } from './Common.utils';

export function useOffClick(cb: Function, ref: React.MutableRefObject<HTMLDivElement>) {
	const windowClickListener = useCallback(
		(event: Event) => {
			const el = ref?.current as HTMLDivElement;
			const target = event.target as Node;
			if (!el || el.contains(target) || el === target) {
				return;
			}
			cb?.(event);
		},
		[cb, ref]
	);

	useEffect(() => {
		window.addEventListener('click', windowClickListener);
		return () => {
			window.removeEventListener('click', windowClickListener);
		};
	}, [windowClickListener]);
}

export function useIsMobileView(onResize?: Function) {
	const [isMobileView, setIsMobileView] = useState(false);

	const onResizeHandler = useCallback(() => {
		const newValue = getIsMobileView();
		setIsMobileView(newValue);
		if (onResize) onResize(newValue);
	}, [setIsMobileView, onResize]);

	useEffect(() => {
		window.addEventListener('resize', onResizeHandler);
		return () => {
			window.removeEventListener('resize', onResizeHandler);
		};
	}, [onResizeHandler]);

	return isMobileView;
}

export function useIsTabView(onResize?: Function, floId?: string) {
	const [isTabView, setIsTabView] = useState(getIsTabView(floId));

	const onResizeHandler = useCallback(() => {
		const newValue = getIsTabView(floId);
		setIsTabView(newValue);
		if (onResize) onResize(newValue);
	}, [setIsTabView, onResize]);

	useEffect(() => {
		window.addEventListener('resize', onResizeHandler);
		return () => {
			window.removeEventListener('resize', onResizeHandler);
		};
	}, [onResizeHandler]);

	return isTabView;
}
