import React, { useCallback, useEffect, useState } from 'react';
import { Player } from '@lottiefiles/react-lottie-player';

import styles from './Loader.module.css';
import infinity from '../../animation/infinity.json';
import classNames from 'classnames';

const Loader = ({
	className,
	wrapperClass,
	height = '100px',
	width = '100px',
	fullPage = false,
	nodelay = false,
}: {
	className?: string;
	wrapperClass?: string;
	height?: string;
	width?: string;
	fullPage?: boolean;
	nodelay?: boolean;
}) => {
	const [showLoader, setShowLoader] = useState(false);
	const [mounted, setMounted] = useState(true);
	const timeoutCallback = useCallback(() => {
		setShowLoader(mounted);
	}, [showLoader, mounted, setShowLoader]);
	useEffect(() => {
		let timeout: string | number | NodeJS.Timeout | undefined;
		if (nodelay) {
			timeoutCallback();
		} else {
			timeout = setTimeout(timeoutCallback, 3000);
		}
		return () => {
			if (timeout) clearTimeout(timeout);
			setMounted(false);
		};
	}, [setShowLoader, nodelay]);
	if (!showLoader) return <></>;
	const content = (
		<Player
			autoplay
			loop
			src={infinity}
			style={{ height, width }}
			className={classNames(
				{ [styles.infinity]: !fullPage },
				{ [styles.fullPageInfinity]: fullPage },
				className
			)}
		/>
	);
	let actualContent = content;
	if (fullPage)
		actualContent = (
			<div className={styles.loaderOverlay}>
				<div className={styles.loaderContainer}>{content}</div>
			</div>
		);

	if (wrapperClass) return <div className={wrapperClass}>{actualContent}</div>;
	return actualContent;
};

export default Loader;
