import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import styles from './Portal.module.css';

const Portal = ({
	children,
	target,
}: {
	children: React.ReactNode;
	target: HTMLElement;
}) => {
	const [mount, setMount] = useState(target);
	const [el, setElem] = useState(document.createElement('div'));

	useEffect(() => {
		const mount = target;
		const elem: HTMLDivElement = document.createElement('div');
		setMount(mount);
		setElem(elem);
		elem.classList.add(styles.wrapper);
		if (mount) mount.appendChild(elem);
		return () => {
			if (mount) {
				mount.removeChild(elem);
			}
		};
	}, [setMount, setElem, target]);

	return createPortal(children, el);

	// return createPortal(<div className={styles.wrapper}>{children}</div>, target);
};

export default Portal;
