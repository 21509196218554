import store from '../../store';
import { call, debounce, delay, ForkEffect, put, takeLatest } from 'redux-saga/effects';
import API from '../../utils/API';
import {
	authSuccess,
	checkIsFloTokenValid,
	checkIsLoggedInAction,
	createAnonymousUserAction,
	leaveRoutesAction,
	logoutAction,
	onAuthSuccessAction,
	refreshTokenAction,
	sendOtpAction,
	setAnonymousAction,
	setAuthAction,
	setAuthTokenAction,
	setFloAccessToken,
	updateThemeAction,
	updateThemeSuccessAction,
	validateOtp,
} from './Routes.reducers';
import Cookie, { CookieAttributes } from 'js-cookie';
import { get } from 'lodash';
import { SagaIterator } from 'redux-saga';
import { CancelSagas } from '../../utils/saga.utils';
import { AccessTokenResponse } from './Routes.types';
import { API_BASE } from '../../Common/Common.env';
import { analyticsLog } from '../../store/reducers/Log.reducer';
import { trackViewCountAction } from '../../Pages/Flo/Flo.reducers';
import {
	setErrorToast,
	setSuccessToast,
} from '../../Components/Notification/Toasts.reducers';

const currentDate = new Date();
const futureDate = new Date(currentDate.getTime() + 5 * 24 * 60 * 60 * 1000);

export const COOKIE_PARAMS = {
	sameSite: 'none',
	secure: true,
	expires: futureDate,
} as CookieAttributes;

function* onAuthSuccess(response: { data: AccessTokenResponse }): SagaIterator {
	const authToken = get(response, 'data.authToken');
	const refreshToken = get(response, 'data.refreshToken');
	const userName = get(response, 'data.userName');
	const userId = get(response, 'data.userId');
	Cookie.set('floik-id', userId || '', COOKIE_PARAMS);
	Cookie.set('floik-end-user-v2-token', authToken || '', COOKIE_PARAMS);
	Cookie.set('floik-end-user-v2-refreshToken', refreshToken || '', COOKIE_PARAMS);

	yield put(
		onAuthSuccessAction({
			authToken,
			refreshToken,
			userName,
			userId,
		})
	);
}

function* createAnonymousUser(): SagaIterator {
	try {
		const response = yield call(
			new API(undefined, true, undefined, true).put,
			`${API_BASE}/v1/authentication/anonymous-user`, //?tokenExpiryTimeInSeconds=120
			{}
		);
		yield call(onAuthSuccess, response);
		yield put(setAnonymousAction('true'));
	} catch (e) {
		// console.error(e);
		yield put(
			analyticsLog({
				level: 'error',
				message: `createAnonymousUser ${location.href}`,
				value: get(e, 'stack'),
			})
		);
		yield put(setAuthAction(false));
	}
}

function* validateOtpSaga({
	payload,
}: {
	payload: { floId: string; email: string; otp: string; floIndex: number };
}): SagaIterator {
	try {
		const response = yield call(
			new API().put,
			`${API_BASE}/v1/assistance/authentication/${payload.floId}/validate-otp?emailId=${payload.email}&otp=${payload.otp}`, //?tokenExpiryTimeInSeconds=120
			undefined
		);

		if (response.data) {
			yield put(
				trackViewCountAction({
					id: get(payload, 'publishedFloId') || get(payload, 'floId'),
					floId: get(payload, 'floId'),
					floIndex: get(payload, 'floIndex'),
				})
			);

			localStorage.setItem(`floik-${payload.floId}-token`, response.data);
			yield put(
				setFloAccessToken({
					data: {
						floAuthToken: response.data,
						tokenValid: true,
					},
					floIndex: get(payload, 'floIndex'),
				})
			);
			yield put(
				setSuccessToast({
					data: { message: 'Authentication successful.' },
					floIndex: get(payload, 'floIndex'),
				})
			);
		}
	} catch (e) {
		// console.error(e);
		if (get(e, 'response.status') === 403) {
			yield put(
				setErrorToast({
					data: { message: 'Authentication failed, invalid OTP.' },
					floIndex: get(payload, 'floIndex'),
				})
			);
		}
		yield put(
			analyticsLog({
				level: 'error',
				message: `Valid otp for email ${payload.email}, ${location.href}`,
				value: get(e, 'stack'),
			})
		);
	}
}

function* checkIsFloTokenValidSaga({
	payload,
}: {
	payload: { token: string; floId: string; floIndex: string };
}): SagaIterator {
	try {
		const response = yield call(
			new API().put,
			`${API_BASE}/v1/assistance/authentication/${payload.floId}/validate-token?token=${payload.token}`,
			undefined
		);
		yield put(
			setFloAccessToken({
				data: {
					tokenValid: response.data,
					floAuthToken: payload.token,
					floIndex: payload.floIndex,
				},
				floIndex: payload.floIndex,
			})
		);
	} catch (e) {
		// console.error(e);
		localStorage.setItem(`floik-${payload.floId}-token`, '');
		yield put(
			setFloAccessToken({
				data: {
					tokenValid: false,
					floAuthToken: '',
				},
				floIndex: get(payload, 'floIndex'),
			})
		);
		yield put(
			analyticsLog({
				level: 'error',
				message: `Check flo token valid ${payload.floId}, ${location.href}`,
				value: get(e, 'stack'),
			})
		);
	}
}

function* sendOtpSaga({
	payload,
}: {
	payload: {
		floId: string;
		email: string;
		cb(): void;
		resend?: boolean;
		floIndex: number;
	};
}): SagaIterator {
	try {
		const response = yield call(
			new API().put,
			`${API_BASE}/v1/assistance/authentication/${payload.floId}/otp?emailId=${payload.email}`,
			undefined
		);
		if (payload.resend) {
			yield put(
				setSuccessToast({
					data: { message: 'OTP resent successfully. Please check your inbox.' },
					floIndex: get(payload, 'floIndex'),
				})
			);
		}
		payload.cb();
	} catch (e) {
		// console.error(e);
		if (get(e, 'response.status') === 403) {
			yield put(
				setErrorToast({
					data: { message: 'Authentication failed, invalid email.' },
					floIndex: get(payload, 'floIndex'),
				})
			);
		}
		yield put(
			analyticsLog({
				level: 'error',
				message: `Send otp to email ${payload.email}, ${location.href}`,
				value: get(e, 'stack'),
			})
		);
	}
}

function* checkIsLoggedIn({ type }: { type: string }): SagaIterator {
	try {
		const authToken = Cookie.get('floik-end-user-v2-token');
		const anonymous = Cookie.get('floik-anonymous');
		const theme = Cookie.get('floik-theme') || 'light';

		if (!authToken) {
			yield put(createAnonymousUserAction({}));
			return;
		}

		yield put(updateThemeAction(theme));
		yield put(setAuthTokenAction(authToken));
		yield put(setAuthAction(true));
		yield put(setAnonymousAction(anonymous === 'true'));
	} catch (e) {
		// console.error(e);
		yield put(
			analyticsLog({
				level: 'error',
				message: `checkIsLoggedIn ${location.href}`,
				value: get(e, 'stack'),
			})
		);
	}
}

function* logout() {
	try {
		const token = Cookie.get('floik-end-user-v2-token');
		const userId = Cookie.get('floik-id');
		Cookie.remove('floik-end-user-v2-token');
		Cookie.set('floik-anonymous', 'true', COOKIE_PARAMS);
		Cookie.remove('floik-end-user-v2-refreshToken');
		Cookie.remove('floik-theme');
		Cookie.remove('userName');
		yield put(updateThemeAction('light'));
		yield put(setAuthAction(false));
		// @ts-ignore
	} catch (e) {
		// console.error('error', e);
		yield put(
			analyticsLog({
				level: 'error',
				message: `logout ${location.href}`,
				value: get(e, 'stack'),
			})
		);
	}
}

export function* refreshTokenSaga() {
	try {
		const body = {
			authToken: Cookie.get('floik-end-user-v2-token'),
			refreshToken: Cookie.get('floik-end-user-v2-refreshToken'),
		};
		const response: { data: AccessTokenResponse } = yield call(
			new API(undefined, true).post,
			`${API_BASE}/v1/authentication/refresh-token`,
			body
		);
		yield call(onAuthSuccess, response);
		// location.reload();
		// yield put(authSuccess({}));
	} catch (e) {
		// console.error('error');
		yield put(
			analyticsLog({
				level: 'error',
				message: `refreshTokenSaga ${location.href}`,
				value: get(e, 'stack'),
			})
		);
	}
}

export function* updateThemeSaga({ payload }: { payload: string }) {
	const theme = payload || 'light';
	document.body.setAttribute('theme', theme);
	Cookie.set('floik-theme', theme, COOKIE_PARAMS);
	yield put(updateThemeSuccessAction(theme));
}

export function* userRootSagas(): Generator<ForkEffect> {
	const tasks = [
		yield takeLatest(checkIsLoggedInAction.type, checkIsLoggedIn),
		yield takeLatest(createAnonymousUserAction.type, createAnonymousUser),
		// @ts-ignore
		yield takeLatest(sendOtpAction.type, sendOtpSaga),
		// @ts-ignore
		yield takeLatest(validateOtp.type, validateOtpSaga),
		yield takeLatest(logoutAction.type, logout),
		// @ts-ignore
		yield takeLatest(updateThemeAction.type, updateThemeSaga),
		// @ts-ignore
		yield takeLatest(checkIsFloTokenValid.type, checkIsFloTokenValidSaga),
		yield debounce(1000, refreshTokenAction.type, refreshTokenSaga),
	];
	// @ts-ignore
	yield takeLatest(leaveRoutesAction.type, CancelSagas, tasks);
}

export function runRoutesSagas() {
	// @ts-ignore
	store.runSaga(userRootSagas);
}
