import { Button, Grid, IconButton } from '@mui/material';
import React from 'react';
import styles from './InternalArticleFooter.module.css';
import { IconBrandTwitterFilled } from '@tabler/icons-react';
import { APP_ENDPOINT, PUBLISH_ASSETS_BASE_URL } from '../../Common/Common.env';

const InternalArticleFooter = () => {
	return (
		<footer className={styles.footer}>
			<Grid container className={styles.container}>
				<Grid item className={styles.leftContainer}>
					<div className={styles.logo}>
						<a className={styles.logoAnchor} target="_blank" href="https://www.floik.com">
							<img
								className={styles.logoImage}
								src={`${PUBLISH_ASSETS_BASE_URL}/images/floik-logo.svg`}
								alt="Floik logo"
							/>
						</a>
					</div>
					<div className={styles.floikText}>
						Floik helps in creating customized self-serve interactive demos, product
						videos and step-by-step guides in minutes on a single platform
					</div>
				</Grid>
				<Grid item className={styles.rightContainer}>
					<div className={styles.shareButtonsContainer}>
						<IconButton
							component="a"
							target="_blank"
							href={'https://twitter.com/getfloik?lang=en'}
							className={styles.shareButton}
						>
							<IconBrandTwitterFilled size={16} />
						</IconButton>
						<IconButton
							component="a"
							target="_blank"
							href={'https://www.linkedin.com/company/floik'}
							className={styles.shareButton}
						>
							<svg
								width="17"
								height="16"
								viewBox="0 0 17 16"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M0.208047 5.10047H3.7241V15.6814H0.208047V5.10047ZM1.98961 0C0.786211 0 0 0.790039 0 1.82699C0 2.84262 0.763203 3.65562 1.94359 3.65562H1.96602C3.19242 3.65562 3.95617 2.84258 3.95617 1.82699C3.93316 0.790039 3.19246 0 1.98961 0ZM12.357 4.85191C10.4906 4.85191 9.65449 5.87848 9.18805 6.5984V5.10047H5.67094C5.7175 6.09309 5.67094 15.6814 5.67094 15.6814H9.18805V9.77227C9.18805 9.45578 9.21105 9.14047 9.30359 8.91375C9.55824 8.28195 10.1369 7.6277 11.1076 7.6277C12.3811 7.6277 12.8897 8.59844 12.8897 10.0202V15.6814H16.4062V9.61406C16.4062 6.3641 14.6718 4.85191 12.357 4.85191Z"
									fill="#232633"
								/>
							</svg>
						</IconButton>
						<Button
							variant="contained"
							className={styles.tryFloikButton}
							href={APP_ENDPOINT}
							component="a"
							target="_blank"
							disableElevation
						>
							Try Floik
						</Button>
					</div>
				</Grid>
			</Grid>
		</footer>
	);
};

export default InternalArticleFooter;
