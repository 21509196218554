import { find, forEach, get, includes, isNumber, map, max, min, reduce } from 'lodash';
import {
	CropStateType,
	floTrackType,
	pauseTimeType,
	publishElementsDataType,
	SwiftPlayImageType,
} from '../Pages/Flo/Flo.types';
import linkifyStr from 'linkify-string';
import {
	MAX_MOBILE_VIEW_HEIGHT_IN_LANDSCAPE,
	MAX_MOBILE_VIEW_WIDTH,
	MAX_TABLET_VIEW_WIDTH,
} from './Common.params';
import qs, { ParsedQs } from 'qs';
import { scaleLinear } from 'd3-scale';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

const BGM_MIN_VOLUME_MULTIPLIER = 0.1;

interface ResourceType {
	extension: string;
	status: string;
	url: string;
	id: string;
	resourceType: string;
	distributionUrl: string;
	resourceObject: string;
	resourceProperties: {
		recordTimeInSeconds: number;
		hasAudio: boolean;
		time: number;
	};
	hasThumbnails: boolean;
	thumbnailsPath: string;
	cookieDefinition: unknown;
}

interface GroupResourceType {
	type: string;
	resources: ResourceType[];
}

interface ResourceGroupTrackResponse {
	id: string;
	floId: string;
	resources?: ResourceType[];
	resourceGroupDetailList?: GroupResourceType[];

	type: string;
}

export interface Subtitle {
	startTime: number;
	endTime: number;
	text: string;
}

function parseTime(timeString: string): number {
	const [hours, minutes, seconds] = timeString.split(':');
	const [secondsPart, milliseconds] = seconds.split('.');
	return (
		parseInt(hours) * 3600 +
		parseInt(minutes) * 60 +
		parseInt(secondsPart) +
		parseFloat('0.' + milliseconds)
	);
}

export function parseVttFile(vttText: string) {
	const lines = vttText.trim().split(/\r?\n/);

	if (lines[0].toUpperCase() === 'WEBVTT') {
		lines.shift();
	}

	const cues: Subtitle[] = [];
	let cue: Subtitle | null = null;
	let cueText = '';

	for (let i = 0; i < lines.length; i++) {
		const line = lines[i].trim();

		if (!cue && line.includes('-->')) {
			cue = {} as Subtitle;
			const [startTime, endTime] = line.split('-->');
			cue.startTime = parseTime(startTime);
			cue.endTime = parseTime(endTime);
			cueText = '';
		} else if (cue && line === '' && cueText.trim()) {
			cue.text = cueText.trim();
			cues.push(cue);
			cue = null;
		} else if (cue) {
			cueText += line + ' ';
		}
		if (cue && cueText && lines.length - 1 === i) {
			cue.text = cueText.trim();
			cues.push(cue);
		}
	}
	return cues;
}

export type mergedType = {} & floTrackType & {
		images: Array<SwiftPlayImageType>;
		floImageElements: {
			[key: string]: string;
		};
	};

export function transformResourceGroupToUISourceList(
	data: ResourceGroupTrackResponse[]
): mergedType[] {
	let hasAudioTrack = false;

	// @ts-ignore
	return map(data, (item) => {
		const values = reduce(
			item.resources,
			(acc, resource: ResourceType) => {
				if (includes(resource.extension, 'm3u8')) {
					acc.hlsURL = resource.url;
					if (get(resource, 'resourceProperties.recordTimeInSeconds')) {
						acc.duration = get(resource, 'resourceProperties.recordTimeInSeconds');
					}
					if (get(resource, 'resourceProperties.height')) {
						acc.height = get(resource, 'resourceProperties.height', 0);
					}
					if (get(resource, 'resourceProperties.width')) {
						acc.width = get(resource, 'resourceProperties.width', 0);
					}
				} else if (includes(resource.extension, 'webm')) {
					acc.url = resource.url;
				}
				if (resource.hasThumbnails) {
					acc.hasThumbnails = resource.hasThumbnails;
					acc.thumbnailsPath = resource.thumbnailsPath;
				}
				if (
					includes(resource.extension, 'png') ||
					includes(resource.extension, 'jpg') ||
					includes(resource.extension, 'jpeg') ||
					includes(resource.extension, 'gif') ||
					includes(resource.extension, 'svg')
				) {
					const id =
						get(resource, 'resourceProperties.elementId', '') ||
						get(resource, 'resourceProperties.imageId', '');

					if (id) {
						// @ts-ignore
						acc.floImageElements[id] = get(resource, 'url');
					}
				}
				if (includes(resource.resourceType, 'HOTSPOT_IMAGE_CAPTURE_PUBLISHED')) {
					// @ts-ignore
					acc.images.push({
						id: resource.id,
						url: resource.url,
						resourceType: resource.resourceType,
						time: Math.round(get(resource, 'resourceProperties.time') * 100) / 100,
					} as SwiftPlayImageType);
				}
				if (includes(resource.resourceType, 'THUMBNAIL')) {
					acc.hasThumbnails = true;
					acc.thumbnailsPath = resource.url;
				}

				if (includes(resource.resourceType, 'TRANSCRIBE')) {
					acc.subtitleUrl = resource.url;
				}

				if (resource.cookieDefinition) {
					// @ts-ignore
					acc.cookieDefinition = resource.cookieDefinition;
				}
				if (
					includes(
						['PUBLISHED_BACKGROUND_MUSIC', 'PUBLISHED_STATIC_BACKGROUND_MUSIC'],
						resource.resourceType
					)
				) {
					acc.backgroundMusicResource = {
						...resource.resourceProperties,
						url: resource.url,
						textPauses: get(resource, 'resourceProperties.speechSegments', []),
					};
				}
				if (includes(resource.resourceType, 'PUBLISH_ELEMENT_VOICE_OVER')) {
					const key = `${get(resource, 'resourceProperties.scriptId', '')}_${get(
						resource,
						'resourceProperties.voiceId',
						''
					)}`;
					acc.elementVoiceResources = {
						...acc.elementVoiceResources,
						[key]: {
							...get(resource, 'resourceProperties', {}),
							url: get(resource, 'url'),
						},
					};
				}

				acc.distributionUrl = get(resource, 'distributionUrl');
				acc.resourceObject = get(resource, 'resourceObject');
				if (get(resource, 'resourceProperties.hasAudio')) {
					acc.hasAudio = get(resource, 'resourceProperties.hasAudio');
				}

				return acc;
			},
			{
				images: [],
				floImageElements: {},
				distributionUrl: '',
				resourceObject: '',
				hlsURL: '',
				url: '',
				subtitleUrl: '',
				duration: 0,
				height: 0,
				width: 0,
				hasThumbnails: false,
				hasAudio: false,
				thumbnailsPath: '',
				cookieDefinition: undefined,
				elementVoiceResources: {},
				backgroundMusicResource: {},
			}
		);
		return {
			...values,
			id: item.id,
			type: item.type,
		};
	});
}

export const linkify = (t: string) => {
	return linkifyStr(t, {
		target: '_blank',
		validate: true,
	});
};

export const convertNodesToString = (nodes: ChildNode[]) => {
	if (!nodes) return '';
	//@ts-ignore
	return nodes?.map((node) => node.outerHTML || node.nodeValue).join('');
};

export const convertStringToNodes = (s: string) => {
	const doc = new DOMParser().parseFromString(s || '', 'text/html');
	return Array.from(doc.body.childNodes);
};

export const sanitizeHtml = (str: string) => {
	if (!str) return '';
	let s = (str || '').replace(/<br>/g, '').trim();
	if (s === '<p></p>' || s === '<p><br></p>' || s === '<p><br/></p>') {
		return '';
	}

	const outArr: ChildNode[] = [];
	const nodeArr = convertStringToNodes(s);
	nodeArr.forEach((node) => {
		if (node.hasChildNodes() && node.nodeName !== 'A') {
			const arr = Array.from(node.childNodes);
			// @ts-ignore
			const sanitizedArr = arr.map(
				(
					child // @ts-ignore
				) => sanitizeHtml(child?.outerHTML || child.nodeValue)
			);
			return outArr.push(...convertStringToNodes(sanitizedArr.join('')));
		}
		if (node.nodeName === '#text') {
			const str = convertNodesToString([node]);
			const link = linkify(str);
			return outArr.push(...convertStringToNodes(link));
		}
		if (node.nodeName === 'A') {
			// @ts-ignore
			node.setAttribute('target', '_blank');
		}
		return outArr.push(node);
	});
	return convertNodesToString(outArr);
};

const modifyAnchorTags = (nodes: ChildNode[]): ChildNode[] => {
	const modifiedNodes: ChildNode[] = [];
	for (const node of nodes) {
		if (node.nodeType === Node.ELEMENT_NODE && node.nodeName === 'A') {
			// @ts-ignore
			node.setAttribute('target', '_blank');
			modifiedNodes.push(node);
		} else if (node.hasChildNodes()) {
			const modifiedChildren = modifyAnchorTags(Array.from(node.childNodes));
			const newNode = node.cloneNode(false);
			for (const child of modifiedChildren) {
				newNode.appendChild(child);
			}
			// @ts-ignore
			modifiedNodes.push(newNode);
		} else {
			modifiedNodes.push(node);
		}
	}
	return modifiedNodes;
};

export const sanitizeHtmlLinks = (str: string) => {
	if (!str) return '';
	let s = (str || '').trim();
	if (s === '<p></p>' || s === '<p><br></p>' || s === '<p><br/></p>') {
		return '';
	}

	const doc = new DOMParser().parseFromString(s || '', 'text/html');
	// @ts-ignore
	const modifiedNodes = modifyAnchorTags(doc.body.childNodes);
	return convertNodesToString(modifiedNodes);
};

export const getWindowDimensions = (floIndex?: number) => {
	const elements = document.querySelectorAll(`floik-flo`);
	if (elements.length > 0 && isNumber(floIndex) && floIndex >= 0) {
		return {
			width: get(elements, `[${floIndex}].clientWidth`),
			height: get(elements, `[${floIndex}].clientHeight`),
		};
	}
	return {
		width: window.innerWidth,
		height: window.innerHeight,
	};
};

export const getIsMobileView = () =>
	(window.innerWidth <= MAX_MOBILE_VIEW_WIDTH &&
		window.innerWidth >= 330 &&
		window.innerHeight >= 300 &&
		window.innerHeight > window.innerWidth) ||
	(window.innerHeight <= MAX_MOBILE_VIEW_HEIGHT_IN_LANDSCAPE &&
		window.innerHeight < window.innerWidth &&
		window.innerWidth >= 600 &&
		window.innerHeight >= 200 &&
		!!window.screen?.orientation?.angle &&
		window.screen?.orientation?.angle !== 0);

export const isMicroApp = () => {
	return (
		document.querySelectorAll('[doc360-app="true"]').length > 0 ||
		document.querySelectorAll('[data-micro-view="true"]').length > 0
	);
};

export const ignoreSecurityCheck = () => {
	const isOldFlo =
		!!document.getElementById('FLOIK_JSON') ||
		document.querySelectorAll('[data-f-id*=_]').length > 0;
	const whiteListedDomains =
		(process.env.REACT_APP_ENVIRONMENT || '') === 'production'
			? ['www.realmofdev.com', 'www.floik.com']
			: ['www.realmofdev.com', 'localhost'];
	const isWhiteListed = includes(whiteListedDomains, location.hostname);
	return isMicroApp() || isOldFlo || isWhiteListed;
};

export const getIsTabView = (floIndex?: number) =>
	get(getWindowDimensions(floIndex), 'width', 0) <= MAX_TABLET_VIEW_WIDTH;

export const isTouchDevice = () =>
	'ontouchstart' in window || navigator.maxTouchPoints > 0;

export const getIsEmbed = (floIndex?: number) => {
	const elements = document.querySelectorAll(`floik-flo`);
	if (elements.length > 0 && isNumber(floIndex) && floIndex >= 0) {
		return elements[floIndex]?.getAttribute('is-embed') === 'true';
	}
	return includes(location.pathname, '/embed');
};

export const isSwiftPlay = (floIndex?: number) => {
	const elements = document.querySelectorAll(`floik-flo`);
	if (elements.length > 0 && isNumber(floIndex) && floIndex >= 0) {
		return elements[floIndex]?.getAttribute('swift-play') === 'true';
	}
	const queryParam = qs.parse(location.search.replace('?', ''));
	return get(queryParam, 'skip-video', 'false') === 'true';
};

export const getFloAttributeOrQueryParamValue = ({
	floId,
	key,
	floIndex,
}: {
	floId?: string;
	key: string;
	floIndex?: number;
}) => {
	const elements = document.querySelectorAll(`floik-flo`);
	if (elements.length > 0 && isNumber(floIndex) && floIndex >= 0) {
		const attrValue = elements[floIndex]?.getAttribute(key);
		return attrValue === 'true' ? true : attrValue === 'false' ? false : attrValue;
	}
	const queryParam = qs.parse(location.search.replace('?', ''));
	const value = get(queryParam, key, null);
	return value === 'true' ? true : value === 'false' ? false : value;
};

export const removeFloikLoaderInMicroApp = (floIndex: number) => {
	const element = document.querySelectorAll(`floik-flo`)[floIndex];
	if (element) {
		const loader = element.querySelector('floik-loader');
		if (loader) {
			element.removeChild(loader);
		}
	}
};

export const generateFloLink = (url: string, internalLink: boolean, floIndex: number) => {
	if (!internalLink || !url) {
		return url;
	}
	const queryObj = qs.parse(location.search.replace('?', ''));
	const isEmbed = getIsEmbed(floIndex);
	const isUrlEmbed = includes(url, '/embed');
	let addHideHeader = isEmbed && !isUrlEmbed;
	const obj: { [key: string]: boolean } = {};
	if (addHideHeader) {
		obj['hide-header'] = true;
	}
	const queryString = internalLink
		? qs.stringify({
				...queryObj,
				...obj,
		  })
		: '';
	return `${url}${queryString ? '?' : ''}${queryString}`;
};

export const getBlurElementPosition = ({
	isCropped,
	appliedCrop,
	element,
	width,
	height,
	offsetHeight,
	offsetWidth,
}: {
	isCropped?: boolean;
	appliedCrop?: CropStateType;
	element: unknown;
	width: number;
	height: number;
	offsetHeight: number;
	offsetWidth: number;
}) => {
	if (isCropped) {
		const croppedHeight = Math.abs(
			get(appliedCrop, 'values.y2', 0) - get(appliedCrop, 'values.y', 0)
		);
		const croppedWidth = Math.abs(
			get(appliedCrop, 'values.x2', 0) - get(appliedCrop, 'values.x', 0)
		);
		const croppedScaleW = scaleLinear(
			[0, get(appliedCrop, 'values.windowSize.width', 0)],
			[0, get(element, 'canvasRenderConfig.windowCoordinates.width', 0)]
		);
		const croppedScaleH = scaleLinear(
			[0, get(appliedCrop, 'values.windowSize.height', 0)],
			[0, get(element, 'canvasRenderConfig.windowCoordinates.height', 0)]
		);
		const scaleX = scaleLinear(
			[
				croppedScaleW(get(appliedCrop, 'values.x', 0)),
				croppedScaleW(get(appliedCrop, 'values.x', 0) + croppedWidth),
			],
			[0, width]
		);

		const scaleY = scaleLinear(
			[
				croppedScaleH(get(appliedCrop, 'values.y', 0)),
				croppedScaleH(get(appliedCrop, 'values.y', 0) + croppedHeight),
			],
			[0, height]
		);
		const scaleXorigin = scaleLinear([0, croppedWidth], [0, width]);

		const scaleYorigin = scaleLinear([0, croppedHeight], [0, height]);
		const pointerX = scaleX(get(element, 'canvasRenderConfig.canvasCoordinates.x', 0));

		const pointerY = scaleY(get(element, 'canvasRenderConfig.canvasCoordinates.y', 0));

		return {
			left: pointerX,
			top: pointerY,
			width: scaleXorigin(get(element, 'canvasRenderConfig.canvasCoordinates.width', 0)),
			height: scaleYorigin(
				get(element, 'canvasRenderConfig.canvasCoordinates.height', 0)
			),
		};
	}

	const yScale = scaleLinear(
		[0, get(element, 'canvasRenderConfig.windowCoordinates.height', 0)],
		[0, height - (offsetHeight || 0) * 2]
	);

	const xScale = scaleLinear(
		[0, get(element, 'canvasRenderConfig.windowCoordinates.width', 0)],
		[0, width - (offsetWidth || 0) * 2]
	);
	return {
		left: xScale(get(element, 'canvasRenderConfig.canvasCoordinates.x', 0)),
		top: yScale(get(element, 'canvasRenderConfig.canvasCoordinates.y', 0)),
		width: xScale(get(element, 'canvasRenderConfig.canvasCoordinates.width', 0)),
		height: yScale(get(element, 'canvasRenderConfig.canvasCoordinates.height', 0)),
	};
};

export const getFloAuthToken = (floId: string) =>
	localStorage.getItem(`floik-${floId}-token`);

export const useElementVoicePlayer = (
	currentElement: publishElementsDataType | undefined | null,
	elementVoiceResources:
		| { [key: string]: { voiceId: string; scriptId: string } }
		| undefined,
	floElements: {
		[key: string]: publishElementsDataType[];
	},
	isMuted: boolean,
	onVolumeChange: Function,
	hasNotPlayed?: boolean
): {
	onElementVoiceMuteClick(): void;
	showElementVoiceIcon: boolean;
	subTitle: string;
	isElementVoicePlaying: boolean;
	onReplayClick(): void;
	elementVoiceTooltipTitle: string;
} => {
	const [curElementVoiceAudio, setCurElementVoiceAudio] =
		useState<HTMLAudioElement | null>(null);
	const [hasAudioPlayed, setAudioPlayed] = useState(false);
	const [curVoiceUrl, setCurVoiceUrl] = useState('');
	const [subtitleIndex, setSubtitleIndex] = useState<number>();
	const [isElementVoicePlaying, setElementVoicePlaying] = useState(false);
	const [playFakeTimeout, setPlayFakeTimeout] = useState<ReturnType<typeof setTimeout>>();
	const [playFakeTimeoutStartTime, setPlayFakeTimeoutStartTime] = useState(0);

	const { voiceProperties, imageElement } = useMemo(
		() => getElementVoiceResources(currentElement, elementVoiceResources, floElements),
		[currentElement, elementVoiceResources]
	);

	const subtitlesList = get(voiceProperties, 'chunks');

	const timeoutsRef = useRef<ReturnType<typeof setTimeout>[]>();

	const showSubtitle = useCallback(
		(index?: number) => {
			const newIndex = !isNumber(index) ? 0 : index;
			if (newIndex < get(subtitlesList, 'length', 0)) {
				setSubtitleIndex(newIndex);
				const duration =
					get(subtitlesList, `[${newIndex}].endTime`, 0) -
					get(subtitlesList, `[${newIndex}].startTime`, 0);
				timeoutsRef.current = timeoutsRef.current || [];
				timeoutsRef.current.push(
					setTimeout(
						() => showSubtitle(newIndex + 1),
						get(subtitlesList, `[${newIndex}].endTime`, 0)
					)
				);
			}
		},
		[setSubtitleIndex, subtitlesList]
	);

	const resetState = useCallback(() => {
		setCurElementVoiceAudio(null);
		setCurVoiceUrl('');
		setSubtitleIndex(-1);
		forEach(timeoutsRef.current || [], (timeout) => {
			clearTimeout(timeout);
		});
	}, [setCurElementVoiceAudio, setCurVoiceUrl, setSubtitleIndex, timeoutsRef]);

	useEffect(() => {
		if (curElementVoiceAudio) {
			curElementVoiceAudio.muted = isMuted;
		}
	}, [isMuted, curElementVoiceAudio]);

	const onElementVoiceMuteClick = useCallback(() => {
		onVolumeChange({ isMuted: !isMuted, volume: isMuted ? 1 : 0 });
		if (curElementVoiceAudio) {
			curElementVoiceAudio.muted = !isMuted;
			if (playFakeTimeout) {
				const timeElapsed = new Date().getTime() - playFakeTimeoutStartTime;
				const duration = curElementVoiceAudio.duration * 1000;
				if (duration > timeElapsed) {
					curElementVoiceAudio.currentTime = timeElapsed / 1000;
					curElementVoiceAudio.play();
				}
				clearTimeout(playFakeTimeout);
				setPlayFakeTimeout(undefined);
			}
		}
	}, [
		curElementVoiceAudio,
		hasAudioPlayed,
		showSubtitle,
		playFakeTimeout,
		setPlayFakeTimeout,
		playFakeTimeoutStartTime,
		onVolumeChange,
		isMuted,
	]);

	const voiceUrl = get(voiceProperties, 'url', '');

	useEffect(() => {
		if (curElementVoiceAudio) {
			curElementVoiceAudio
				.play()
				.then(() => {
					setAudioPlayed(true);
				})
				.catch((reason) => {
					if (reason?.name === 'NotAllowedError' && curElementVoiceAudio.muted) {
						curElementVoiceAudio.onloadedmetadata = () => {
							if (isNumber(curElementVoiceAudio.duration)) {
								setElementVoicePlaying(true);
								showSubtitle();
								setPlayFakeTimeout(
									setTimeout(() => {
										setElementVoicePlaying(false);
										setAudioPlayed(true);
										setPlayFakeTimeout(undefined);
									}, curElementVoiceAudio.duration * 1000)
								);
								setPlayFakeTimeoutStartTime(new Date().getTime());
							}
						};
					}
				});
		}

		return () => {
			if (curElementVoiceAudio) {
				curElementVoiceAudio.pause();
			}
		};
	}, [
		curElementVoiceAudio,
		setAudioPlayed,
		setPlayFakeTimeout,
		setElementVoicePlaying,
		showSubtitle,
		setPlayFakeTimeoutStartTime,
	]);

	useEffect(() => {
		let newVoiceUrl = voiceUrl;
		if (
			!(
				(get(currentElement, 'time') === 0 &&
					get(currentElement, 'editorElementName') === 'cta') ||
				!hasNotPlayed
			)
		) {
			return;
		}

		if (
			(get(currentElement, 'enableVoice') ||
				(get(currentElement, 'linkedImageId') && get(imageElement, 'enableVoice'))) &&
			newVoiceUrl !== curVoiceUrl
		) {
			if (newVoiceUrl) {
				const audio = new Audio(newVoiceUrl);
				audio.muted = isMuted;
				audio.onended = () => {
					setElementVoicePlaying(false);
					setAudioPlayed(true);
				};
				audio.onplay = () => {
					setElementVoicePlaying(true);
				};
				showSubtitle();
				setCurElementVoiceAudio(audio);
				setCurVoiceUrl(newVoiceUrl);
				setAudioPlayed(false);
			} else {
				resetState();
			}
		} else if (
			!get(currentElement, 'enableVoice') &&
			curElementVoiceAudio &&
			!(get(currentElement, 'linkedImageId') && get(imageElement, 'enableVoice'))
		) {
			resetState();
		}
	}, [
		currentElement,
		setCurElementVoiceAudio,
		curElementVoiceAudio,
		setAudioPlayed,
		voiceUrl,
		setCurVoiceUrl,
		isMuted,
		floElements,
		showSubtitle,
		resetState,
		setElementVoicePlaying,
		hasNotPlayed,
		imageElement,
	]);

	const onReplayClick = useCallback(() => {
		if (curElementVoiceAudio) {
			curElementVoiceAudio.play();
			showSubtitle();
		}
		return () => {
			if (curElementVoiceAudio) {
				curElementVoiceAudio.pause();
			}
		};
	}, [curElementVoiceAudio, showSubtitle]);

	return {
		onElementVoiceMuteClick,
		showElementVoiceIcon: !!curElementVoiceAudio,
		subTitle: get(subtitlesList, `[${subtitleIndex}].text`, ''),
		isElementVoicePlaying,
		onReplayClick,
		elementVoiceTooltipTitle: isElementVoicePlaying
			? isMuted
				? 'Unmute'
				: 'Mute'
			: 'Replay element voice',
	};
};

export const useBackgroundMusic = ({
	url,
	isMuted,
	pauseOnInteractions,
	fadeInOut,
	pauseTimeList,
	currentTime,
	videoEnded,
	volume,
	duration,
	isSwiftPlay,
	interactionsList,
}: {
	url: string;
	isMuted: boolean;
	pauseOnInteractions: boolean;
	fadeInOut: boolean;
	currentTime: number;
	pauseTimeList: pauseTimeType[];
	videoEnded: boolean;
	volume: number;
	duration: number;
	interactionsList: pauseTimeType[];
	isSwiftPlay?: boolean;
}) => {
	const [curBgAudio, setCurBgAudio] = useState<HTMLAudioElement | null>(null);
	const [curBgUrl, setCurBgUrl] = useState('');
	const [audioPlayed, setAudioPlayed] = useState(false);
	const [playFakeTimeoutStartTime, setPlayFakeTimeoutStartTime] = useState(0);
	const [animateAudio, setAnimateAudio] = useState('');
	const [volumeRange, setVolumeRange] = useState({ range: [0.5, 1], step: 0.1 });
	const [hideVoiceIcon, setHideVoiceIcon] = useState(false);
	const [isManualPause, setIsManualPause] = useState(false);

	useEffect(() => {
		const minVolume = volume * BGM_MIN_VOLUME_MULTIPLIER;
		const step = (volume - minVolume) / 5;
		setVolumeRange({ range: [minVolume, volume], step });
	}, [volume]);

	const canPlay =
		currentTime !== 0 ||
		(!pauseOnInteractions && get(interactionsList, '[0].start') === 0);

	// useEffect(() => {
	// 	if (curBgAudio && canPlay) {
	// 		curBgAudio
	// 			?.play()
	// 			.then(() => {
	// 				setAudioPlayed(true);
	// 			})
	// 			.catch((reason) => {
	// 				if (reason?.name === 'NotAllowedError' && (curBgAudio.muted || !audioPlayed)) {
	// 					curBgAudio.onloadedmetadata = () => {
	// 						setPlayFakeTimeoutStartTime(new Date().getTime());
	// 					};
	// 				}
	// 			});
	// 	}
	// 	return () => {
	// 		if (curBgAudio) {
	// 			curBgAudio?.pause();
	// 		}
	// 	};
	// }, [curBgAudio, setAudioPlayed, setPlayFakeTimeoutStartTime, audioPlayed, canPlay]);

	useEffect(() => {
		if (curBgAudio) {
			curBgAudio.muted = isMuted;
		}
	}, [isMuted, curBgAudio]);

	useEffect(() => {
		let interval: ReturnType<typeof setInterval>;
		if (curBgAudio && animateAudio) {
			const cb = () => {
				if (animateAudio === 'fadeIn') {
					if (curBgAudio.volume >= volumeRange.range[1]) {
						setAnimateAudio('');
						if (interval) clearInterval(interval);
						return;
					}

					curBgAudio.volume = min([
						Math.round((curBgAudio.volume + volumeRange.step) * 100) / 100,
						volumeRange.range[1],
					]) as number;
				} else {
					if (curBgAudio.volume <= volumeRange.range[0]) {
						setAnimateAudio('');
						if (interval) clearInterval(interval);
						return;
					}
					curBgAudio.volume = max([
						Math.round((curBgAudio.volume - volumeRange.step) * 100) / 100,
						volumeRange.range[0],
					]) as number;
				}
			};
			cb();
			interval = setInterval(cb, 90);
		}
		return () => {
			if (curBgAudio && animateAudio) {
				clearInterval(interval);
			}
		};
	}, [curBgAudio, animateAudio, setAnimateAudio, volumeRange]);

	const audioFadeInOut = useCallback(
		(fadeIn?: boolean) => {
			if (curBgAudio) {
				if (fadeInOut) {
					setAnimateAudio(fadeIn ? 'fadeIn' : 'fadeOut');
				}
				// else if (fadeIn) {
				// 	curBgAudio.volume = volumeRange.range[1];
				// } else {
				// 	curBgAudio.volume = volumeRange.range[0];
				// }
			}
		},
		[curBgAudio, fadeInOut, setAnimateAudio, volumeRange]
	);

	useEffect(() => {
		if (curBgAudio && !isManualPause) {
			const elementPresent = find(
				pauseTimeList,
				(item) => currentTime >= item.start - 0.5 && currentTime <= item.end + 0.5
			);
			const interactionPresent = find(
				interactionsList,
				(item) =>
					item.isInteraction &&
					item.start >= currentTime - 0.5 &&
					item.start <= currentTime + 0.5
			);

			if (pauseOnInteractions) {
				if (interactionPresent) {
					//@ts-ignore
					if (interactionPresent?.start !== 0) {
						if (fadeInOut) {
							audioFadeInOut();
							setTimeout(() => {
								curBgAudio.pause();
							}, 450);
						} else {
							curBgAudio.pause();
						}
					}
					setHideVoiceIcon(true);
				} else if (curBgAudio.paused && currentTime > 0) {
					setHideVoiceIcon(false);
					curBgAudio.play();
					if (fadeInOut) audioFadeInOut(true);
				}
				if (elementPresent) {
					if (!interactionPresent) setHideVoiceIcon(false);
					if (fadeInOut) audioFadeInOut();
				} else if (curBgAudio.volume <= volumeRange.range[0]) {
					audioFadeInOut(true);
				}
			} else {
				if (elementPresent || interactionPresent) {
					audioFadeInOut();
				} else if (curBgAudio.volume <= volumeRange.range[0]) {
					audioFadeInOut(true);
				}
			}
		}
	}, [
		pauseOnInteractions,
		curBgAudio,
		currentTime,
		pauseTimeList,
		interactionsList,
		volumeRange,
		isManualPause,
		setHideVoiceIcon,
		fadeInOut,
	]);

	const onAudioEnded = useCallback(() => {
		if (curBgAudio && !isManualPause) {
			curBgAudio.play();
		}
	}, [isManualPause, curBgAudio]);

	useEffect(() => {
		if (curBgAudio) {
			curBgAudio.addEventListener('ended', onAudioEnded);
		}
		return () => {
			curBgAudio?.removeEventListener('ended', onAudioEnded);
		};
	}, [curBgAudio, onAudioEnded]);

	useEffect(() => {
		if (url && url !== curBgUrl && (!isSwiftPlay || !pauseOnInteractions)) {
			const newAudio = new Audio(url);
			newAudio.muted = isMuted;
			newAudio.volume = volume * BGM_MIN_VOLUME_MULTIPLIER;
			setCurBgUrl(url);
			setCurBgAudio(newAudio);
		}
	}, [
		url,
		setCurBgAudio,
		isMuted,
		curBgUrl,
		setCurBgUrl,
		volume,
		isSwiftPlay,
		pauseOnInteractions,
	]);

	const onToggleMuteBgCb = useCallback(() => {
		if (curBgAudio) {
			curBgAudio.muted = !isMuted;
			if (playFakeTimeoutStartTime) {
				const timeElapsed = new Date().getTime() - playFakeTimeoutStartTime;
				const duration = timeElapsed % (curBgAudio.duration * 1000);
				curBgAudio.currentTime = duration / 1000;
				curBgAudio.play();
				setPlayFakeTimeoutStartTime(0);
			} else if (curBgAudio.paused) {
				curBgAudio.play();
			}
		}
	}, [curBgAudio, isMuted, playFakeTimeoutStartTime]);

	const onRestartBgCb = useCallback(() => {
		if (curBgAudio) {
			curBgAudio.currentTime = 0;
			if (!pauseOnInteractions && curBgAudio.paused) {
				curBgAudio.play();
			}
		}
	}, [curBgAudio, pauseOnInteractions]);

	useEffect(() => {
		if (videoEnded) {
			setIsManualPause(true);
			if (fadeInOut) {
				audioFadeInOut();
				setTimeout(() => {
					curBgAudio?.pause();
				}, 450);
			} else {
				curBgAudio?.pause();
			}
		}
	}, [videoEnded, curBgAudio, fadeInOut, setIsManualPause]);

	useEffect(() => {
		if (isManualPause && currentTime === 0) {
			const anyInteractionAtStart = find(
				interactionsList,
				(item) => item.isInteraction && item.start === 0
			);
			if (!anyInteractionAtStart && !curBgAudio?.paused) {
				curBgAudio?.pause();
			}
		}
	}, [curBgAudio, isManualPause, currentTime, interactionsList]);

	useEffect(() => {
		if (curBgAudio && curBgAudio.volume > volumeRange.range[1]) {
			curBgAudio.volume = volumeRange.range[1];
		}
	}, [volumeRange, curBgAudio]);

	const onVideoPauseBgCb = useCallback(() => {
		if (curBgAudio) {
			setIsManualPause(true);
			curBgAudio.pause();
		}
	}, [curBgAudio, setIsManualPause]);

	const onPlayBgCb = useCallback(() => {
		if (curBgAudio) {
			setIsManualPause(false);
			curBgAudio.play();
		}
	}, [curBgAudio, setIsManualPause]);

	const onVideoEndBgCb = useCallback(() => {
		if (curBgAudio) {
			curBgAudio.currentTime = 0;
			curBgAudio.pause();
			setIsManualPause(true);
		}
	}, [curBgAudio, setIsManualPause]);

	return {
		onToggleMuteBgCb,
		onRestartBgCb,
		onVideoPauseBgCb,
		onPlayBgCb,
		hideVoiceIcon,
		onVideoEndBgCb,
	};
};

export const getElementVoiceResources = (
	currentElement: publishElementsDataType | undefined | null,
	elementVoiceResources:
		| { [key: string]: { voiceId: string; scriptId: string } }
		| undefined,
	floElements: {
		[key: string]: publishElementsDataType[];
	}
) => {
	let element = currentElement;
	let imageElement;
	if (!get(currentElement, 'enableVoice') && get(currentElement, 'linkedImageId')) {
		element = find(get(floElements, 'linked_images'), [
			'id',
			get(currentElement, 'linkedImageId'),
		]);
		imageElement = element;
		//@ts-ignore
	}
	return {
		voiceProperties: get(
			elementVoiceResources,
			`${get(element, 'voiceConfig.scriptId')}_${get(element, 'voiceConfig.voiceId')}`
		),
		imageElement,
	};
};

export const preventDefaultForTouchEndEvent = (
	event?: React.MouseEvent | React.TouchEvent
) => {
	if (event?.type === 'touchend') {
		event.preventDefault();
	}
};

export const getResourceHeadersForDoc360 = (floIndex: number, token: string) => {
	const embeddedElements = document.querySelectorAll('floik-flo');
	const region = embeddedElements[floIndex].getAttribute('doc360-region');
	const deploymentId =
		embeddedElements[floIndex].getAttribute('doc360-deployment-id') || '';
	return {
		Authorization: `Bearer ${token}`,
		'x-d360-id': deploymentId,
		'x-d360-region': region,
	};
};

export async function getImageDimensionsFromUrl(
	imageUrl: string
): Promise<{ width: number; height: number } | null> {
	return new Promise((resolve, reject) => {
		const img = new Image();

		img.onload = () => {
			resolve({ width: img.width, height: img.height });
		};

		img.onerror = () => {
			reject(null);
		};

		img.src = imageUrl;
	});
}

export const getThemeDetails = (theme: string) => {
	const supportedThemes = [
		{
			key: 'none',
			label: 'No Theme',
			color: 'none',
		},
		{
			key: 'peach',
			label: 'Peach',
			color: '#F7C7B3',
		},
		{
			key: 'light_blue',
			label: 'Light Blue',
			color: '#CBD8FF',
		},
		{
			key: 'mint',
			label: 'Mint',
			color: '#99D7A8',
		},
		{
			key: 'navy',
			label: 'Navy',
			color: '#2D3C67',
		},
		{
			key: 'plum',
			label: 'Plum',
			color: '#522E47',
		},
		{
			key: 'dark_gray',
			label: 'Dark Gray',
			color: '#374E4E',
		},
		{
			key: 'teal',
			label: 'Teal',
			color: '#155E75',
		},
		{
			key: 'gradient_mint',
			label: 'Gradient Mint',
			gradient: 'linear-gradient(180deg, #13547A, #80D0C7)',
		},
		{
			key: 'gradient_blue',
			label: 'Gradient Blue',
			gradient: 'linear-gradient(180deg, #0F469D, #3189ED)',
		},
		{
			key: 'gradient_purple',
			label: 'Gradient Purple',
			gradient: 'linear-gradient(180deg, #663177, #AF7EA4)',
		},
		{
			key: 'gradient_indigo',
			label: 'Gradient Indigo',
			gradient: 'linear-gradient(90deg, #1945AE, #60399D)',
		},
	];

	const themeDetails = supportedThemes.find((t) => t.key === theme);
	return themeDetails ? themeDetails.color || themeDetails.gradient : null;
};

export const getContrastColor = (hex: string) => {
	// Handle linear gradient values
	if (hex?.includes('linear-gradient')) {
		// Extract the first color from the gradient
		const match = hex.match(/#[0-9A-Fa-f]{6}/);
		if (match) {
			hex = match[0];
		} else {
			return '#000'; // Default to black if no valid hex color found in gradient
		}
	}

	// Convert HEX to RGB
	const rgb = hexToRgb(hex);
	if (!rgb) return '#000'; // Default to black if invalid color

	// Calculate luminance
	const luminance =
		(0.2126 * rgb.r) / 255 + (0.7152 * rgb.g) / 255 + (0.0722 * rgb.b) / 255;

	// Return black (#000) for light colors, white (#fff) for dark colors
	return luminance > 0.5 ? '#222' : '#fff';
};

// Helper: Convert HEX to RGB
function hexToRgb(hex: string): { r: number; g: number; b: number } | null {
	hex = hex.replace(/^#/, ''); // Remove #
	if (hex.length === 3) {
		hex = hex
			.split('')
			.map((c) => c + c)
			.join(''); // Convert #RGB to #RRGGBB
	}
	const match = hex.match(/^([0-9a-f]{2})([0-9a-f]{2})([0-9a-f]{2})$/i);
	return match
		? {
				r: parseInt(match[1], 16),
				g: parseInt(match[2], 16),
				b: parseInt(match[3], 16),
		  }
		: null;
}

type WrapperType = 'rounded' | 'light' | 'dark';

export function getWrapperValue(
	wrapperValue: string | string[] | ParsedQs | ParsedQs[] | null
): WrapperType {
	// Handle null or undefined
	if (wrapperValue === null || wrapperValue === undefined) {
		return 'rounded';
	}

	// Convert to string if it's an array or ParsedQs
	const value =
		typeof wrapperValue === 'string'
			? wrapperValue
			: Array.isArray(wrapperValue)
			? String(wrapperValue[0] || '')
			: String(wrapperValue);

	// Check valid values
	switch (value) {
		case 'light':
		case 'dark':
			return value as WrapperType;
		default:
			return 'rounded';
	}
}
