import React, { useCallback, useRef } from 'react';
import classNames from 'classnames';
import styles from '../../../../../Components/VideoPlayer/VideoPlayer.module.css';
import { Button, IconButton, TextareaAutosize } from '@mui/material';
import {
	PUBLISHED_FLO_COMMENT_PLACEHOLDER_EMAIL,
	PUBLISHED_FLO_COMMENT_PLACEHOLDER_NAME,
	PUBLISHED_FLO_COMMENT_PLACEHOLDER_QUESTION,
} from '../../../../../Common/Common.constants';
import { get, last } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as BlackCloseIcon } from './../../../../../Common/images/black-close.svg';
import {
	sendAnnotationAction,
	showAddCommentsPopup,
	updateAddCommentsPopup,
} from '../../../Flo.reducers';
import Cookie from 'js-cookie';
import { setNotification } from '../../../../../Components/Notification/Toasts.reducers';
import { headerBrandImageSelector } from '../../../../../Common/Common.selector';

let timeout: NodeJS.Timeout | undefined = undefined;

const SendCommentPopup = ({ floId, floIndex }: { floId: string; floIndex: number }) => {
	const dispatch = useDispatch();
	const brandImage = useSelector(headerBrandImageSelector(floIndex));
	const floatingRef = useRef<undefined | HTMLDivElement>();

	const enableAddComment = useSelector((state) =>
		get(state, `floPage.flos.${floIndex}.commentsPopup`, {
			visible: false,
			annotationsMeta: [],
			id: '',
			time: undefined,
			top: 0,
			left: 0,
			question: '',
			name: '',
			email: '',
			className: '',
		})
	);
	const onClose = useCallback(() => {
		dispatch(
			showAddCommentsPopup({
				data: {
					visible: false,
					top: 0,
					left: 0,
					question: '',
					name: '',
					email: '',
					className: '',
					id: '',
				},
				floId,
				floIndex,
			})
		);
	}, [floId]);

	const validEmail = get(enableAddComment, 'email', '').trim()
		? /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
				get(enableAddComment, 'email', '').trim()
		  )
		: true;

	const sendAnnotationComment = useCallback(() => {
		dispatch(
			sendAnnotationAction({
				data: last(enableAddComment.annotationsMeta),
				stepId: enableAddComment.id,
				time: enableAddComment.time,
				question: enableAddComment.question,
				name: enableAddComment.name,
				email: enableAddComment.email,
				floId,
				floIndex,
			})
		);

		if (get(enableAddComment, 'name', ''))
			Cookie.set('floik-username', get(enableAddComment, 'name', ''), {
				path: '/',
			});
		if (get(enableAddComment, 'email', ''))
			Cookie.set('floik-user-email', get(enableAddComment, 'email', ''), {
				path: '/',
			});

		dispatch(
			showAddCommentsPopup({
				data: {
					visible: false,
					top: 0,
					left: 0,
					question: '',
					name: '',
					email: '',
					className: '',
				},
				floId,
				floIndex,
			})
		);
	}, [enableAddComment, floId]);

	const onChangeHandler = useCallback(
		(event: React.ChangeEvent, name: string) => {
			dispatch(
				updateAddCommentsPopup({
					data: {
						[name]: get(event, 'target.value'),
						className: '',
					},
					floId,
					floIndex,
				})
			);
		},
		[floId]
	);

	const onQuestionChange = useCallback(
		(e: React.ChangeEvent) => {
			onChangeHandler(e, 'question');
		},
		[onChangeHandler]
	);

	const onEmailChange = useCallback(
		(e: React.ChangeEvent) => {
			onChangeHandler(e, 'email');
		},
		[onChangeHandler]
	);

	const onNameChange = useCallback(
		(e: React.ChangeEvent) => {
			onChangeHandler(e, 'name');
		},
		[onChangeHandler]
	);
	const onKeyDownCallback = useCallback(
		(ev: React.KeyboardEvent<HTMLTextAreaElement>, name: string) => {
			if (ev.key === 'Escape') {
				if (!enableAddComment.question) {
					dispatch(
						showAddCommentsPopup({
							data: {
								visible: false,
								top: 0,
								left: 0,
								question: '',
								name: '',
								email: '',
								className: '',
								id: '',
							},
							floId,
							floIndex,
						})
					);
				} else if (floatingRef.current) {
					dispatch(
						updateAddCommentsPopup({
							data: {
								className: styles.wiggle,
							},
							floId,
							floIndex,
						})
					);
					if (timeout) clearTimeout(timeout);
					timeout = setTimeout(() => {
						dispatch(
							updateAddCommentsPopup({
								data: {
									className: '',
								},
								floId,
								floIndex,
							})
						);
					}, 2000);
				}
			}
			if (ev.key === 'Enter' && !ev.shiftKey) {
				if (
					get(enableAddComment, 'email', '').trim() &&
					!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
						get(enableAddComment, 'email', '').trim()
					)
				) {
					dispatch(
						setNotification({
							data: {
								type: 'error',
								message: 'Invalid email',
								canShow: true,
							},
							floIndex,
						})
					);
					return;
				}
				if (get(enableAddComment, 'question', '').trim()) {
					sendAnnotationComment();
				}
			}
		},
		[enableAddComment, timeout, floId]
	);

	const onQuestionKeyDownCallback = useCallback(
		(ev: React.KeyboardEvent<HTMLTextAreaElement>) => {
			onKeyDownCallback(ev, 'question');
		},
		[onKeyDownCallback]
	);

	const onNameKeyDownCallback = useCallback(
		(ev: React.KeyboardEvent<HTMLTextAreaElement>) => {
			onKeyDownCallback(ev, 'name');
		},
		[onKeyDownCallback]
	);

	const onEmailKeyDownCallback = useCallback(
		(ev: React.KeyboardEvent<HTMLTextAreaElement>) => {
			onKeyDownCallback(ev, 'email');
		},
		[onKeyDownCallback]
	);
	return (
		<>
			{enableAddComment.visible && (
				<div
					onClick={onClose}
					onScroll={onClose}
					className={styles.sendCommentPopupBackground}
				></div>
			)}
			{enableAddComment.visible && (
				<div
					className={classNames(
						styles.floatingCommentContainer,
						enableAddComment.className
					)}
					// @ts-ignore
					ref={floatingRef}
					style={{
						// width: 200,
						top: enableAddComment.top,
						left: enableAddComment.left,
					}}
				>
					<div className={styles.colorStrip}></div>
					<div className={styles.commentContainer}>
						<IconButton
							className={styles.closeButton}
							aria-label="close"
							onClick={onClose}
							sx={{
								position: 'absolute',
								right: 24,
								top: 24,
							}}
						>
							<BlackCloseIcon className={styles.closeIcon} />
						</IconButton>
						<div className={styles.messageContainer}>
							<TextareaAutosize
								className={styles.textArea}
								aria-label={PUBLISHED_FLO_COMMENT_PLACEHOLDER_QUESTION}
								minRows={3}
								onChange={onQuestionChange}
								placeholder={PUBLISHED_FLO_COMMENT_PLACEHOLDER_QUESTION}
								autoFocus
								onKeyDown={onQuestionKeyDownCallback}
							/>
						</div>
						<div className={styles.messageContainer}>
							<TextareaAutosize
								className={styles.input}
								aria-label={PUBLISHED_FLO_COMMENT_PLACEHOLDER_NAME}
								minRows={1}
								maxRows={1}
								defaultValue={enableAddComment.name}
								onChange={onNameChange}
								placeholder={PUBLISHED_FLO_COMMENT_PLACEHOLDER_NAME}
								onKeyDown={onNameKeyDownCallback}
							/>
						</div>
						<div className={styles.messageContainer}>
							<TextareaAutosize
								className={styles.input}
								aria-label={PUBLISHED_FLO_COMMENT_PLACEHOLDER_EMAIL}
								minRows={1}
								maxRows={1}
								defaultValue={enableAddComment.email}
								onChange={onEmailChange}
								placeholder={PUBLISHED_FLO_COMMENT_PLACEHOLDER_EMAIL}
								onKeyDown={onEmailKeyDownCallback}
							/>
						</div>
						<div className={styles.messageContainer}>
							<img alt="Logo" src={brandImage} className={styles.logo} />
							<Button
								variant="contained"
								disableElevation
								className={styles.floatingControlsSendButton}
								onClick={sendAnnotationComment}
								disabled={
									!validEmail || !get(enableAddComment, 'question', '').trim().length
								}
							>
								Send
							</Button>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default SendCommentPopup;
