import React from 'react';
import classNames from 'classnames';
import Snackbar from '../SnackBar/SnackBar';

import { NotificationType } from './Notification.types';
import styles from './Notification.module.css';
import { ReactComponent as SuccessSvg } from '../../Common/images/success-circle.svg';
import { ReactComponent as ErrorSvg } from '../../Common/images/error-circle.svg';
import { ReactComponent as InfoSg } from '../../Common/images/info-circle.svg';
import { useSelector } from 'react-redux';
import { get } from 'lodash';

const Notification = (props: NotificationType) => {
	const {
		type,
		message,
		onClose,
		canShow,
		vertical,
		horizontal,
		autoHideDuration,
		floIndex,
	} = props;
	const isError = type === 'error';
	const isInfo = type === 'info';
	const isWarning = type === 'warning';
	let src = <SuccessSvg className={styles.notificationIcon} />;
	const isEmbed = useSelector((state) =>
		get(state, `floPage.flos.${floIndex}.floDetails["is-embed"]`, false)
	);
	if (isError) src = <ErrorSvg className={styles.notificationIcon} />;
	if (isInfo) src = <InfoSg className={styles.notificationIcon} />;
	if (isWarning)
		src = <InfoSg className={classNames(styles.notificationIcon, styles.warning)} />;
	const embeddedElements = document.querySelectorAll('floik-flo');

	if (!canShow) return <span />;

	return (
		<Snackbar
			open={canShow}
			duration={autoHideDuration || 3000}
			onClose={onClose}
			disablePortal={isEmbed}
			container={embeddedElements?.length ? embeddedElements[floIndex] : undefined}
			isEmbed={isEmbed}
		>
			<div className={styles.notificationContainer}>
				{src}
				<span>{message}</span>
			</div>
		</Snackbar>
	);
};

export default Notification;
