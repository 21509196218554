import React, { ReactElement } from 'react';
import * as ReactDOMClient from 'react-dom/client';
import { ThemeProvider } from '@mui/material/styles';
import App from './Containers/App/App';
import { Provider } from 'react-redux';
import store from './store';
import theme from './theme';
import { isMicroApp } from './Common/Common.utils';
import { externalAppInfoType } from './Common/Common.types';
import { get, includes } from 'lodash';
import qs from 'qs';

const isDev = process.env.REACT_APP_PUBLISH_ASSETS_BASE_URL === 'http://localhost:3000';

const cssFiles = {
	dev: 'main.dbfd447a.css',
	qa: 'main.a8d017d0.css',
};

let ErrorBoundary = ({ children }: { children: ReactElement }) => children;
// const PUBLISH_ASSETS_BASE_URL = 'http://localhost:4000/';
const PUBLISH_ASSETS_BASE_URL = `${process.env.REACT_APP_PUBLISH_ASSETS_BASE_URL}/`;
const DEPLOYMENT_ENV = process.env.REACT_APP_DEPLOYMENT_ENV || 'dev';
const CSS_FILE_PATH = `${PUBLISH_ASSETS_BASE_URL}${
	PUBLISH_ASSETS_BASE_URL.includes('signoff')
		? `${isDev ? '' : `${DEPLOYMENT_ENV}/`}`
		: ''
}static/css/${
	PUBLISH_ASSETS_BASE_URL.includes('signoff')
		? cssFiles[DEPLOYMENT_ENV]
		: 'main.cf51311f.css'
}`;

class FloikFlo extends HTMLElement {
	static observedAttributes = [];

	constructor() {
		// Always call super first in constructor
		super();
	}
}

if (!customElements.get('floik-flo')) {
	customElements.define('floik-flo', FloikFlo);
}

const addStyleLink = () => {
	const isStyleAdded = document.querySelector(`[href="${CSS_FILE_PATH}"]`);
	if (isStyleAdded) {
		return;
	}
	const linkElement = document.createElement('link');
	linkElement.rel = 'stylesheet';
	linkElement.href = CSS_FILE_PATH;
	document.head.prepend(linkElement);
};

if (isMicroApp()) {
	addStyleLink();
}
window.floikPreviewInstance = {};

const renderApp = (
	element: Element | DocumentFragment,
	externalAppInfo?: externalAppInfoType
) => {
	const root = ReactDOMClient.createRoot(element);

	addStyleLink();

	if (isMicroApp()) {
		window.floikPreviewInstance.appRoot = {
			unmount: () => {
				root.unmount();
			},
		};
	}

	const scriptPath = PUBLISH_ASSETS_BASE_URL.includes('signoff')
		? `https://www.signoff.ai/exe/${process.env.REACT_APP_DEPLOYMENT_ENV || 'dev'}/`
		: PUBLISH_ASSETS_BASE_URL;
	const urlPath = `${isDev ? '/' : scriptPath}main.js`;
	const scriptQuery = `script[src="${urlPath}"]`;
	const scriptTag = document.querySelector(scriptQuery);

	const floSpaceId = scriptTag?.getAttribute('id') || '';
	// const floSpaceId = "64c8ed1fdc0ab9016fff5a20";

	root.render(
		<ErrorBoundary>
			<ThemeProvider theme={theme}>
				<Provider store={store}>
					<App externalAppInfo={externalAppInfo} floSpaceId={floSpaceId} />
				</Provider>
			</ThemeProvider>
		</ErrorBoundary>
	);
};

const updateOldTags = () => {
	const oldEmbeddedElements = document.querySelectorAll('[data-f-id*=_]');
	if (oldEmbeddedElements.length > 0) {
		const queryParam = qs.parse(location.search.replace('?', ''));
		for (let i = 0; i < oldEmbeddedElements.length; i++) {
			const element = oldEmbeddedElements[i];
			const newEmbedElement = document.createElement('floik-flo');
			const attributeNames = element.getAttributeNames();
			for (let j = 0; j < attributeNames.length; j++) {
				if (attributeNames[j] === 'data-f-id') {
					const splitId = element.getAttribute('data-f-id')?.split('_');
					const scriptTag = document.querySelector(
						`script[src="${isDev ? '/' : PUBLISH_ASSETS_BASE_URL}main.js"]`
					);
					scriptTag?.setAttribute('id', get(splitId, '[0]', ''));
					newEmbedElement.setAttribute('flo-id', get(splitId, '[1]', ''));
					newEmbedElement.setAttribute(
						'swift-play',
						get(queryParam, 'skip-video', 'false')
					);
					newEmbedElement.setAttribute(
						'auto-play',
						get(queryParam, 'auto-play', 'false')
					);
					newEmbedElement.setAttribute(
						'hide-header',
						get(queryParam, 'hide-header', 'false')
					);
					newEmbedElement.setAttribute('show-toc', get(queryParam, 'show-toc', 'false'));
					newEmbedElement.setAttribute(
						'show-author',
						get(queryParam, 'show-author', 'false')
					);
					newEmbedElement.setAttribute(
						'show-flo-title',
						get(queryParam, 'show-flo-title', 'false')
					);
					newEmbedElement.setAttribute('inApp', get(queryParam, 'inApp', 'false'));
					newEmbedElement.setAttribute('is-embed', includes(location.pathname, '/embed'));
					if (element.getAttribute('data-micro-view') !== 'true')
						newEmbedElement.setAttribute('style', 'width: 100vw; height: 100vh');
				} else if (attributeNames[j] === 'data-micro-view') {
					newEmbedElement.setAttribute('doc360-app', 'true');
				} else {
					newEmbedElement.setAttribute(
						attributeNames[j],
						element.getAttribute(attributeNames[j]) || ''
					);
				}
			}
			element.parentNode?.replaceChild(newEmbedElement, element);
		}
	}
};

const renderMicroApp = (externalAppInfo?: externalAppInfoType) => {
	updateOldTags();
	const embeddedElements = document.querySelectorAll('floik-flo');
	if (embeddedElements.length > 0) {
		const rootElement = document.createElement('div');
		rootElement.setAttribute('style', 'width: 0;height: 0;position:fixed;top:-9999px');
		document.body.append(rootElement);
		renderApp(rootElement, externalAppInfo);
	}
};

if (!isMicroApp()) {
	updateOldTags();
	const embeddedElements = document.querySelectorAll('floik-flo');
	if (embeddedElements[0]) {
		renderMicroApp();
	} else {
		let rootElement = document.getElementById('root') as Element | DocumentFragment;
		if (rootElement) {
			renderApp(rootElement);
		}
	}
}

window.floikPreviewInstance.renderMicroApp = renderMicroApp;

window.doc360PreviewInstance?.onMessageFromMicroApp({
	type: 'floik_script_loaded',
	payload: {
		loaded: true,
	},
});
