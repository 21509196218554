import { createSlice, Dispatch } from '@reduxjs/toolkit';
import { ReactNode } from 'react';
import { cancelButtonText, confirmButtonText } from '../../Common/Common.constants';
import { get } from 'lodash';

export interface DialogItemType {
	visible: boolean;
	header: ReactNode;
	body: ReactNode;
	disablePortal?: boolean;
	container?: Element | (() => Element | null) | null;
	id?: string;
	ctaId?: string;
	classContainer?: string;
	bodyClassName?: string;
	classDialogRoot?: string;
	classDialogConfirmButton?: string;
	confirmButtonText: string;
	confirmButtonType?: string;
	cancelButtonText: ReactNode;
	hideClosebutton?: boolean;
	confirmButtonUrl?: string;
	noBackdropClick?: boolean;
	showConfirm: boolean;
	disableAutoFocus: boolean;
	sending: boolean;
	centerAlignFooter: boolean;
	collabAdding: boolean;
	showCancel: boolean;
	onCancel?(value: unknown, clicked: boolean): boolean;
	onConfirm?(dispatch: Dispatch): boolean;
	headerClassName?: string;
	cancelButtonClassName?: string;
	fullScreenView?: boolean;
	classContentWrapper?: string;
	position?: string;
	classes?: { closeIcon?: string; closeButton?: string };
	floId: string;
}

type DialogReducerType = {
	[key: string]: DialogItemType;
};

const initialState = Object.freeze({
	visible: false,
	id: '',
	ctaId: '',
	header: 'header',
	body: 'body',
	showConfirm: false,
	showCancel: false,
	sending: false,
	centerAlignFooter: false,
	collabAdding: false,
	noBackdropClick: false,
	onCancel: undefined,
	onConfirm: undefined,
	disableAutoFocus: false,
	classContainer: '',
	bodyClassName: '',
	hideClosebutton: false,
	classDialogConfirmButton: '',
	classDialogRoot: '',
	cancelButtonText: cancelButtonText,
	confirmButtonText: confirmButtonText,
	fullScreenView: false,
});

const dialogReducers = {
	showDialog(
		state: DialogReducerType,
		{
			payload,
		}: { payload: { data: Partial<DialogItemType>; floId: string; floIndex: number } }
	) {
		return {
			...state,
			[payload.floIndex]: {
				...initialState,
				...payload.data,
				visible: get(payload, 'data.visible', true),
				floId: payload.floId,
			},
		};
	},
	hideDialog(
		state: DialogReducerType,
		{ payload }: { payload: { data?: string; floId: string; floIndex: number } }
	) {
		if (payload.data && get(state, `[${payload.floIndex}].id`, '') !== payload.data) {
			return state;
		}
		return {
			...state,
			[payload.floIndex]: initialState,
		};
	},
	updateDialog(
		state: DialogReducerType,
		{
			payload,
		}: { payload: { data: Partial<DialogItemType>; floId: string; floIndex: number } }
	) {
		state[payload.floIndex] = { ...state[payload.floIndex], ...payload.data };
	},
};

export const videoDialogReducer = createSlice({
	name: 'videoDialogReducer',
	initialState: {},
	// @ts-ignore
	reducers: dialogReducers,
});

export default videoDialogReducer.reducer;

export const showDialogAction = videoDialogReducer.actions.showDialog;
export const hideDialogAction = videoDialogReducer.actions.hideDialog;
export const updateDialogAction = videoDialogReducer.actions.updateDialog;

videoDialogReducer.actions.setDialogStateForCollaborator;
