import { lazy } from 'react';

const SwiftplayInteractiveMod = lazy(
	() => import('../SwiftplayInteractiveMode/SwiftplayInteractiveMode')
);
const LazyLoadedSwiftPlayInteractiveVideo = (props: {
	hideHeader?: boolean;
	hideHeaderBecauseOfData?: boolean;
}) => <SwiftplayInteractiveMod {...props} />;

export default LazyLoadedSwiftPlayInteractiveVideo;
