import { Box } from '@mui/material';
import styles from './OTPInput.module.css';
import React from 'react';

const OTP = ({
	separator,
	length,
	value,
	inputType,
	onChange,
}: {
	separator: React.ReactNode;
	length: number;
	value: string;
	inputType?: string;
	onChange: React.Dispatch<React.SetStateAction<string>>;
}) => {
	const inputRefs = React.useRef<HTMLInputElement[]>(new Array(length).fill(null));

	const focusInput = (targetIndex: number) => {
		const targetInput = inputRefs.current[targetIndex];
		targetInput.focus();
	};

	const selectInput = (targetIndex: number) => {
		const targetInput = inputRefs.current[targetIndex];
		targetInput.select();
	};

	const handleKeyDown = (
		event: React.KeyboardEvent<HTMLInputElement>,
		currentIndex: number
	) => {
		switch (event.key) {
			case 'ArrowUp':
			case 'ArrowDown':
			case ' ':
				event.preventDefault();
				break;
			case 'ArrowLeft':
				event.preventDefault();
				if (currentIndex > 0) {
					focusInput(currentIndex - 1);
					selectInput(currentIndex - 1);
				}
				break;
			case 'ArrowRight':
				event.preventDefault();
				if (currentIndex < length - 1) {
					focusInput(currentIndex + 1);
					selectInput(currentIndex + 1);
				}
				break;
			case 'Delete':
				event.preventDefault();
				onChange((prevOtp) => {
					const otp = prevOtp.slice(0, currentIndex) + prevOtp.slice(currentIndex + 1);
					return otp;
				});

				break;
			case 'Backspace':
				event.preventDefault();
				if (currentIndex > 0) {
					focusInput(currentIndex - 1);
					selectInput(currentIndex - 1);
				}

				onChange((prevOtp) => {
					const otp = prevOtp.slice(0, currentIndex) + prevOtp.slice(currentIndex + 1);
					return otp;
				});
				break;

			default:
				break;
		}
	};

	const handleChange = (
		event: React.ChangeEvent<HTMLInputElement>,
		currentIndex: number
	) => {
		const currentValue = event.target.value;
		let indexToEnter = 0;

		while (indexToEnter <= currentIndex) {
			if (inputRefs.current[indexToEnter].value && indexToEnter < currentIndex) {
				indexToEnter += 1;
			} else {
				break;
			}
		}
		onChange((prev) => {
			const otpArray = prev.split('');
			const lastValue = currentValue[currentValue.length - 1];
			otpArray[indexToEnter] = lastValue;
			return otpArray.join('');
		});
		if (currentValue !== '') {
			if (currentIndex < length - 1) {
				focusInput(currentIndex + 1);
			}
		}
	};

	const handleClick = (
		event: React.MouseEvent<HTMLInputElement, MouseEvent>,
		currentIndex: number
	) => {
		selectInput(currentIndex);
	};

	const handlePaste = (
		event: React.ClipboardEvent<HTMLInputElement>,
		currentIndex: number
	) => {
		event.preventDefault();
		const clipboardData = event.clipboardData;

		// Check if there is text data in the clipboard
		if (clipboardData.types.includes('text/plain')) {
			let pastedText = clipboardData.getData('text/plain');
			pastedText = pastedText.substring(0, length).trim();
			let indexToEnter = 0;

			while (indexToEnter <= currentIndex) {
				if (inputRefs.current[indexToEnter].value && indexToEnter < currentIndex) {
					indexToEnter += 1;
				} else {
					break;
				}
			}

			const otpArray = value.split('');

			for (let i = indexToEnter; i < length; i += 1) {
				const lastValue = pastedText[i - indexToEnter] ?? ' ';
				otpArray[i] = lastValue;
			}

			onChange(otpArray.join(''));
		}
	};

	return (
		<Box sx={{ display: 'flex', gap: 1, alignItems: 'center', justifyContent: 'center' }}>
			{new Array(length).fill(null).map((_, index) => (
				<React.Fragment key={index}>
					<input
						className={styles.input}
						type={inputType}
						aria-label={`Digit ${index + 1} of OTP`}
						ref={(ele: HTMLInputElement) => {
							inputRefs.current[index] = ele!;
						}}
						onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) =>
							handleKeyDown(event, index)
						}
						onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
							handleChange(event, index)
						}
						onClick={(event: React.MouseEvent<HTMLInputElement>) =>
							handleClick(event, index)
						}
						onPaste={(event: React.ClipboardEvent<HTMLInputElement>) =>
							handlePaste(event, index)
						}
						value={value[index] ?? ''}
					/>
					{index === length - 1 ? null : separator}
				</React.Fragment>
			))}
		</Box>
	);
};

export default OTP;
