import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { get, isBoolean } from 'lodash';
import VideoFlo from './VideoFlo/VideoFlo';
import GuideFlo from './GuideFlo/GuideFlo';
import styles from './../Flo.module.css';
import { RootState } from '../../../store';
import classNames from 'classnames';
import Folders from './Folders/Folders';
import LazyLoadedSwiftPlayInteractiveVideo from './LazyLoadedComponents/LazyLoadedSwiftPlayInteractiveVideo';
import { ReactComponent as NotFoundSvg } from '../../../Common/images/404.svg';
import { getThemeDetails } from '../../../Common/Common.utils';

const FloOutputType = ({
	hideHeader,
	hideHeaderBecauseOfData,
	floId,
	floIndex,
}: {
	hideHeader?: boolean;
	hideHeaderBecauseOfData?: boolean;
	floId: string;
	floIndex: number;
}) => {
	const pageType: string = useSelector((state) =>
		get(state, `floPage.flos.${floIndex}.floDetails.type`, '')
	);
	const embeddedElements = document.querySelectorAll('floik-flo');

	const outputType: string = useSelector((state) =>
		get(state, `floPage.flos.${floIndex}.floDetails.outputType`, '')
	);
	const isDomainValid = useSelector((state) =>
		get(state, 'floPage.floSpace.isDomainValid')
	);
	const skipVideo: boolean = useSelector((state) =>
		get(state, `floPage.flos.${floIndex}.floDetails["skip-video"]`, false)
	);
	const internalArticles = useSelector((state: RootState) =>
		hideHeader
			? false
			: get(state, `floPage.flos.${floIndex}.floDetails.internalArticles`, false)
	) as boolean;

	const hasSwiftPlayImages = useSelector(
		(state) => get(state, `floPage.flos.${floIndex}.swiftPlayImages.length`, 0) > 0
	);
	const hasHotspots = useSelector(
		(state) => get(state, `floPage.flos.${floIndex}.ctaAndHotspotTimesList.length`, 0) > 0
	);

	const theme = useSelector((state) =>
		get(state, `floPage.flos.${floIndex}.floDetails['theme']`, 'none')
	);
	const view = useSelector((state) =>
		get(state, `floPage.flos.${floIndex}.floDetails['view']`, 'default')
	);

	const wrapper = useSelector((state) =>
		get(state, `floPage.flos.${floIndex}.floDetails['wrapper']`, 'rounded')
	);

	const themeColor = getThemeDetails(theme) || 'none';

	const errored: boolean = useSelector((state) =>
		get(state, `floPage.flos.${floIndex}.errored`, false)
	);
	const errorMessage = useSelector((state) =>
		get(state, `floPage.flos.${floIndex}.errorMessage`, '')
	);
	const errorCode = useSelector((state) =>
		get(state, `floPage.flos.${floIndex}.errorCode`, 0)
	);
	const errorType = useSelector((state) =>
		get(state, `floPage.flos.${floIndex}.errorType`, '')
	);

	useEffect(() => {
		if (errored) {
			window.doc360PreviewInstance?.onMessageFromMicroApp({
				type: 'floik_app_flo_error',
				payload: {
					floId,
					errorType: 'FAILED_TO_LOAD_FLO',
				},
			});
		}
	}, [errored]);

	if (isBoolean(isDomainValid) && !isDomainValid) {
		return (
			<div className={classNames(styles.errorContainer)}>
				<div className={styles.errorImageContainer}>
					<NotFoundSvg className={styles.image} />
				</div>
			</div>
		);
	}

	if (pageType === 'FOLDER') {
		return <Folders hideHeader={hideHeader} floId={floId} floIndex={floIndex} />;
	}

	switch (outputType) {
		case 'interactive demo':
			if (skipVideo && hasHotspots && hasSwiftPlayImages) {
				return (
					<LazyLoadedSwiftPlayInteractiveVideo
						hideHeader={hideHeader}
						hideHeaderBecauseOfData={hideHeaderBecauseOfData}
						floId={floId}
						floIndex={floIndex}
						themeColor={themeColor}
						view={view}
						wrapper={wrapper}
					/>
				);
			}
		case 'video': {
			if (hideHeader || !internalArticles)
				return (
					<VideoFlo
						hideHeader={hideHeader}
						floId={floId}
						hideHeaderBecauseOfData={hideHeaderBecauseOfData}
						floIndex={floIndex}
						themeColor={themeColor}
						view={view}
						wrapper={wrapper}
					/>
				);
			return (
				<div
					className={classNames(styles.pageVideoContainer, {
						[styles.isInternalArticle]: internalArticles,
					})}
				>
					<div className={classNames(styles.videofloInternalWrapper)}>
						<VideoFlo
							floId={floId}
							hideHeader={hideHeader}
							hideHeaderBecauseOfData={hideHeaderBecauseOfData}
							floIndex={floIndex}
							themeColor={themeColor}
							view={view}
							wrapper={wrapper}
						/>
					</div>
				</div>
			);
		}
		case 'document': {
			return (
				<GuideFlo
					hideHeader={hideHeader}
					floId={floId}
					hideHeaderBecauseOfData={hideHeaderBecauseOfData}
					floIndex={floIndex}
					themeColor={themeColor}
					view={view}
					wrapper={wrapper}
				/>
			);
		}
		default: {
			if (errored) {
				return (
					<div className={classNames(styles.errorContainer)}>
						{errorType === 'flospaceError' ? (
							<div className={styles.errorImageContainer}>
								<NotFoundSvg className={styles.image} />
							</div>
						) : errorCode === 403 ? (
							errorMessage
						) : (
							'Your Flo is not yet live. Please try again later.'
						)}
					</div>
				);
			}
			return <div className={styles.loadingContainer}>{/*<Loader fullPage />*/}</div>;
		}
	}
};

export default FloOutputType;
