import React from 'react';
import axios, {
	AxiosError,
	AxiosInstance,
	AxiosRequestConfig,
	AxiosResponse,
} from 'axios';
// import store from '../store';
import { get, isObject, set } from 'lodash';
import Cookie from 'js-cookie';
import { Dispatch } from '@reduxjs/toolkit';

import store from '../store';
import {
	createAnonymousUserAction,
	logoutAction,
	refreshTokenAction,
} from '../Containers/Routes/Routes.reducers';
import { API_BASE, WEBSITE_PRICING_PAGE } from '../Common/Common.env';
import { pushErrorState } from '../store/reducers/Routes.reducer';
import { showDialogAction } from '../Components/Dialog/Dialog.reducer';

import {
	POP_UP_402_FINGER_PRINT_USER_SUB_TITLE_TEXT,
	POP_UP_402_LOGGED_IN_USER_TITLE_TEXT,
} from '../Common/Common.constants';
import uniqId from 'uniqid';
import qs from 'qs';

const refreshUrl = `${API_BASE}/v1/authentication/refresh-token`;
const accessTokenUrl = `${API_BASE}/v1/authentication/access-token`;
const analyticsBaseUrl = '/assistance/analytics';

const queryParam = qs.parse(location.search.replace('?', ''));
const analyticsBlocked = get(queryParam, 'fab', 'false') === 'true';

const responseBody = (response: AxiosResponse) => {
	return response.data;
};

const responseHeader = (response: AxiosResponse) => {
	return response;
};

const sessionId = uniqId();

const isSkipVideo = get(queryParam, 'skip-video', 'false') === 'true';

let parentDomain = '';
try {
	parentDomain = window.location != window.parent.location ? document.referrer : '';
} catch (e) {
	console.error(e);
}

class API {
	instance: AxiosInstance;
	noAuth: boolean;
	getHeader: boolean;
	redirectOnError: boolean;
	customToken?: string;
	floIndex?: number;

	constructor(
		config: AxiosRequestConfig = {
			baseURL: '/',
			timeout: 35000,
		},
		noAuth?: boolean,
		getHeader?: boolean,
		redirectOnError?: boolean,
		customToken?: string,
		floIndex?: number
	) {
		this.instance = axios.create(config);
		this.noAuth = noAuth || false;
		this.getHeader = getHeader || false;
		this.redirectOnError = redirectOnError || false;
		this.customToken = customToken || '';
		this.floIndex = floIndex || 0;
		// @ts-ignore
		this.instance.interceptors.request.use(this.requestInterceptors);
		this.instance.interceptors.response.use(
			this.responseSuccessInterceptors,
			this.responseErrorInterceptors
		);
	}

	responseSuccessInterceptors = (response: AxiosResponse): AxiosResponse => response;
	responseErrorInterceptors = (response: AxiosError): Promise<AxiosError> => {
		const { status } = response.response || {};
		const error = get(response, 'response.data.data') || {};
		console.log(error);
		let url = '';
		let state = {};
		if (status === 401) {
			store.dispatch(logoutAction(''));
			// if(!this.instance.getUri().endsWith('/log')) {
			// 	store.dispatch(createAnonymousUserAction({}));
			// }
			return new Promise((resolve, reject) => resolve(response));
		}

		if (this.redirectOnError) {
			if (status === 404) {
				if (
					get(response, 'response.data.customErrorCode') &&
					get(response, 'response.data.customErrorCode') === 'LOGIN_REQUIRED'
				) {
					store.dispatch(createAnonymousUserAction({}));
					return new Promise((resolve) => resolve(response));
				}
				url = '/404';
			} else if (status === 403) {
				url = '/403';
			} else if (status === 500) {
				url = '/500';
			}
			if (url) {
				// @ts-ignore
				const { errorMessage, headerTitle } = error || {};
				if (errorMessage || headerTitle)
					store.dispatch(
						pushErrorState(
							url + `?error=${errorMessage}&errorHeader=${headerTitle}&status=${status}`
						)
					);
				else store.dispatch(pushErrorState(url + `?error=${error}&status=${status}`));
			}
		}

		const isFingerPrintSubscription = get(
			response,
			'response.data.fingerPrintSubscription'
		);
		const isUserSubscriptionOwner = get(response, 'response.data.userSubscriptionOwner');
		const errorTitle =
			get(response, 'response.data.data.headerTitle') ||
			POP_UP_402_LOGGED_IN_USER_TITLE_TEXT;
		const errorMsg =
			get(response, 'response.data.data.errorMessage') ||
			POP_UP_402_FINGER_PRINT_USER_SUB_TITLE_TEXT;

		if (status === 402) {
			store.dispatch(
				showDialogAction({
					data: {
						header: errorTitle,
						body: errorMsg,
						showConfirm: true,
						confirmButtonType: 'confirm',
						confirmButtonText: isFingerPrintSubscription ? 'Login' : 'Pricing Plans',
						onConfirm: (dispatch: Dispatch) => {
							if (isFingerPrintSubscription) {
								const token = Cookie.get('floik-end-user-v2-token');
								// @ts-ignore
								// window.location = `${API_BASE}/v1/authentication/authenticate?redirect_uri=/auth&redirect_host=${location.origin}&anonymous_token=${token}`;
							} else {
								window.open(WEBSITE_PRICING_PAGE, '_blank');
							}
						},
					},
					floIndex: this.floIndex,
				})
			);
		}

		if (status === 412) {
			const unsubscribedUser = isFingerPrintSubscription && isUserSubscriptionOwner;
			store.dispatch(
				showDialogAction({
					data: {
						header: unsubscribedUser ? '' : errorTitle,
						body: errorMsg,
						showConfirm: unsubscribedUser,
						confirmButtonType: 'confirm',
						confirmButtonText: 'Login',
						centerAlignFooter: unsubscribedUser,
						onConfirm: (dispatch: Dispatch) => {
							const token = Cookie.get('floik-end-user-v2-token');
							// @ts-ignore
							// window.location = `${API_BASE}/v1/authentication/authenticate?redirect_uri=/auth&redirect_host=${location.origin}&anonymous_token=${token}`;
						},
					},
					floIndex: this.floIndex,
				})
			);
		}

		if (response.response?.status === 401) {
			if (
				get(response, 'response.data.customErrorCode') &&
				get(response, 'response.data.customErrorCode') === 'LOGIN_REQUIRED'
			) {
				const token = Cookie.get('floik-end-user-v2-token');
				// @ts-ignore
				// window.location = `${API_BASE}/v1/authentication/authenticate?redirect_uri=/auth&redirect_host=${location.origin}&anonymous_token=${token}`;
			} else if (
				// @ts-ignore
				![accessTokenUrl, refreshUrl].includes(response.config.url) &&
				response.response?.status === 401
			) {
				store.dispatch(refreshTokenAction(''));
			}
		}
		if (
			// @ts-ignore
			refreshUrl === response.config.url &&
			// @ts-ignore
			[400, 401].includes(get(response, 'response.status'))
		) {
			store.dispatch(logoutAction(''));
		}
		const newResponse = set(response, 'data.redirectOnError', this.redirectOnError);
		return Promise.reject(newResponse);
	};

	requestInterceptors = (params?: AxiosRequestConfig) => {
		const controller = new AbortController();
		const token = !this.noAuth && this.getToken();
		const moreparams = parentDomain
			? {
					'floik-referer-type': 'embed',
					'floik-referer-url': parentDomain,
			  }
			: {
					'floik-referer-type': 'direct',
			  };
		const paramHeaders = get(params, 'headers') || {};
		const newParams = {
			...(params || {}),
			signal: controller.signal,
			headers: {
				...paramHeaders,
				...(token ? { Authorization: `Bearer ${token}` } : {}),
				'tab-session-id': sessionId,
				...moreparams,
			},
			defaults: {
				mode: 'cors',
				withCredentials: false,
				credentials: 'same-origin',
				headers: {
					...paramHeaders,
					Authorization: `Bearer ${token}`,
					'Access-Control-Allow-Origin': '*',
					'Access-Control-Allow-Headers':
						'Origin, X-Requested-With, Content-Type, Accept',
					'Access-Control-Allow-Methods': 'GET, PUT, POST, DELETE, OPTIONS',
					// 'Content-Type': 'application/json',
				},
			},
		};
		if (analyticsBlocked && params?.url?.includes(analyticsBaseUrl)) {
			controller.abort('ANALYTICS_BLOCKED');
		}
		return newParams;
	};

	getToken = () => {
		return (
			this.customToken ||
			Cookie.get('floik-end-user-v2-token') ||
			get(store.getState(), 'routerContainer.authToken')
		);
		// return get(store.getState(), 'user.authorization');
	};

	get = <T, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig) =>
		this.instance.get(url, config).then(this.getHeader ? responseHeader : responseBody);
	post = <T, B, R = AxiosResponse<T>>(
		url: string,
		body: B,
		config?: AxiosRequestConfig
	): Promise<R> =>
		this.instance
			.post(url, body, config)
			.then(this.getHeader ? responseHeader : responseBody);
	put = <T, B, R = AxiosResponse<T>>(
		url: string,
		body: B,
		config?: AxiosRequestConfig
	): Promise<R> =>
		this.instance
			.put(url, body, config)
			.then(this.getHeader ? responseHeader : responseBody);
	delete = <T, R = AxiosResponse<T>>(
		url: string,
		config?: AxiosRequestConfig
	): Promise<R> =>
		this.instance
			.delete(url, config)
			.then(this.getHeader ? responseHeader : responseBody);
}

export default API;
