import { AppBar, Button, Grid, Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react';
import styles from './PagePrimaryHeader.module.css';
import classNames from 'classnames';
import { RootState } from '../../store';
import { get, startsWith } from 'lodash';
import { useSelector } from 'react-redux';
import { useIsTabView } from '../../Common/Common.hooks';
import { APP_ENDPOINT, PUBLISH_ASSETS_BASE_URL } from '../../Common/Common.env';
import { ReactComponentLike } from 'prop-types';
import { getIsTabView, sanitizeHtml } from '../../Common/Common.utils';
import { ReactComponent as InteractiveDemoIcon } from '../../Common/images/ID-Icon.svg';
import { ReactComponent as FilmStripIcon } from '../../Common/images/FilmStrip.svg';
import {
	enableTryFloikSelector,
	headerBrandImageSelector,
} from '../../Common/Common.selector';
import { FLOIK_WEBSITE_LINK } from '../../Common/Common.constants';

const PrimaryHeader = ({
	className,
	internalArticles,
	floId,
	floIndex,
}: {
	internalArticles?: boolean;
	className?: string;
	floId: string;
	floIndex: number;
}) => {
	const floOutputType: string = useSelector((state) =>
		get(state, `floPage.flos.${floIndex}.floDetails.outputType`, '')
	);

	const FloInfoMap = {
		video: { icon: FilmStripIcon, title: 'Video Flo' },
		'interactive demo': { icon: InteractiveDemoIcon, title: 'Interactive demo Flo' },
		default: { icon: InteractiveDemoIcon, title: 'Interactive demo Flo' },
	};

	const brandImage = useSelector(headerBrandImageSelector(floIndex));
	const hideLogo = useSelector((state) =>
		get(state, 'floPage.floSpace.branding.headerConfigurationData.hideHeaderLogo')
	);
	let logoHyperLink: undefined | string = useSelector((state) =>
		get(state, 'floPage.floSpace.branding.headerConfigurationData.logoHyperLink')
	);

	if (!logoHyperLink && internalArticles) {
		logoHyperLink = FLOIK_WEBSITE_LINK;
	}

	const { icon: SVG, title: floIconTitle } = (get(FloInfoMap, floOutputType) ||
		FloInfoMap.default) as { icon: ReactComponentLike; title: string };

	const outputHeader: string = useSelector((state) =>
		get(state, `floPage.flos.${floIndex}.floDetails.heading`, '')
	);
	const enableTryFloik: boolean = useSelector(enableTryFloikSelector(floIndex));
	const floName: string = useSelector((state) =>
		get(state, `floPage.flos.${floIndex}.floDetails.name`, '')
	);
	const [isTabView, setIsTabView] = useState(getIsTabView(floIndex));
	useIsTabView((value: boolean) => {
		setIsTabView(value);
	}, floId);

	const brandHeaderImageLoader =
		useSelector((state) => get(state, `loaders.brandHeaderImage_${floIndex}`, 0)) > 0;
	const floLoader = useSelector((state) => get(state, `loaders.flo_${floIndex}`, 0)) > 0;
	const hideHeader = !floName || ((hideLogo || !brandImage) && !enableTryFloik);
	if (hideHeader) return <></>;
	return (
		<AppBar
			position="sticky"
			className={classNames(styles.container, className, {
				[styles.isTabView]: isTabView,
			})}
		>
			<Grid
				container
				classes={{ root: styles.gridContainer }}
				justifyContent="space-between"
				alignItems="center"
			>
				<Grid
					container
					item
					laptop={5}
					tablet={5}
					classes={{
						root: styles.leftGridContainer,
					}}
				>
					<Grid item mobile={12} className={styles.floikLogo}>
						{!hideLogo && brandImage && !(floLoader || brandHeaderImageLoader) && (
							<Button
								component={logoHyperLink ? 'a' : 'button'}
								variant={'text'}
								className={classNames(styles.logoAnchor, {
									[styles.noLink]: !logoHyperLink,
								})}
								target={logoHyperLink ? '_blank' : '_self'}
								href={logoHyperLink ? logoHyperLink : ''}
								disableFocusRipple
								disableRipple
								disableTouchRipple
							>
								<img
									className={styles.logoImage}
									src={brandImage}
									// src={brandImage || `${PUBLISH_ASSETS_BASE_URL}/images/floik-logo.svg`}
									alt="logo"
								/>
							</Button>
						)}
						{isTabView && false && (
							<div className={styles.floDetailsHeader}>
								<Tooltip arrow title={floIconTitle} placeholder="bottom-end">
									<SVG className={classNames(styles.svgIcon, styles.idBGColor)} />
								</Tooltip>
								<h1 className={styles.floNameContainer}>
									<Tooltip arrow title={floName} placeholder="bottom-end">
										<div
											className={classNames(styles.floName, 'text-ellipsis')}
											dangerouslySetInnerHTML={{
												__html: sanitizeHtml(outputHeader || floName),
											}}
											title={floName}
										></div>
									</Tooltip>
								</h1>
							</div>
						)}
					</Grid>
				</Grid>
				<Grid
					item
					desktop={5}
					laptop={5}
					tablet={5}
					mobile={5}
					classes={{
						root: styles.rightGridContainer,
					}}
				>
					{!floLoader && enableTryFloik && (
						<Button
							variant="contained"
							className={classNames(styles.tryFloikButton, {
								[styles.internalArticles]: internalArticles,
							})}
							href={APP_ENDPOINT}
							component="a"
							target="_blank"
							disableElevation
						>
							<span className={styles.tryFloikButtonText}>Try Floik</span>
						</Button>
					)}
				</Grid>
			</Grid>
		</AppBar>
	);
};

export default PrimaryHeader;
