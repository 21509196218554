import { createSlice, Dispatch } from '@reduxjs/toolkit';
import { ReactNode } from 'react';
import { cancelButtonText, confirmButtonText } from '../../Common/Common.constants';
import { DialogItemType } from '../VideoDialog/VideoDialog.reducer';

export interface DialogItemType {
	visible: boolean;
	header: ReactNode;
	body: ReactNode;
	disablePortal?: boolean;
	container?: Element | (() => Element | null) | null;
	id?: string;
	classContainer?: string;
	bodyClassName?: string;
	classDialogRoot?: string;
	classDialogConfirmButton?: string;
	confirmButtonText: string;
	confirmButtonType?: string;
	cancelButtonText: string;
	hideClosebutton?: boolean;
	disableAutoFocus?: boolean;
	confirmButtonUrl?: string;
	noBackdropClick?: boolean;
	showConfirm: boolean;
	sending: boolean;
	centerAlignFooter: boolean;
	collabAdding: boolean;
	showCancel: boolean;

	onCancel?(): void;

	onConfirm?(dispatch: Dispatch): void;

	headerClassName?: string;
	classes?: { closeIcon?: string };
}

type DialogReducerType = {
	[key: string]: DialogItemType;
};

const initialState = Object.freeze({
	visible: false,
	id: '',
	header: 'header',
	body: 'body',
	showConfirm: false,
	showCancel: false,
	sending: false,
	centerAlignFooter: false,
	collabAdding: false,
	noBackdropClick: false,
	onCancel: undefined,
	onConfirm: undefined,
	classContainer: '',
	bodyClassName: '',
	hideClosebutton: false,
	classDialogConfirmButton: '',
	classDialogRoot: '',
	cancelButtonText: cancelButtonText,
	confirmButtonText: confirmButtonText,
});

const dialogReducers = {
	showDialog(
		state: DialogReducerType,
		{ payload }: { payload: { data: Partial<DialogReducerType>; floIndex: number } }
	) {
		return {
			...state,
			[payload.floIndex]: { ...initialState, ...payload.data, visible: true },
		};
	},
	hideDialog(state: DialogReducerType, { payload }: { payload: number }) {
		return {
			...state,
			[payload]: initialState,
		};
	},
};

export const dialogReducer = createSlice({
	name: 'dialogReducer',
	initialState,
	// @ts-ignore
	reducers: dialogReducers,
});

export default dialogReducer.reducer;

export const showDialogAction = dialogReducer.actions.showDialog;
export const hideDialogAction = dialogReducer.actions.hideDialog;
