import { CropStateType } from '../Pages/Flo/Flo.types';
import { scaleLinear } from 'd3-scale';
import { get } from 'lodash';

function gcd(a: number, b: number): number {
	while (b !== 0) {
		const temp = b;
		b = a % b;
		a = temp;
	}
	return a;
}

export function calculateAspectRatio(width: number, height: number): number {
	const divisor = gcd(width || 0, height || 0);
	const ratioWidth = width / divisor;
	const ratioHeight = height / divisor;
	return ratioWidth / ratioHeight;
}

export const calculateAspectRatioFit = (
	srcWidth: number,
	srcHeight: number,
	maxWidth: number | undefined,
	maxHeight: number | undefined
) => {
	let ratio = 0;
	if (maxHeight && maxWidth) {
		ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);
	} else if (maxWidth) {
		ratio = maxWidth / srcWidth;
	} else if (maxHeight) {
		ratio = maxHeight / srcHeight;
	}
	return { width: srcWidth * ratio, height: srcHeight * ratio };
};

export const calculateVideoCropMatrix = (
	currentVideoContainerDim: { width: number; height: number },
	actualVideoConfig: { width: number; height: number } | undefined,
	croppedDimension: CropStateType | undefined,
	isEditingCrop: boolean
) => {
	const newValue = calculateAspectRatioFit(
		actualVideoConfig?.width || 0,
		actualVideoConfig?.height || 0,
		currentVideoContainerDim?.width || 0,
		currentVideoContainerDim?.height || 0
	);
	const offsetLeft =
		get(currentVideoContainerDim, 'width', 0) - get(newValue, 'width', 0);
	const offsetTop =
		get(currentVideoContainerDim, 'height', 0) - get(newValue, 'height', 0);
	if (isEditingCrop || !croppedDimension?.enable || !actualVideoConfig) {
		return {
			offsetLeft,
			width: newValue.width,
			height: newValue.height,
			offsetTop,
		};
	}

	const aspectRatio = calculateAspectRatio(
		actualVideoConfig.width,
		actualVideoConfig.height
	);

	let scaleRatio = Math.min(
		actualVideoConfig.height /
			Math.round(
				Math.abs(
					get(croppedDimension, 'values.y2', actualVideoConfig.height) -
						get(croppedDimension, 'values.y', 0)
				)
			),
		actualVideoConfig.width /
			Math.round(
				Math.abs(
					get(croppedDimension, 'values.x2', actualVideoConfig.width) -
						get(croppedDimension, 'values.x', 0)
				)
			)
	);
	const xScaleFunction = scaleLinear([0, actualVideoConfig.width], [0, newValue.width]);
	const xScale = xScaleFunction(Math.round(get(croppedDimension, 'values.x', 0)));
	const yScaleFunction = scaleLinear([0, actualVideoConfig.height], [0, newValue.height]);
	const yScale = yScaleFunction(Math.round(get(croppedDimension, 'values.y', 0)));
	return {
		scaleRatio,
		translateX: offsetLeft * 0.5 * scaleRatio,
		translateY: offsetTop * 0.5 * scaleRatio,
		transform: `scale(${scaleRatio}) translate(${
			-1 * (xScale - (offsetLeft * 0.5) / scaleRatio)
		}px, ${-1 * (yScale - (offsetTop * 0.5) / scaleRatio)}px)`,
		transformOrigin: '0 0',
		offsetLeft,
		width: newValue.width,
		height: newValue.height,
		offsetTop,
	};
};

export function calculateScaleValue(
	initialScale: number,
	modifiedScale: number,
	oldValue: number
): number {
	if (
		initialScale === modifiedScale ||
		initialScale === 0 ||
		modifiedScale === 0 ||
		initialScale === null ||
		modifiedScale === null
	) {
		return oldValue; // Avoid division by zero, return the old value
	}
	const scale = scaleLinear().domain([0, initialScale]).range([0, modifiedScale]);
	const val = scale(oldValue);
	//console.log('val', val, initialScale, modifiedScale, oldValue);
	return val;
}
