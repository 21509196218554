import React, {
	lazy,
	MutableRefObject,
	useCallback,
	useEffect,
	useRef,
	useState,
} from 'react';
import classNames from 'classnames';
import styles from '../../Flo.module.css';
import { Button, Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { debounce, find, forEach, get, isFunction, min, startsWith } from 'lodash';
import { useParams } from 'react-router-dom';
import { appliedCropSelector, getEditorElementConfigSelector } from '../../Flo.selectors';
import {
	elementBeanListType,
	floTrackType,
	publishElementsDataType,
} from '../../Flo.types';
import { CropAreaType } from '../../../../Components/Annotations/Annotations.types';
import { setCropDataAction } from '../../Flo.reducers';
import Loader from '../../../../Components/Loader/Loader';
import qs from 'qs';
import { formatDate } from '../../../../Common/FormatDate';
import { calculateAspectRatioFit } from '../../../../utils/video.utils';
import { RootState } from '../../../../store';
import videojs from 'video.js';
import uniqId from 'uniqid';
import { SYNTHESISED_AI_VOICE } from '../../../../Common/Common.constants';
import {
	getContrastColor,
	getIsEmbed,
	getIsTabView,
	getWindowDimensions,
	sanitizeHtml,
	sanitizeHtmlLinks,
} from '../../../../Common/Common.utils';
import { useIsTabView } from '../../../../Common/Common.hooks';
import { MAX_TABLET_VIEW_WIDTH } from '../../../../Common/Common.params';

const queryParam = qs.parse(location.search.replace('?', ''));
const appVersion = parseFloat(
	get(document.querySelector('meta[name="app-version"]'), 'content') || '1'
);

const VideoPlayer = lazy(() => import('../../../../Components/VideoPlayer/VideoPlayer'));

const headerHeight = {
	default: 64,
	hideHeader: 0,
	internalArticle: 100,
};

function ViewPageVideo(props: unknown) {
	const {
		//@ts-ignore
		hideHeader,
		//@ts-ignore
		setPlayerRef,
		//@ts-ignore
		videoTrack,
		//@ts-ignore
		screenShareTrack,
		//@ts-ignore
		audioTrack,
		//@ts-ignore
		setVideoReady,
		//@ts-ignore
		setScreenShareTrack,
		//@ts-ignore
		setAudioTrack,
		//@ts-ignore
		setVideoTrack,
		//@ts-ignore
		playerRef,
		//@ts-ignore
		setError,
		//@ts-ignore
		onSetPlayerDim,
		//@ts-ignore
		floName,
		//@ts-ignore
		createdBy: createdByName,
		//@ts-ignore
		createdOn,
		//@ts-ignore
		propSetVideoSize,
		//@ts-ignore
		withBorder,
		//@ts-ignore
		isTabView,
		//@ts-ignore
		floBodyRef,
		//@ts-ignore
		hidePlayer,
		//@ts-ignore
		floId,
		//@ts-ignore
		floIndex,
	} = props;
	const [hasAudio, setHasAudio] = useState(false);
	const inFullScreen = useSelector((state: RootState) =>
		get(state, `floPage.flos.${floIndex}.inFullScreen`, false)
	) as boolean;
	const internalArticles = useSelector((state: RootState) =>
		hideHeader || inFullScreen
			? false
			: get(state, `floPage.flos.${floIndex}.floDetails.internalArticles`, false)
	) as boolean;
	const elementVoiceResources = useSelector((state) =>
		get(state, `floPage.flos.${floIndex}.floPrimaryTrack.elementVoiceResources`)
	);
	const floElements: {
		[key: string]: publishElementsDataType[];
	} = useSelector((state) => get(state, `floPage.flos.${floIndex}.editorsElements`, {}));
	const elementsVoiceEnabled =
		get(floElements, 'elementsWithVoiceAnnotation', []).length > 0;

	const backgroundMusicResource = useSelector((state) =>
		get(state, `floPage.flos.${floIndex}.floPrimaryTrack.backgroundMusicResource`)
	);
	const isAiVoice = useSelector((state: RootState): boolean => {
		const elementsConfig: elementBeanListType[] =
			getEditorElementConfigSelector(floIndex)(state);
		const selectedElementsConfig = find(
			elementsConfig,
			(e) => get(e, 'editorElementName') === 'voice-over'
		);
		const voiceOverProperties = selectedElementsConfig?.properties;
		return voiceOverProperties?.voiceOverMode === SYNTHESISED_AI_VOICE;
	});
	const showTracks = useSelector((state) =>
		get(state, `floPage.flos.${floIndex}.tracks`)
	);
	const interactiveMode = useSelector((state) =>
		get(state, `floPage.flos.${floIndex}.floDetails["interactive-mode"]`)
	);
	const enableAnnotation = useSelector((state) =>
		get(state, `floPage.flos.${floIndex}.floDetails.allow_public_comments`)
	);
	const showSubtitles = useSelector((state) =>
		get(state, `floPage.flos.${floIndex}.floDetails.display_subtitles`)
	);
	const elements = useSelector((state) => get(state, `floPage.flos.${floIndex}.tracks`));
	const appliedCrop = useSelector(appliedCropSelector(floIndex));
	const dispatch = useDispatch();
	const duration: number = useSelector(
		(state: RootState) => get(state, `floPage.flos.${floIndex}.duration`, 0) as number
	);
	const [videoSize, setVideoSize] = useState<
		{ height: number; width: number } | null | undefined
	>();
	const selectedCommentId = useSelector((state) =>
		get(state, `floPage.flos.${floIndex}.scrollTo.commentId`)
	);
	const createdBy = useSelector((state) =>
		get(state, `floPage.flos.${floIndex}.floDetails.createdBy.userProfile`)
	);
	const outputHeading: string = useSelector((state) =>
		get(state, `floPage.flos.${floIndex}.floDetails.heading`, '')
	);
	const outputDesc: string = useSelector((state) =>
		get(state, `floPage.flos.${floIndex}.floDetails.description`, '')
	);

	const setSelectedCommentId = useCallback((commentId: string) => {}, []);

	const setPlayerRefHandler = useCallback(
		(videRef: HTMLVideoElement): void => {
			// @ts-ignore
			setPlayerRef(videRef);
		},
		[setPlayerRef]
	);
	const setVideoSizeCallback = useCallback(
		(data: { width: number; height: number } | undefined | null) => {
			setVideoSize(data);
			if (isFunction(propSetVideoSize)) {
				propSetVideoSize(data);
			}
		},
		[setVideoSize, propSetVideoSize]
	);
	const onAreaSelected = useCallback(
		(cropData: CropAreaType) => {
			dispatch(
				setCropDataAction({
					data: {
						enable: true,
						values: cropData,
						videoSize: {
							width:
								get(screenShareTrack, 'sources[0].width') ||
								get(videoTrack, 'sources[0].width'),
							height:
								get(screenShareTrack, 'sources[0].height') ||
								get(videoTrack, 'sources[0].height'),
						},
					},
					floId,
					floIndex,
				})
			);
		},
		[videoTrack, screenShareTrack]
	);

	useEffect(() => {
		const videoOptions: any = [];
		const screenShareOptions: any = [];
		const audioOptions: any = [];
		const subtitleTrack: any = [];
		let screenShareSubtitle: any;
		let videoSubtitle: any;
		let audioSubtitle: any;
		let hasAudioFile = false;
		if (showTracks) {
			forEach(showTracks, (item: floTrackType) => {
				const {
					type,
					url,
					subtitleUrl,
					hlsURL,
					hasThumbnails,
					thumbnailsPath,
					hasAudio,
				} = item;

				const enableHlsCredentials =
					process.env.REACT_APP_ENABLE_HLS_CREDENTIALS === 'true';
				const src = hlsURL || url;
				const track = {
					src,
					withCredentials: enableHlsCredentials,
					hasThumbnails,
					thumbnailsPath,
					preload: 'metadata',
					id: item.id,
					// @ts-ignore
					height: item.height,
					// @ts-ignore
					width: item.width,
				};
				if (subtitleUrl && showSubtitles) {
					const subtitleData = {
						src: subtitleUrl,
						withCredentials: enableHlsCredentials,
						kind: 'subtitles',
						mode: 'showing',
						srclang: 'en',
						default: true,
						label: 'default',
						id: uniqId(),
					};

					if (type === 'video') {
						videoSubtitle = subtitleData;
					}
					if (type === 'screenShare') {
						screenShareSubtitle = subtitleData;
					}
					if (type === 'audio') {
						audioSubtitle = subtitleData;
					}
				}
				if (src && type === 'video') {
					videoOptions.push(track);
				} else if (src && type === 'screenShare') {
					screenShareOptions.push(track);
				} else if (src && type === 'audio') {
					track.src = url;
					// @ts-ignore
					track.type = 'audio/webm';
					audioOptions.push(track);
				}
				hasAudioFile = hasAudio || hasAudioFile;
			});
		}
		subtitleTrack.push(screenShareSubtitle || videoSubtitle || audioSubtitle);
		const videoTracksOptions: videojs.PlayerOptions = {};
		const screenShareTracksOptions: videojs.PlayerOptions = {};
		if (screenShareOptions.length > 0) {
			screenShareTracksOptions.tracks = subtitleTrack;
		} else {
			videoTracksOptions.tracks = subtitleTrack;
		}
		if (!isAiVoice || screenShareOptions.length === 0) {
			setVideoTrack({
				sources: videoOptions,
				...videoTracksOptions,
			});
		} else {
			setVideoTrack({
				sources: [],
			});
		}
		setScreenShareTrack({
			sources: screenShareOptions,
			...screenShareTracksOptions,
		});
		setAudioTrack({ sources: audioOptions });
		//@ts-ignore
		setHasAudio(hasAudioFile || elementsVoiceEnabled || backgroundMusicResource?.url);
	}, [
		setHasAudio,
		showTracks,
		showSubtitles,
		setScreenShareTrack,
		setVideoTrack,
		isAiVoice,
		elementVoiceResources,
		backgroundMusicResource,
	]);
	return (
		<>
			<VideoPlayer
				hidePlayer={hidePlayer}
				floId={floId}
				floIndex={floIndex}
				elementsVoiceEnabled={elementsVoiceEnabled}
				hideHeader={hideHeader || inFullScreen}
				key={`floId-${floId}`}
				isAiVoice={isAiVoice}
				hasAudioTrack={hasAudio}
				floBodyRef={floBodyRef}
				isTabView={isTabView}
				hasError={false}
				videoSize={videoSize}
				setPlayerRef={setPlayerRefHandler}
				duration={duration}
				withBorder={withBorder}
				floName={outputHeading}
				createdByName={createdByName}
				createdOn={createdOn}
				videoOptions={videoTrack}
				internalArticles={internalArticles}
				screenShareOptions={screenShareTrack}
				// @ts-ignore
				audioOptions={audioTrack}
				// @ts-ignore
				appliedCrop={appliedCrop}
				className={classNames('floMainVideoPlayer')}
				selectedCommentId={selectedCommentId}
				setSelectedCommentId={setSelectedCommentId}
				setVideoReady={setVideoReady}
				onSetPlayerDim={onSetPlayerDim}
				onAreaSelected={onAreaSelected}
				createdBy={createdBy}
				boundToContainer
				minTrimTime={1} //Trim time in seconds
				disableAnnotations={!enableAnnotation || interactiveMode}
				setError={setError}
				setVideoSize={setVideoSizeCallback}
				elementVoiceResources={elementVoiceResources}
				backgroundMusicResource={backgroundMusicResource}
			/>
		</>
	);
}

const VideoFlo = ({
	hideHeader,
	hideHeaderBecauseOfData,
	floId,
	floIndex,
	themeColor,
	view,
	wrapper,
}: {
	hideHeader?: boolean;
	hideHeaderBecauseOfData?: boolean;
	floId: string;
	floIndex: number;
	themeColor: string;
	view: string;
	wrapper: string;
}) => {
	const inFullScreen = useSelector((state: RootState) =>
		get(state, `floPage.flos.${floIndex}.inFullScreen`, false)
	) as boolean;
	const internalArticles = useSelector((state: RootState) =>
		hideHeader || inFullScreen
			? false
			: get(state, `floPage.flos.${floIndex}.floDetails.internalArticles`, false)
	) as boolean;
	const dontShowBorder = useSelector((state: RootState) =>
		get(state, `floPage.flos.${floIndex}.floDetails.dontShowBorder`, false)
	) as boolean;
	const showAuthor = useSelector((state) =>
		get(state, `floPage.floDetails['show-author']`, true)
	);
	const interactiveMode = useSelector((state) =>
		get(state, `floPage.flos.${floIndex}.floDetails["interactive-mode"]`)
	);

	const loader = useSelector((state) => get(state, `loaders.flo_${floIndex}`, 0) > 0);
	const currentFlo = useSelector((state) =>
		get(state, `floPage.flos.${floIndex}.floDetails`)
	);
	const [videoTrack, setVideoTrack] = useState({});
	const [videoContainerHeight, setVideoContainerHeight] = useState(0);
	const [videoContainerWidth, setVideoContainerWidth] = useState(0);
	const [videoTitleWidth, setVideoTitleWidth] = useState(0);
	const [videoSize, setVideoSize] = useState({ width: 0, height: 0 });
	const [audioTrack, setAudioTrack] = useState({});
	const [screenShareTrack, setScreenShareTrack] = useState({});
	const [hasError, setError] = useState<boolean>(false);
	const [playerRef, setPlayerRef] = useState<HTMLVideoElement | undefined>();
	const [videoReady, setVideoReady] = useState(false);
	const floBodyRef = useRef<HTMLDivElement>() as MutableRefObject<HTMLDivElement>;
	const [isTabView, setIsTabView] = useState(getIsTabView(floId));
	useIsTabView((val: boolean) => {
		setIsTabView(val);
	}, floId);
	const [playerDim, setPlayerDim] = useState({ width: 0, height: 0 });
	const isEmbed = useSelector((state) =>
		get(state, `floPage.flos.${floIndex}.floDetails["is-embed"]`, false)
	);

	const outputHeader: string = useSelector((state) =>
		get(state, `floPage.flos.${floIndex}.floDetails.heading`, '')
	);
	const floName: string = useSelector((state) =>
		get(state, `floPage.flos.${floIndex}.floDetails.name`, '')
	);
	const outputDesc: string = useSelector((state) =>
		get(state, `floPage.flos.${floIndex}.floDetails.description`, '')
	);
	const withBorder = !dontShowBorder && !hideHeader;

	const onSetPlayerDim = useCallback(
		(data: { width: number; offsetWidth: number; height: number }) => {
			setPlayerDim(data);
			setVideoContainerWidth(data.width + (isTabView ? 0 : 60));
			setVideoTitleWidth(data.width);
		},
		[setVideoContainerWidth, setVideoTitleWidth, isTabView]
	);

	const onResize = useCallback(() => {
		let diffHeight = headerHeight.default + 80;
		let diffWidth = 0;
		if (hideHeader) {
			diffHeight = headerHeight.hideHeader;
		}
		const windowDimensions = getWindowDimensions(floIndex);
		let windowWidth = windowDimensions.width - (isTabView ? 32 : 32); // 32 is for the padding
		if (internalArticles) {
			diffHeight = headerHeight.internalArticle + 80;
			windowWidth = min([windowWidth, 1040 + 280]) as number;
		}
		if (withBorder && !hideHeader) {
			diffHeight = headerHeight.internalArticle + 80;
		}

		let { height, width } = calculateAspectRatioFit(
			get(screenShareTrack, 'sources[0].width') ||
				get(videoTrack, 'sources[0].width') ||
				16,
			get(screenShareTrack, 'sources[0].height') ||
				get(videoTrack, 'sources[0].height') ||
				9,
			windowWidth - diffWidth,
			windowDimensions.height - diffHeight
		);

		if (!withBorder && hideHeader) {
			height = windowDimensions.height;
		}
		let newContainerHeight =
			height +
			(hideHeader || width > MAX_TABLET_VIEW_WIDTH || !isTabView
				? 0
				: windowDimensions.width < 650 && windowDimensions.width > 350
				? 12
				: 16);
		if (wrapper && wrapper !== 'rounded') {
			const availableHeight = windowDimensions.height - diffHeight;
			if (newContainerHeight + 32 <= availableHeight) {
				newContainerHeight += 32;
			}
		}
		setVideoContainerHeight(newContainerHeight);

		setVideoContainerWidth(width + (isTabView ? 0 : 60));
	}, [
		screenShareTrack,
		videoTrack,
		videoSize,
		setVideoContainerHeight,
		setVideoContainerWidth,
		internalArticles,
		hideHeader,
		isTabView,
		isEmbed,
		wrapper,
	]);

	useEffect(() => {
		const debounced = debounce(onResize, 500);
		window.addEventListener('resize', debounced, true);
		debounced();
		setTimeout(() => {
			debounced();
		}, 1000);
		try {
			const elem = document.querySelector('#floik-html-loader');
			if (elem) {
				document.body.removeChild(elem);
			}
		} catch (e) {
			console.error(e);
		}
		return () => {
			window.removeEventListener('resize', debounced, true);
		};
	}, [onResize]);

	const hidePlayer = !!(
		playerDim.width &&
		playerDim.height &&
		(playerDim.width < 130 || playerDim.height < 90)
	);

	return (
		<Grid
			container
			item
			desktop={12}
			laptop={12}
			tablet={12}
			mobile={12}
			justifyContent="center"
			data-device={isTabView ? 'tab' : 'others'}
			classes={{
				root: classNames(styles.floBody, styles.videoFlo, {
					[styles.hideHeaderBecauseOfData]: hideHeaderBecauseOfData,
					[styles.notEmbed]: !isEmbed,
					[styles.fullHeight]: hideHeader,
					[styles.floBodyInternal]: internalArticles,
					[styles.videoWidthLessThan350]: playerDim.width < 350,
					[styles.videoWidthLessThan650]: playerDim.width < MAX_TABLET_VIEW_WIDTH,
					[styles.videoWidthLessThan800]: playerDim.width < 800,
				}),
			}}
			style={
				{
					...styles,
					'--container-background':
						themeColor === 'none'
							? 'var(--global-flo-background)'
							: getContrastColor(themeColor) === '#fff'
							? `linear-gradient(rgba(1, 1, 1, 0.5), rgba(1, 1, 1, 0.5)), ${themeColor}`
							: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), ${themeColor}`,
				} as React.CSSProperties
			}
			ref={floBodyRef}
		>
			{hasError && (
				<div className={styles.hasError}>
					<Button
						variant="contained"
						disableElevation
						className={styles.buttonReload}
						onClick={() => {
							location.reload();
						}}
					>
						{' '}
						Reload{' '}
					</Button>
				</div>
			)}
			<Grid
				item
				desktop={12}
				laptop={12}
				tablet={12}
				mobile={12}
				classes={{
					root: classNames(styles.leftGridContainer, {
						[styles.leftGridFullWidth]: hideHeader,
					}),
				}}
				style={{
					opacity: videoReady ? 1 : 0,
					height: `${videoContainerHeight}px`,
					width: `${videoContainerWidth}px`,
					overflow: 'hidden',
				}}
			>
				{loader ? (
					<Loader className={styles.loaderContainer} wrapperClass={styles.loader} />
				) : (
					<ViewPageVideo
						//@ts-ignore
						setPlayerRef={setPlayerRef}
						floId={floId}
						floIndex={floIndex}
						isTabView={isTabView}
						hideHeader={hideHeader}
						playerRef={playerRef}
						floName={outputHeader || floName}
						createdBy={get(currentFlo, 'createdBy.name', '') as string}
						createdOn={formatDate(get(currentFlo, 'lastPublishedOn', '')) as string}
						screenShareTrack={screenShareTrack}
						videoTrack={videoTrack}
						audioTrack={audioTrack}
						propSetVideoSize={setVideoSize}
						videoReady={videoReady}
						onSetPlayerDim={onSetPlayerDim}
						setVideoReady={setVideoReady}
						setVideoTrack={setVideoTrack}
						setAudioTrack={setAudioTrack}
						setScreenShareTrack={setScreenShareTrack}
						setError={setError}
						withBorder={withBorder}
						floBodyRef={floBodyRef}
						hidePlayer={hidePlayer}
					/>
				)}
			</Grid>
			{videoReady && videoContainerWidth > 0 && !(hideHeader && !internalArticles) && (
				<Grid
					desktop={12}
					laptop={12}
					tablet={12}
					mobile={12}
					classes={{
						root: styles.floDetails,
					}}
					style={
						hidePlayer
							? {
									opacity: videoTitleWidth,
									padding: '10px',
							  }
							: {
									opacity: videoTitleWidth,
									width: videoTitleWidth,
									maxWidth: videoTitleWidth,
							  }
					}
				>
					<div className={styles.floDetailsHeader}>
						<h1 className={styles.floNameContainer}>
							<div
								className={styles.floName}
								style={{
									color: getContrastColor(themeColor),
								}}
								dangerouslySetInnerHTML={{
									__html: sanitizeHtml(outputHeader || floName),
								}}
							></div>
							<div className={styles.viewCounts}></div>
						</h1>
						<div
							className={styles.floMeta}
							style={{
								color: getContrastColor(themeColor),
								opacity: 0.7,
							}}
						>
							{!showAuthor ? (
								''
							) : (
								<>
									{get(currentFlo, 'createdBy.name')}{' '}
									<span className={styles.dash}> - </span>
								</>
							)}
							{/*// @ts-ignore*/}
							{formatDate(get(currentFlo, 'lastPublishedOn'))}
						</div>
						{outputDesc && (
							<div
								className={styles.floDescription}
								style={
									{
										'--desc-color': getContrastColor(themeColor),
									} as React.CSSProperties
								}
								dangerouslySetInnerHTML={{
									__html: sanitizeHtmlLinks(outputDesc),
								}}
							></div>
						)}
					</div>
				</Grid>
			)}
		</Grid>
	);
};

export default VideoFlo;
