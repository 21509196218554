import { RootState } from '../../store';
import { find, get, reduce } from 'lodash';

export const floTracks = (floIndex: number) => (state: RootState) =>
	get(state, `floPage.flos.${floIndex}.tracks`);
export const floDuration = (floIndex: number) => (state: RootState) =>
	get(state, `floPage.flos.${floIndex}.duration`);
export const floComments = (floIndex: number) => (state: RootState) =>
	get(state, `floPage.flos.${floIndex}.comments`);
export const floCommentReplies = (floIndex: number) => (state: RootState) =>
	get(state, `floPage.flos.${floIndex}.commentReplies`);
export const floSpaceIdSelector = (floIndex: number) => (state: RootState) =>
	get(state, `floPage.flos.${floIndex}.floDetails.flospaceId`);
export const floDetailsSelector = (floIndex: number) => (state: RootState) =>
	get(state, `floPage.flos.${floIndex}.floDetails`);
export const newCommentStartId = (floIndex: number) => (state: RootState) =>
	get(state, `floPage.flos.${floIndex}.newCommentStartId`);
export const getStageEditCommentId =
	(floIndex: number) =>
	(state: RootState): string =>
		get(state, `floPage.flos.${floIndex}.editingCommentId`);
export const floAnnotations = (floIndex: number) => (state: RootState) =>
	get(state, `floPage.flos.${floIndex}.comments.data`);
export const createdBySelector = (floIndex: number) => (state: RootState) => ({
	name: get(state, `floPage.flos.${floIndex}.floDetails.createdBy.name`),
	userId: get(state, `floPage.flos.${floIndex}.floDetails.createdBy.userId`),
	profilePicUrl: get(
		state,
		`floPage.flos.${floIndex}.floDetails.createdBy.userProfile.profilePicUrl`
	),
	avatarColor: get(
		state,
		`floPage.flos.${floIndex}.floDetails.createdBy.userProfile.profilePreference.avatarColor`
	),
	profilePreference: {
		avatarColor: get(
			state,
			`floPage.flos.${floIndex}.floDetails.createdBy.userProfile.profilePreference.avatarColor`
		),
	},
	email: get(state, `floPage.flos.${floIndex}.floDetails.createdBy.email`),
});

export const appliedCropSelector = (floIndex: number) => (state: RootState) => {
	// const floResources = get(state, `floPage.flos.${floIndex}.tracks`);
	const floResources = get(
		state,
		`floPage.flos.${floIndex}.floDetails.floResources.resourceGroupDetailList`
	);
	const dims = reduce(
		floResources,
		(
			acc: {
				[key: string]: {
					height: number;
					width: number;
				};
			},
			item
		): {
			[key: string]: {
				height: number;
				width: number;
			};
		} => {
			// @ts-ignore
			acc[item.type] = reduce(
				get(item, 'resources'),
				// @ts-ignore
				(acc2, resource) => {
					if (get(resource, 'resourceType') === 'PUBLISHED_TRANSCRIBE') {
						return {
							width: get(resource, 'resourceProperties.width'),
							height: get(resource, 'resourceProperties.height'),
						};
					}
					return acc2;
				},
				{
					width: 0,
					height: 0,
				}
			);

			return acc;
		},
		{}
	);

	const cropConfig = find(get(state, `floPage.flos.${floIndex}.editorsElementsConfig`), [
		'editorElementName',
		'crop',
	]);
	return {
		enable: !!cropConfig,
		values: {
			x: Number(get(cropConfig, 'properties.canvasRenderConfig.canvasCoordinates.x', 0)),
			y: Number(get(cropConfig, 'properties.canvasRenderConfig.canvasCoordinates.y', 0)),
			x2:
				Number(get(cropConfig, 'properties.canvasRenderConfig.canvasCoordinates.x', 0)) +
				Number(
					get(cropConfig, 'properties.canvasRenderConfig.canvasCoordinates.width', 0)
				),
			y2:
				Number(get(cropConfig, 'properties.canvasRenderConfig.canvasCoordinates.y', 0)) +
				Number(
					get(cropConfig, 'properties.canvasRenderConfig.canvasCoordinates.height', 0)
				),
			// @ts-ignore
			windowSize:
				get(cropConfig, 'properties.canvasRenderConfig.windowCoordinates') ||
				dims.screenShare ||
				dims.video,
		},
		// @ts-ignore
		videoSize:
			get(cropConfig, 'properties.canvasRenderConfig.windowCoordinates') ||
			dims.screenShare ||
			dims.video,
	};
};

export const getEditorElementConfigSelector =
	(floIndex: number) => (state: RootState) => {
		return get(state, `floPage.flos.${floIndex}.editorsElementsConfig`, []);
	};
