import { createSlice } from '@reduxjs/toolkit';
import {
	max,
	reject,
	set,
	sortBy,
	concat,
	get,
	find,
	cloneDeep,
	isEmpty,
	findIndex,
	filter,
	map,
} from 'lodash';

import {
	setFloStateType,
	floTrackType,
	setFloCommentsType,
	commentType,
	createdByType,
	FloDetailsType,
	scrollToType,
	updateFloStatusType,
	updateFloPublicAccessType,
	updateFloPrivateAcessType,
	setSettingsType,
	deleteCommentPayloadType,
	SSEDataType,
	elementsDataType,
	CropStateType,
	TrimStateType,
	PostProcessConfigType,
	floOutputMetadataType,
	publishElementsDataType,
	PublishedClickEvent,
	CommentsPopupType,
	stepsElementType,
	CanvasRenderConfig,
	SwiftPlayImageType,
	elementBeanListType,
} from './Flo.types';
import {
	AnnotationSnapshot,
	AnnotationState,
	AnnotationMetaSnapshot,
	setAnnotationsByTimeType,
} from '../../Components/Annotations/Annotations.types';
import { Axios, AxiosError } from 'axios';
import { GuideDataType } from './pages/GuideFlo/Gude.types';
import { bool } from 'prop-types';
import SwiftPlayReducer from './pages/SwiftplayInteractiveMode/SwiftplayInteractiveMode.reducer';
import { mergedType, Subtitle } from '../../Common/Common.utils';
import { FloSpaceConfigOutput } from './flospace/flospaceParser';

// const floReducers = {};
export interface FloReducerItemType extends setFloCommentsType, setFloStateType {
	commentsPopup: CommentsPopupType;
	errored: boolean;
	inFullScreen: boolean;
	supportedInIphone: boolean;
	audioUrl: string;
	hotspotTimes: number[];
	floPrimaryTrack: mergedType[];
	subtitlesList: Subtitle[];
	swiftPlayImages: SwiftPlayImageType[];
	hotspotGroups: {
		[key: string]: elementsDataType[];
	};
	qualitySelectors: {
		[key: string]: Array<{
			url: string;
			label: string;
		}>;
	};
	appCommentPosition?: {
		show?: boolean;
		time?: number;
		canvasRenderConfig?: CanvasRenderConfig;
	};
	videoDimensions: {
		width: number;
		height: number;
	};
	amplitudeData: Array<{ [key: string]: number }>;
	errorCode: number;
	errorMessage: string;
	errorType?: string;
	editingCommentId: string;
	annotationTool?: string;
	annotations: AnnotationSnapshot[];
	floDetails: FloDetailsType;
	annotationsMeta: AnnotationMetaSnapshot[];
	scrollTo?: scrollToType;
	postProcessConfigs: PostProcessConfigType;
	guide: {
		steps: GuideDataType[];
		elements: {
			[key: string]: publishElementsDataType;
		};
		stepsFloElements: { [key: string]: { [key: string]: elementBeanListType[] } };
	};
	allowComment: boolean;
	ctaAndHotspotTimes: number[];
	ctaAndHotspotTimesList: stepsElementType[];
}

export type FloReducerType = {
	floSpace: FloSpaceConfigOutput;
	flos: { [key: string]: FloReducerItemType };
};

const initialState = Object.freeze({
	floSpace: {},
	flos: {},
});

const defaultFloState = Object.freeze({
	commentsPopup: {
		visible: false,
		annotationsMeta: [],
		id: '',
		top: 0,
		left: 0,
		question: '',
		name: '',
		email: '',
		className: '',
	},
	qualitySelectors: {},
	floPrimaryTrack: [],
	subtitlesList: [],
	swiftPlayImages: [],
	videoDimensions: {
		height: 0,
		width: 0,
	},
	amplitudeData: [],
	audioUrl: '',
	annotationTool: 'none',
	annotations: [],
	errorCode: 0,
	editingCommentId: '',
	supportedInIphone: false,
	errored: false,
	errorMessage: '',
	annotationsMeta: [],
	tracks: [],
	commentReplies: {},
	comments: {
		data: [],
		paginationInfo: {},
		loading: true,
	},
	floDetails: {},
	scrollTo: {
		commentId: '',
		highlightCommentId: '',
		highlightAnnotationByCommentId: '',
		behavior: 'smooth',
		replyId: '',
	},
	isSSEConnected: false,
	sseEventSource: null,
	postProcessConfigs: {
		crop: {
			enable: false,
			values: null,
			videoSize: {
				width: 0,
				height: 0,
			},
		},
		trim: {
			enable: false,
			values: ['0', '100'],
			timeValue: [],
			rangeValues: [0, 100],
			originalLength: 0,
			hasNotPlayed: true,
			maxProgressed: 0,
			currentTime: 0,
			currentTimeSeconds: 0,
		},
	},
	guide: {
		steps: [],
	},
	editorsElements: {},
	editorsElementsConfig: [],
	currentMetadata: {},
	allowComment: false,
});

const scrollToDefault: scrollToType = {
	commentId: '',
	highlightAnnotationByCommentId: '',
	highlightCommentId: '',
	behavior: 'smooth',
	replyId: '',
};

const floReducers = {
	setScrollTo(
		state: FloReducerType,
		{
			payload,
		}: { payload: { scrollInfo: scrollToType; floId: string; floIndex: number } }
	) {
		state.flos[payload.floIndex].scrollTo = payload.scrollInfo;
		if (!payload.scrollInfo.highlightCommentId) {
			state.flos[payload.floIndex].annotations = [];
		}
	},
	getFloDetails(
		state: FloReducerType,
		{ payload }: { payload: { floId: string; floIndex: number } }
	) {
		//@ts-ignore
		state.flos[payload.floIndex] = cloneDeep(defaultFloState);
	},
	setFloDetails(
		state: FloReducerType,
		{ payload }: { payload: FloDetailsType & { floIndex: number } }
	) {
		state.flos[payload.floIndex].floDetails = payload;
	},
	getFloSpaceData(
		state: FloReducerType,
		{
			payload,
		}: {
			payload: {
				floSpaceId: string;
				customToken?: string;
				floId: string;
			};
		}
	) {},
	setFloSpaceData(state: FloReducerType, { payload }: { payload: FloDetailsType }) {
		return {
			...state,
			floSpace: payload,
		};
	},
	setUpdatedFloDetails(state: FloReducerType, { payload }: { payload: FloDetailsType }) {
		state.floDetails = payload || {};
		state.floDetails['auto-play'] = get(state, ['floDetails', 'auto-play']);
		// && get(state, 'floDetails.outputType') === 'interactive demo'
		state.postProcessConfigs.trim.hasNotPlayed = get(payload, ['auto-play']) || false;
	},
	setSSEFloDetails(state: FloReducerType, { payload }: { payload: FloDetailsType }) {
		state.floDetails = payload;
	},
	updatedFloName(
		state: FloReducerType,
		{ payload }: { payload: { id: string; name: string } }
	) {
		if (payload.id === state.floDetails?.id) {
			state.floDetails.name = payload.name;
		}
	},
	setFloErrored(
		state: FloReducerType,
		{ payload }: { payload: { error: AxiosError; floId: string; floIndex: number } }
	) {
		const message =
			get(payload, 'error.response.data.data') || get(payload, 'error.message');
		const messageStatus = get(payload, 'error.response.status', 0);
		state.flos[payload.floIndex] = {
			...(state.flos[payload.floIndex] || {}),
			errorCode: messageStatus,
			errorType: get(payload, 'error.errorType', ''),
			errorMessage: message || 'Oops! Something went wrong',
			errored: true,
		};
	},
	setFloTracks(
		state: FloReducerType,
		{ payload }: { payload: { tracks: floTrackType[]; floId: string; floIndex: number } }
	) {
		state.flos[payload.floIndex] = {
			...(state.flos[payload.floIndex] || {}),
			tracks: payload.tracks,
			duration: Number(max(payload.tracks.map((i) => i.duration || 0)) || 0),
		};
	},
	getFloComments(state: any, { payload }: any) {
		state.flos[payload.floIndex].comments.loading = true;
	},
	setFloComments(state: FloReducerType, { payload }: { payload: any }) {
		const { data } = payload || {};
		const comment = data[data.length - 1];
		if (payload.type === 'add' && state.flos[payload.floIndex].comments.data) {
			const lastAnnotationId = get(state, 'comments.data[0].annotationId', '');
			const annotationId = get(comment, 'annotationId');
			state.flos[payload.floIndex].scrollTo = {
				...scrollToDefault,
				commentId: comment.id,
				behavior: 'auto',
			};
			if (lastAnnotationId === annotationId) {
				data.splice(data.length - 1, 1);
			}
			state.flos[payload.floIndex].comments.data = concat(
				data,
				state.flos[payload.floIndex].comments.data
			);
			state.flos[payload.floIndex].comments.paginationInfo = payload.paginationInfo;
		} else {
			state.flos[payload.floIndex].comments = payload;
			state.flos[payload.floIndex].scrollTo = {
				...scrollToDefault,
				commentId: comment?.id,
				behavior: 'auto',
			};
		}
		state.flos[payload.floIndex].comments.loading = false;
	},
	addComments(state: any, { payload }: { payload: any }) {},
	setFloPrimaryTrack(
		state: any,
		{ payload }: { payload: { data: any; floId: string; floIndex: number } }
	) {
		state.flos[payload.floIndex].floPrimaryTrack = payload.data;
	},
	setAddComments(
		state: any,
		{ payload }: { payload: { data: any; floId: string; floIndex: number } }
	) {
		const annotations = state.flos[payload.floIndex].comments.data;
		const lastAnnotationId =
			annotations.length && annotations[annotations.length - 1].annotationId;
		const newAnnotationId = get(payload, 'data.annotationId');
		if (lastAnnotationId === newAnnotationId) return;
		state.flos[payload.floIndex].comments.data.push(payload.data);

		if (payload.data.canSkipScrollTo) return;
		state.flos[payload.floIndex].scrollTo = {
			...scrollToDefault,
			...(payload.data.addHighlight ? { highlightCommentId: payload.data.id } : {}),
			commentId: payload.data.id,
		};
	},
	getFloReplies(state: any, { payload }: any) {
		state.flos[payload.floIndex].commentReplies[payload.commentId] =
			state.commentReplies[payload.commentId] || {};
		const reply = state.commentReplies[payload.commentId];
		state.scrollTo = { ...scrollToDefault };
		if (reply) reply.loading = true;
	},
	setFloReplies(state: any, { payload }: { payload: any }) {
		const { commentId, data, paginationInfo } = payload || {};
		const newLastReply = data[data.length - 1];

		if (payload.type === 'add') {
			const lastAnnotationId = get(state, 'commentReplies.data[0].annotationId', '');
			const annotationId = get(newLastReply, 'annotationId');
			if (lastAnnotationId === annotationId) {
				data.splice(data.length - 1, 1);
			}

			const currentReplies = get(
				state,
				`state.flos[${payload.floIndex}].commentReplies[${commentId}].data`
			);
			state.flos[payload.floIndex].commentReplies[commentId].data = concat(
				data,
				currentReplies
			);
			state.flos[payload.floIndex].commentReplies[commentId].paginationInfo =
				paginationInfo;
			state.flos[payload.floIndex].scrollTo = {
				...scrollToDefault,
				replyId: newLastReply.id,
				commentId,
				behavior: 'auto',
			};
		} else {
			state.flos[payload.floIndex].commentReplies[commentId] = payload;
			state.flos[payload.floIndex].scrollTo = { ...scrollToDefault, commentId };
		}
		state.flos[payload.floIndex].commentReplies[commentId].loading = false;
	},
	addFloReply(state: any, { payload }: { payload: any }) {},
	setAddFloReply(
		state: any,
		{ payload }: { payload: { data: any; floId: string; floIndex: number } }
	) {
		const parentId = get(payload, 'data.parentCommentId', '');

		if (parentId) {
			const comment = find(state.flos[payload.floIndex].comments.data, { id: parentId });
			// state.commentReplies[parentId].data.push(payload);
			const currentReplies =
				get(state.flos[payload.floIndex].commentReplies[parentId], 'data') || [];

			if (!isEmpty(currentReplies)) {
				const lastReplyId = currentReplies[currentReplies.length - 1].id;
				const canPush = lastReplyId !== payload.data.id;
				canPush && currentReplies.push(payload.data);
			} else {
				state.flos[payload.floIndex].commentReplies[parentId] = { data: [payload.data] };
			}
			state.flos[payload.floIndex].scrollTo = {
				...scrollToDefault,
				replyId: '',
				commentId: parentId,
				behavior: 'smooth',
			};
		}
	},
	setAnnotationTool(
		state: FloReducerType,
		{
			payload,
		}: {
			payload: { data: string; floId: string; floIndex: number };
		}
	) {
		state.flos[payload.floIndex].annotationTool = payload.data;
	},
	setAmplitudeData(
		state: FloReducerType,
		{ payload }: { payload: { data: any; floId: string; floIndex: number } }
	) {
		state.flos[payload.floIndex].amplitudeData = payload.data;
	},
	setQualitySelectors(
		state: FloReducerType,
		{
			payload,
		}: {
			payload: {
				floIndex: number;
				key: string;
				audioUrl: string;
				values: Array<{
					url: string;
					audioUrl: string;
					label: string;
				}>;
			};
		}
	) {
		if (payload.audioUrl) state.flos[payload.floIndex].audioUrl = payload.audioUrl;
		state.flos[payload.floIndex].qualitySelectors[payload.key] = payload.values;
	},
	setIsFloSupportedInIphone(
		state: FloReducerType,
		{
			payload,
		}: {
			payload: { data: boolean; floId: string; floIndex: number };
		}
	) {
		state.flos[payload.floIndex].supportedInIphone = payload.data;
	},
	addAnnotations(
		state: FloReducerType,
		{
			payload,
		}: {
			payload: {
				data: AnnotationSnapshot;
				floId: string;
				floIndex: number;
				floSpaceId: string;
				trackId: string;
			};
		}
	) {
		state.flos[payload.floIndex].annotations.push(payload.data);
	},
	clearAnnotations(
		state: FloReducerType,
		{
			payload,
		}: {
			payload: {
				data: AnnotationSnapshot;
				floId: string;
				floIndex: number;
				floSpaceId: string;
				trackId: string;
			};
		}
	) {
		state.flos[payload.floIndex].annotations = [];
	},
	getAnnotationsMeta(state: FloReducerType, { payload }: { payload?: null }) {},
	setAnnotationsMeta(
		state: FloReducerType,
		{
			payload,
		}: { payload: { data: AnnotationSnapshot[]; floId: string; floIndex: number } }
	) {
		state.flos[payload.floIndex].annotationsMeta = sortBy(payload.data, 'time');
	},
	getAnnotationsByTime(state: FloReducerType, { payload }: { payload?: null }) {},
	setAnnotationsByTime(
		state: FloReducerType,
		{ payload }: { payload: setAnnotationsByTimeType }
	) {
		const { data, commentId } = payload;
		if (data) {
			let annotations = sortBy(data, 'time');
			let annotationItem: AnnotationSnapshot = data[0];
			if (commentId) {
				//@ts-ignore
				annotationItem = find(data, { commentId }) || {};
				annotations = [annotationItem];
			}
			state.flos[payload.floIndex].annotations = annotations;
			state.flos[payload.floIndex].scrollTo = {
				...scrollToDefault,
				commentId: get(annotationItem, 'commentId') || commentId || '',
				highlightCommentId: get(annotationItem, 'commentId') || commentId || '',
			};
		} else {
			state.flos[payload.floIndex].annotations = [];
			//  state.scrollTo ={ ...scrollToDefault };
		}
	},
	updateAnnotation(
		state: FloReducerType,
		{
			payload,
		}: {
			payload: {
				data?: AnnotationSnapshot;
				uiId?: string;
				content?: string;
				commentId?: string;
				createdBy?: createdByType;
				id?: string;
				floId: string;
				floIndex: number;
			};
		}
	) {
		if (!payload?.data) {
			state.flos[payload.floIndex].annotations = state.flos[
				payload.floIndex
			].annotations.slice(0, -1);
		} else {
			if (state.flos[payload.floIndex].annotations.length - 1 >= 0) {
				const index = state.flos[payload.floIndex].annotations.findIndex(
					(annot) => annot.uid == payload.uiId
				);
				const newData = set(
					{ ...payload.data },
					'state[0].comment.content',
					payload.content
				) as AnnotationSnapshot;
				newData.id = payload.id;
				// @ts-ignore
				newData.createdBy = payload.createdBy;
				// @ts-ignore
				state.flos[payload.floIndex].annotations[index] = newData;
				state.flos[payload.floIndex].scrollTo = {
					...scrollToDefault,
					commentId: newData.commentId || '',
					highlightCommentId: newData.commentId || '',
				};
			}
		}
	},
	sendAnnotation(state: FloReducerType, { payload }: { payload: AnnotationSnapshot }) {},
	removeAnnotations(
		state: FloReducerType,
		{ payload }: { payload: { floId: string; floIndex: number } }
	) {
		state.flos[payload.floIndex].annotations = reject(
			state.flos[payload.floIndex].annotations,
			['id', payload]
		);
	},
	getFloTracks(
		state: FloReducerType,
		{
			payload,
		}: {
			payload: {
				floId: string;
				floIndex: number;
				floSpaceId: string;
			};
		}
	) {},

	resolveComment(state: any, { payload }: { payload: any }) {},
	setResolveComment(state: any, { payload }: { payload: any }) {
		const { annotationId, isResolved, isResolvedBy } = payload;
		const comment = find(state.flos[payload.floIndex].comments.data, { annotationId });

		if (comment) {
			comment.isResolved = isResolved;
			comment.isResolvedBy = isResolvedBy;
		}
	},
	decisionComment(state: any, { payload }: { payload: any }) {},
	setDecisionComment(state: any, { payload }: { payload: any }) {
		const { annotationId, isDecision, isDecisionBy, parent, commentId } = payload;

		if (parent) {
			const repliesList = state.flos[payload.floIndex].commentReplies[parent];
			if (repliesList) {
				const reply = find(repliesList.data, { id: commentId });
				reply.isDecision = isDecision;
				reply.isDecisionBy = isDecisionBy;
			}
			return;
		}

		const comment = find(state.flos[payload.floIndex].comments.data, { annotationId });
		if (comment) {
			comment.isDecision = isDecision;
			comment.isDecisionBy = isDecisionBy;
		}
	},
	getCommentsCount(state: any, { payload }: { payload: any }) {},

	leaveFloPage() {
		return initialState;
	},
	updateSkipConsent(
		state: FloReducerType,
		{ payload }: { payload: { floId: string; floIndex: number } }
	) {
		if (state.flos[payload.floIndex].floDetails?.floUserConsent) {
			// @ts-ignore
			state.flos[payload.floIndex].floDetails.floUserConsent.profileSkipped = true;
		}
	},
	setSettings(
		state: FloReducerType,
		{ payload }: { payload: { data: setSettingsType; floId: string; floIndex: number } }
	) {
		const { shareLinkEnabled } = payload.data;
		const { floDetails } = state.flos[payload.floIndex];
		if (state.flos[payload.floIndex].floDetails?.floId !== payload.id) return;
		if (floDetails && !isEmpty(payload)) {
			floDetails.shareLinkEnabled = shareLinkEnabled;
		} else {
			const newFloDetails = {
				shareLinkEnabled: false,
			};
			// @ts-ignore
			state.flos[payload.floIndex].floDetails = newFloDetails;
		}
	},
	deleteComment(state: setFloCommentsType) {},
	setDeleteComment(
		state: FloReducerType,
		{
			payload,
		}: { payload: { data: deleteCommentPayloadType; floId: string; floIndex: number } }
	) {
		const { commentId } = payload.data || {};
		const commentsData = state.flos[payload.floIndex].comments.data;
		const index = findIndex(commentsData, { id: commentId });

		if (index > -1) {
			commentsData.splice(index, 1);
			state.flos[payload.floIndex].floDetails.commentsCount = payload.count;
		}
		if (payload.data.annotationId)
			state.flos[payload.floIndex].annotations = reject(
				state.flos[payload.floIndex].annotations,
				['id', payload.data.annotationId]
			);
	},
	deleteReply(state: setFloCommentsType) {},
	setDeleteReply(
		state: FloReducerType,
		{
			payload,
		}: { payload: { data: deleteCommentPayloadType; floId: string; floIndex: number } }
	) {
		const { commentId, parentId, replyCount, count } = payload.data || {};
		if (parentId) {
			const replyData = get(
				state,
				`flos[${payload.floIndex}].commentReplies[${parentId}].data`
			);
			const comment = find(state.flos[payload.floIndex].comments.data, { id: parentId });

			const index = findIndex(replyData, { id: commentId });
			if (replyData && index > -1) {
				replyData.splice(index, 1);
				state.flos[payload.floIndex].floDetails['commentsCount'] = count;
				if (comment) comment.count = replyCount || 0;
			}
		}
	},
	stageEditComment(
		state: FloReducerType,
		{ payload }: { payload: { id: string; floId: string; floIndex: number } }
	) {
		state.flos[payload.floIndex].editingCommentId = payload.id;
		state.flos[payload.floIndex].scrollTo = {
			behavior: 'smooth',
			commentId: '',
			highlightAnnotationByCommentId: '',
			highlightCommentId: '',
			replyId: '',
		};
		state.flos[payload.floIndex].scrollTo.highlightCommentId = payload.id;
	},
	editComment(state: FloReducerType) {},
	setEditComment(
		state: FloReducerType,
		{
			payload,
		}: {
			payload: {
				floId: string;
				floIndex: number;
				annotationId: string;
				content: string;
				commentId: string;
				time: number;
			};
		}
	) {
		const { annotationId, content } = payload;
		const comment = find(state.flos[payload.floIndex].comments.data, { annotationId });
		if (comment) comment.content = content;
	},
	editReply(state: FloReducerType) {},
	setEditReply(
		state: FloReducerType,
		{
			payload,
		}: {
			payload: {
				floId: string;
				floIndex: number;
				annotationId: string;
				commentId: string;
				content: string;
				parent: string;
			};
		}
	) {
		const { parent, commentId, content } = payload;
		const repliesList = get(state, `flos[${payload.floIndex}].commentReplies[${parent}]`);
		if (repliesList) {
			const reply = find(repliesList.data, { id: commentId });
			if (reply) reply.content = content;
		}
	},
	onSSEMessage(state: setFloCommentsType, { payload }: { payload: SSEDataType }) {},
	setCommentCount(
		state: FloReducerType,
		{ payload }: { payload: { data: number; floId: string; floIndex: number } }
	) {
		state.flos[payload.floIndex].floDetails['commentsCount'] = payload.data;
	},
	setAutoPlay(
		state: FloReducerType,
		{ payload }: { payload: { data: boolean; floId: string; floIndex: number } }
	) {
		state.flos[payload.floIndex].floDetails['auto-play'] = payload.data ?? false;
	},
	setReplyCount(
		state: FloReducerType,
		{
			payload,
		}: {
			payload: {
				parentCommentId: string;
				count: string;
				floId: string;
				floIndex: number;
			};
		}
	) {
		const { parentCommentId, count } = payload;
		const comment = find(state.flos[payload.floIndex].comments.data, {
			id: parentCommentId,
		});
		if (comment) comment.count = parseInt(count);
		else {
			//@ts-ignore
			state.flos[payload.floIndex].comments.data.push({
				id: parentCommentId,
				count: 1,
			});
		}
	},
	initiateSSE(state: setFloCommentsType) {},
	setSseEventSource(
		state: FloReducerType,
		{ payload }: { payload: { data: EventSource; floId: string; floIndex: number } }
	) {
		state.flos[payload.floIndex].sseEventSource = payload.data;
	},
	setTrimData(
		state: FloReducerType,
		{ payload }: { payload: { data: TrimStateType; floId: string; floIndex: number } }
	) {
		state.flos[payload.floIndex].postProcessConfigs.trim = payload.data;
	},
	setTrimCurrentTime(
		state: FloReducerType,
		{
			payload,
		}: {
			payload: {
				currentTime: number;
				currentTimeSeconds: number;
				floId: string;
				floIndex: number;
			};
		}
	) {
		// state.postProcessConfigs.trim.hasNotPlayed = payload.currentTimeSeconds === 0 && (
		// 	get(state, 'postProcessConfigs.trim.currentTime', 0) > 0 ||
		// 	get(state, 'postProcessConfigs.trim.currentTime', 0) === 0
		// );
		state.flos[payload.floIndex].postProcessConfigs.trim.currentTime =
			payload.currentTime;
		state.flos[payload.floIndex].postProcessConfigs.trim.maxProgressed = max([
			state.flos[payload.floIndex].postProcessConfigs.trim.maxProgressed,
			payload.currentTimeSeconds,
		]);
		state.flos[payload.floIndex].postProcessConfigs.trim.currentTimeSeconds =
			payload.currentTimeSeconds;
	},
	setTrimFirstPlay(
		state: FloReducerType,
		{
			payload,
		}: {
			payload: { data: boolean; floId: string; floIndex: number };
		}
	) {
		if (payload) {
			state.flos[payload.floIndex].postProcessConfigs.trim.currentTime = 0;
			state.flos[payload.floIndex].postProcessConfigs.trim.currentTimeSeconds = 0;
		}
		state.flos[payload.floIndex].postProcessConfigs.trim.hasNotPlayed = payload.data;
	},
	setCropData(
		state: FloReducerType,
		{ payload }: { payload: { data: CropStateType; floId: string; floIndex: number } }
	) {
		state.flos[payload.floIndex].postProcessConfigs.crop = payload.data;
	},
	getElements(state: setFloCommentsType) {},
	setElements(
		state: FloReducerType,
		{
			payload,
		}: {
			payload: {
				data: {
					[key: string]: publishElementsDataType[];
				};
				floId: string;
				floIndex: number;
			};
		}
	) {
		state.flos[payload.floIndex].editorsElements = payload.data;
	},
	setHotspotsTimes(
		state: FloReducerType,
		{
			payload,
		}: {
			payload: { data: number[]; floId: string; floIndex: number };
		}
	) {
		state.flos[payload.floIndex].hotspotTimes = payload.data;
	},
	setCtaAndHotspotTimes(
		state: FloReducerType,
		{
			payload,
		}: {
			payload: { data: stepsElementType[]; floId: string; floIndex: number };
		}
	) {
		state.flos[payload.floIndex].ctaAndHotspotTimesList = payload.data;
		state.flos[payload.floIndex].ctaAndHotspotTimes = map(
			payload.data,
			(item: { time: number }) => item.time
		);
	},
	setHotspotsGrouped(
		state: FloReducerType,
		{
			payload,
		}: {
			payload: {
				data: {
					[key: string]: elementsDataType[];
				};
				floId: string;
				floIndex: number;
			};
		}
	) {
		state.flos[payload.floIndex].hotspotGroups = payload.data;
	},
	getElementsConfig(state: setFloCommentsType) {},
	setElementsConfig(
		state: FloReducerType,
		{
			payload,
		}: { payload: { data: elementsDataType[]; floId: string; floIndex: number } }
	) {
		state.flos[payload.floIndex].editorsElementsConfig = payload.data;
	},
	changePublishConfiguration(state: setFloCommentsType) {},
	setFloOutputMetadata(
		state: FloReducerType,
		{
			payload,
		}: { payload: { data: floOutputMetadataType[]; floId: string; floIndex: number } }
	) {
		state.flos[payload.floIndex].floDetails.floOutputMetadata = payload.data;
	},
	onPublishFlo(state: FloReducerType) {},
	setFloCurrentMetadata(
		state: FloReducerType,
		{
			payload,
		}: { payload: { data: floOutputMetadataType; floId: string; floIndex: number } }
	) {
		state.flos[payload.floIndex].currentMetadata = payload.data;
	},
	editTrim(state: FloReducerType) {},
	editCtaElement(state: FloReducerType) {},
	tracKCta(state: FloReducerType, { payload }: { payload: { id: string } }) {},
	trackCompletionAction(
		state: FloReducerType,
		{ payload }: { payload: { partial: boolean } }
	) {},
	trackViewCountAction(
		state: FloReducerType,
		{ payload }: { payload: { id: string; restart: boolean } }
	) {},
	trackHotspotAction(state: FloReducerType, { payload }: { payload: { id: string } }) {},
	trackClickAction(
		state: FloReducerType,
		{ payload }: { payload: { body: { [key: string]: any } } }
	) {},
	skipCta(
		state: FloReducerType,
		{
			payload,
		}: { payload: { id: string; enable?: boolean; floId: string; floIndex: number } }
	) {
		state.flos[payload.floIndex].ctaAndHotspotTimesList = map(
			state.flos[payload.floIndex].ctaAndHotspotTimesList,
			(elem) => {
				if (elem.id === payload.id) {
					return {
						...elem,
						disable: !payload.enable,
					};
				}
				return elem;
			}
		);
		state.flos[payload.floIndex].editorsElements.cta = map(
			state.flos[payload.floIndex].editorsElements.cta,
			(elem: publishElementsDataType) => {
				if (elem.id === payload.id) {
					return {
						...elem,
						disable: !payload.enable,
					};
				}
				return elem;
			}
		);
		state.flos[payload.floIndex].editorsElements.info_ctastart = map(
			state.flos[payload.floIndex].editorsElements.info_ctastart,
			(elem: publishElementsDataType) => {
				if (elem.id === payload.id) {
					return {
						...elem,
						disable: !payload.enable,
					};
				}
				return elem;
			}
		);
		state.flos[payload.floIndex].editorsElements.info_ctaend = map(
			state.flos[payload.floIndex].editorsElements.info_ctaend,
			(elem: publishElementsDataType) => {
				if (elem.id === payload.id) {
					return {
						...elem,
						disable: !payload.enable,
					};
				}
				return elem;
			}
		);
	},
	setFloGuide(
		state: FloReducerType,
		{
			payload,
		}: {
			payload: { data: GuideDataType[]; floId: string; floIndex: number };
		}
	) {
		state.flos[payload.floIndex].guide.steps = payload.data;
	},
	setElementsFloGuide(
		state: FloReducerType,
		{
			payload,
		}: {
			payload: {
				floElements: { [key: string]: publishElementsDataType };
				stepsFloElements: { [key: string]: { [key: string]: elementBeanListType[] } };
				floId: string;
				floIndex: number;
			};
		}
	) {
		state.flos[payload.floIndex].guide.elements = payload.floElements;
		state.flos[payload.floIndex].guide.stepsFloElements = payload.stepsFloElements;
	},
	showAddCommentsPopup(
		state: FloReducerType,
		{ payload }: { payload: { data: CommentsPopupType; floId: string; floIndex: number } }
	) {
		state.flos[payload.floIndex].commentsPopup = payload.data;
	},
	updateAddCommentsPopup(
		state: FloReducerType,
		{
			payload,
		}: { payload: { data: Partial<CommentsPopupType>; floId: string; floIndex: number } }
	) {
		state.flos[payload.floIndex].commentsPopup = {
			...state.flos[payload.floIndex].commentsPopup,
			...payload,
		};
	},
	getBrandImageAction(
		state: FloReducerType,
		{
			payload,
		}: {
			payload: {
				url: string;
				position: string;
				retry: number;
			};
		}
	) {},
	getBrandHeaderImageAction(
		state: FloReducerType,
		{
			payload,
		}: {
			payload: {
				url: string;
				retry: number;
			};
		}
	) {},
	switchFullScreen(
		state: FloReducerType,
		{
			payload,
		}: {
			payload: { data: boolean; floId: string; floIndex: number };
		}
	) {
		state.flos[payload.floIndex].inFullScreen = payload.data;
	},
	parseSubtitle(state: FloReducerType) {},
	unsetSubtitleUrl(
		state: FloReducerType,
		{
			payload,
		}: {
			payload: { floId: string; floIndex: number; data: string };
		}
	) {
		state.flos[payload.floIndex].tracks = map(
			state.flos[payload.floIndex].tracks,
			(elem: floTrackType) => {
				if (elem.subtitleUrl === payload.data) {
					return {
						...elem,
						subtitleUrl: '',
					};
				}
				return elem;
			}
		);
	},
	setSubtitleList(
		state: FloReducerType,
		{
			payload,
		}: {
			payload: { data: Subtitle[]; floId: string; floIndex: number };
		}
	) {
		state.flos[payload.floIndex].subtitlesList = payload.data;
	},
	setAllowComment(
		state: FloReducerType,
		{ payload }: { payload: { floId: string; floIndex: number } }
	) {
		state.flos[payload.floIndex].allowComment =
			!state.flos[payload.floIndex].allowComment;
	},
	setAppComment(
		state: FloReducerType,
		{
			payload,
		}: {
			payload: {
				data: {
					show: boolean;
					time?: number;
					canvasRenderConfig?: CanvasRenderConfig;
				};
				floId: string;
				floIndex: number;
			};
		}
	) {
		state.flos[payload.floIndex].appCommentPosition = payload.data;
	},
	setVideoDimension(
		state: FloReducerType,
		{
			payload,
		}: {
			payload: {
				data: {
					height: number;
					width: number;
				};
				floId: string;
				floIndex: number;
			};
		}
	) {
		state.flos[payload.floIndex].videoDimensions = payload.data;
	},
	...SwiftPlayReducer,
};

export const floPage = createSlice({
	name: 'floPage',
	initialState,
	// @ts-ignore
	reducers: floReducers,
});

export const getFloDetails = floPage.actions.getFloDetails;
export const setFloDetails = floPage.actions.setFloDetails;
export const setFloSpaceData = floPage.actions.setFloSpaceData;
export const updatedFloName = floPage.actions.updatedFloName;
export const setFloErroredAction = floPage.actions.setFloErrored;
export const getFloTracks = floPage.actions.getFloTracks;
export const leaveFloPageAction = floPage.actions.leaveFloPage;
export const setFloTracks = floPage.actions.setFloTracks;
export const setIsFloSupportedInIphone = floPage.actions.setIsFloSupportedInIphone;
export const getFloComments = floPage.actions.getFloComments;
export const setFloComments = floPage.actions.setFloComments;
export const addComments = floPage.actions.addComments;
export const setAddComments = floPage.actions.setAddComments;

export const getFloReplies = floPage.actions.getFloReplies;
export const setFloReplies = floPage.actions.setFloReplies;
export const addFloReply = floPage.actions.addFloReply;
export const updateSkipConsentAction = floPage.actions.updateSkipConsent;
export const setAddFloReply = floPage.actions.setAddFloReply;
export const setAnnotationToolAction = floPage.actions.setAnnotationTool;
export const addAnnotationsAction = floPage.actions.addAnnotations;
export const clearAnnotationsAction = floPage.actions.clearAnnotations;
export const getAnnotationsMeta = floPage.actions.getAnnotationsMeta;
export const setAnnotationsMeta = floPage.actions.setAnnotationsMeta;
export const getAnnotationsByTime = floPage.actions.getAnnotationsByTime;
export const setAnnotationsByTime = floPage.actions.setAnnotationsByTime;
export const removeAnnotationsAction = floPage.actions.removeAnnotations;
export const updateAnnotationAction = floPage.actions.updateAnnotation;
export const sendAnnotationAction = floPage.actions.sendAnnotation;
export const resolveComment = floPage.actions.resolveComment;
export const setResolveComment = floPage.actions.setResolveComment;
export const decisionComment = floPage.actions.decisionComment;
export const setDecisionComment = floPage.actions.setDecisionComment;
export const getCommentsCount = floPage.actions.getCommentsCount;
export const setScrollTo = floPage.actions.setScrollTo;
export const setSettings = floPage.actions.setSettings;
export const deleteComment = floPage.actions.deleteComment;
export const setDeleteComment = floPage.actions.setDeleteComment;
export const deleteReply = floPage.actions.deleteReply;
export const setDeleteReply = floPage.actions.setDeleteReply;
export const setAmplitudeData = floPage.actions.setAmplitudeData;
export const setQualitySelectors = floPage.actions.setQualitySelectors;
export const stageEditComment = floPage.actions.stageEditComment;
export const editComment = floPage.actions.editComment;
export const setEditComment = floPage.actions.setEditComment;
export const editReply = floPage.actions.editReply;
export const setEditReply = floPage.actions.setEditReply;
export const onSSEMessage = floPage.actions.onSSEMessage;
export const setCommentCount = floPage.actions.setCommentCount;
export const setReplyCount = floPage.actions.setReplyCount;
export const initiateSSE = floPage.actions.initiateSSE;
export const setSseEventSource = floPage.actions.setSseEventSource;
export const setSSEFloDetails = floPage.actions.setSSEFloDetails;
export const setUpdatedFloDetails = floPage.actions.setUpdatedFloDetails;
export const getElements = floPage.actions.getElements;
export const setElements = floPage.actions.setElements;
export const setHotspotsTimes = floPage.actions.setHotspotsTimes;
export const setCtaAndHotspotTimes = floPage.actions.setCtaAndHotspotTimes;
export const setHotspotsGrouped = floPage.actions.setHotspotsGrouped;
export const getElementsConfig = floPage.actions.getElementsConfig;
export const setElementsConfig = floPage.actions.setElementsConfig;
export const setTrimDataAction = floPage.actions.setTrimData;
export const setTrimCurrentTime = floPage.actions.setTrimCurrentTime;
export const setTrimFirstPlay = floPage.actions.setTrimFirstPlay;
export const setCropDataAction = floPage.actions.setCropData;
export const changePublishConfiguration = floPage.actions.changePublishConfiguration;
export const setFloOutputMetadata = floPage.actions.setFloOutputMetadata;
export const onPublishFlo = floPage.actions.onPublishFlo;
export const setFloCurrentMetadata = floPage.actions.setFloCurrentMetadata;
export const editTrim = floPage.actions.editTrim;
export const editCtaElement = floPage.actions.editCtaElement;
export const trackCtaAction = floPage.actions.tracKCta;
export const trackCompletionAction = floPage.actions.trackCompletionAction;
export const trackViewCountAction = floPage.actions.trackViewCountAction;
export const trackHotspotAction = floPage.actions.trackHotspotAction;
export const trackClickAction = floPage.actions.trackClickAction;
export const skipCta = floPage.actions.skipCta;
export const setFloGuide = floPage.actions.setFloGuide;
export const setElementsFloGuide = floPage.actions.setElementsFloGuide;
export const showAddCommentsPopup = floPage.actions.showAddCommentsPopup;
export const updateAddCommentsPopup = floPage.actions.updateAddCommentsPopup;
export const getBrandImageAction = floPage.actions.getBrandImageAction;
export const getBrandHeaderImageAction = floPage.actions.getBrandHeaderImageAction;
export const switchFullScreen = floPage.actions.switchFullScreen;
export const parseSubtitle = floPage.actions.parseSubtitle;
export const unsetSubtitleUrl = floPage.actions.unsetSubtitleUrl;
export const setSubtitleList = floPage.actions.setSubtitleList;
export const setAllowComment = floPage.actions.setAllowComment;
export const setAppComment = floPage.actions.setAppComment;
export const setVideoDimension = floPage.actions.setVideoDimension;
export const setSwiftPlayImages = floPage.actions.setSwiftPlayImages;
export const setFloPrimaryTrack = floPage.actions.setFloPrimaryTrack;
export const getFloSpaceDataAction = floPage.actions.getFloSpaceData;

export default floPage.reducer;
