import React, {
	Fragment,
	useCallback,
	useEffect,
	useState,
	Suspense,
	createContext,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get, map, replace, startsWith } from 'lodash';
import { Grid } from '@mui/material';
import Portal from '../../Components/Portal/Portal';
import { runFloSagas } from './Flo.sagas';

import {
	clearAnnotationsAction,
	getFloDetails,
	getFloSpaceDataAction,
	leaveFloPageAction,
	setAppComment,
	setFloErroredAction,
	setSettings,
} from './Flo.reducers';
import styles from './Flo.module.css';
import PrimaryHeader from '../../Components/PagePrimaryHeader/PagePrimaryHeader';
import qs from 'qs';
import classNames from 'classnames';
import { RootState } from '../../store';
import FloOutputType from './pages/FloOutputType';
import SendCommentPopup from './pages/GuideFlo/SendCommentPopup/SendCommentPopup';
import InternalArticleFooter from '../../Components/InternalArticleFooter/InternalArticleFooter';
import {
	enableTryFloikSelector,
	headerBrandImageSelector,
} from '../../Common/Common.selector';
import { APP_ENDPOINT } from '../../Common/Common.env';
import LoginDialog from '../../Components/LoginDialog/LoginDialog';
import { checkIsFloTokenValid } from '../../Containers/Routes/Routes.reducers';
import { externalAppInfoType } from '../../Common/Common.types';
import { analyticsLog } from '../../store/reducers/Log.reducer';
import Notification from '../../Components/Notification/Notification';
import { NotificationType } from '../../Components/Notification/Notification.types';
import { setNotification } from '../../Components/Notification/Toasts.reducers';
import DialogComponent from '../../Components/Dialog/Dialog';
import { ignoreSecurityCheck, isMicroApp } from '../../Common/Common.utils';

const appVersion = parseFloat(
	get(document.querySelector('meta[name="app-version"]'), 'content') || '1'
);

const queryParam = qs.parse(location.search.replace('?', ''));
const isEmbed = startsWith(location.pathname, '/embed/');
const isInApp = get(queryParam, 'inApp', 'false') === 'true';

let defaultValue = !isEmbed && appVersion > 1 ? 'false' : 'true';

export const ExternalAppInfoContext = createContext({});

// const hideHeader = get(queryParam, 'hide-header', defaultValue) === 'true';

const Flo = (props: {
	externalAppInfo?: externalAppInfoType;
	floId?: string | null;
	floIndex: number;
}) => {
	const { floId, floIndex } = props;
	const dispatch = useDispatch();
	const outputType: string = useSelector((state) =>
		get(state, `floPage.flos.${floIndex}.floDetails.outputType`, '')
	);
	const hideHeader = useSelector((state) =>
		get(state, `floPage.flos.${floIndex}.floDetails["hide-header"]`)
	);
	const isInApp =
		document.querySelector(`[flo-id="${floId}"]`)?.getAttribute('floik-app') === 'true' ||
		get(queryParam, 'inApp', 'false') === 'true';
	const internalArticles = useSelector((state: RootState) =>
		hideHeader
			? false
			: get(state, `floPage.flos.${floIndex}.floDetails.internalArticles`, false)
	) as boolean;

	console.log(
		'floId is',
		floId,
		useSelector((state: RootState) => state.floPage)
	);

	const publicResource = useSelector((state) =>
		get(state, `floPage.flos.${floIndex}.floDetails.publicResource`)
	);

	const floAuthInfo = useSelector((state: RootState) =>
		get(state, `routerContainer.floAuthInfo.${floIndex}`)
	);

	useEffect(() => {
		if (!publicResource && floId && get(floAuthInfo, `floAuthToken.${floIndex}`, '')) {
			const interval = setInterval(() => {
				dispatch(
					checkIsFloTokenValid({
						floId,
						token: get(floAuthInfo, `floAuthToken.${floIndex}`, ''),
						floIndex,
					})
				);
			}, 60000);
			return () => {
				clearInterval(interval);
			};
		}
	}, [floAuthInfo, publicResource]);

	const onMessage = useCallback((event: MessageEvent) => {
		if (event.origin !== APP_ENDPOINT) return;
		console.log(event);
		const type = get(event, 'data.type');
		const payload = get(event, 'data.payload') || {};
		switch (type) {
			case 'app-comment': {
				dispatch(
					setAppComment({
						data: {
							show: !!get(payload, 'canvasRenderConfig'),
							time: get(payload, 'time'),
							canvasRenderConfig: get(payload, 'canvasRenderConfig'),
							id: get(payload, 'id'),
						},
						floId,
						floIndex,
					})
				);
				dispatch(clearAnnotationsAction({ floId, floIndex }));
				break;
			}
			default:
				break;
		}
	}, []);

	useEffect(() => {
		let parentDomain = '';
		try {
			const domain = window.location !== window.parent.location ? document.referrer : '';
			parentDomain = replace(domain, /\/$/, '');
		} catch (e) {
			console.error(e);
		}
		if (parentDomain !== APP_ENDPOINT) return;

		window.parent.postMessage(
			{
				type: 'iframe_ready',
			},
			'*'
		);
		window.addEventListener('message', onMessage, true);
		return () => {
			window.addEventListener('message', onMessage, true);
		};
	}, []);

	const hideLogo = useSelector((state) =>
		get(state, `floPage.floSpace.branding.headerConfigurationData.hideHeaderLogo`)
	);
	const brandImage = useSelector(headerBrandImageSelector(floIndex));
	const enableTryFloik: boolean = useSelector(enableTryFloikSelector(floIndex));

	const hideHeaderBecauseOfData = (hideLogo || !brandImage) && !enableTryFloik;

	if (
		!isInApp &&
		floId &&
		!publicResource &&
		floAuthInfo &&
		!get(floAuthInfo, 'tokenValid')
	) {
		return <LoginDialog floId={floId} floIndex={floIndex} />;
	}

	return (
		<Grid
			container
			classes={{
				root: classNames(styles.gridContainer, {
					[styles.gridContainerWithWebsiteHeader]: internalArticles,
					[styles.instructions]: outputType === 'document',
				}),
			}}
		>
			{!hideHeader && !hideHeaderBecauseOfData && (
				<Grid
					item
					desktop={12}
					laptop={12}
					tablet={12}
					classes={{
						root: styles.headerGridContainer,
					}}
				>
					<PrimaryHeader
						internalArticles={internalArticles}
						floId={floId}
						floIndex={floIndex}
					/>
				</Grid>
			)}
			<Suspense>
				<FloOutputType
					hideHeader={hideHeader}
					floId={floId}
					hideHeaderBecauseOfData={hideHeaderBecauseOfData}
					floIndex={floIndex}
				/>
			</Suspense>
			{!hideHeader && internalArticles && (
				<Grid item desktop={12} laptop={12} tablet={12} className="footer-sec">
					<InternalArticleFooter />
				</Grid>
			)}
			<SendCommentPopup floId={floId} floIndex={floIndex} />
		</Grid>
	);
};

const FlosList = (props: {
	externalAppInfo?: externalAppInfoType;
	floSpaceId?: string;
}) => {
	const embeddedElements = document.querySelectorAll('floik-flo');

	const [floIdState, setFloIdState] = useState('');

	const dispatch = useDispatch();

	const floSpace = useSelector((state) => get(state, 'floPage.floSpace'));

	const microApp = isMicroApp();

	useEffect(() => {
		runFloSagas();
		return () => {
			dispatch(leaveFloPageAction(''));
		};
	}, []);

	const fetchFloDetails = useCallback((flospaceId?: string) => {
		if (embeddedElements.length > 0) {
			for (let index = 0; index < embeddedElements.length; index++) {
				embeddedElements[index].classList.add('floik-container');
				dispatch(
					getFloDetails({
						customToken: get(props, 'externalAppInfo.token'),
						isPreview: get(props, 'externalAppInfo.isPreview'),
						floId: embeddedElements[index].getAttribute('flo-id'),
						floIndex: index,
						floSpaceId: flospaceId,
					})
				);
			}
		}
	}, []);

	useEffect(() => {
		if (microApp || (ignoreSecurityCheck() && !get(props, 'floSpaceId'))) {
			fetchFloDetails();
		} else {
			dispatch(
				getFloSpaceDataAction({
					floSpaceId: get(props, 'floSpaceId'),
					customToken: get(props, 'externalAppInfo.token'),
				})
			);
		}
	}, [fetchFloDetails, microApp]);

	useEffect(() => {
		if (get(props, 'floSpaceId') && get(floSpace, 'isDomainValid') && !microApp) {
			fetchFloDetails(get(floSpace, 'flospaceId'));
		}
	}, [get(floSpace, 'flospaceId'), microApp, fetchFloDetails]);

	useEffect(() => {
		if (embeddedElements.length === 0 && !floIdState) {
			try {
				let localData;
				if (process.env.NODE_ENV === 'development') {
					localData = localStorage.getItem('floik_json');
				}
				const json = JSON.parse(
					// @ts-ignore
					localData || document.getElementById('FLOIK_JSON')?.innerText
				);
				setFloIdState(get(json, 'floId', '') || get(json, 'folderId', ''));
				if (get(json, 'floId', '') || get(json, 'folderId', '')) {
					dispatch(
						getFloDetails({
							customToken: get(props, 'externalAppInfo.token'),
							isPreview: get(props, 'externalAppInfo.isPreview'),
							floId: get(json, 'floId', ''),
							floIndex: 0,
						})
					);
				}
			} catch (error) {
				dispatch(
					analyticsLog({
						level: 'error',
						message: `getFloDetailsSaga failed ${location.href} ${JSON.stringify(
							get(error, 'response')
						)}`,
						value: get(error, 'stack'),
					})
				);
				dispatch(
					setFloErroredAction({
						error: error,
						floId: '',
						floIndex: 0,
					})
				);
				// @ts-ignore
				if (![403, 404, 406].includes(get(e, 'response.status', 0))) {
					// dispatch(setErrorToast(e));
					console.log('error', error);
				}
				dispatch(setSettings({ floId: '', data: {}, floIndex: 0 }));
			}
		}
	}, [setFloIdState, floIdState]);

	const notifications: NotificationType[] = useSelector(
		// @ts-ignore
		(state) => get(state, `toasts`) as NotificationType[]
	);

	const onCloseNotification = useCallback((floIndex: number) => {
		dispatch(
			setNotification({
				data: {
					type: '',
					message: '',
					canShow: false,
				},
				floIndex,
			})
		);
	}, []);

	if (embeddedElements.length === 0) {
		return (
			<>
				<Flo
					floId={floIdState}
					floIndex={0}
					externalAppInfo={get(props, 'externalAppInfo')}
				/>
				<Notification
					type={get(notifications, `[0].type`, '')}
					message={get(notifications, `[0].message`, '')}
					canShow={get(notifications, `[0].canShow`, false)}
					onClose={() => onCloseNotification(0)}
					floIndex={0}
				/>
				<DialogComponent floIndex={0} />
			</>
		);
	}

	return (
		<div>
			{map(embeddedElements, (containerEl: HTMLElement, index: number) => (
				<Fragment>
					<Portal target={containerEl}>
						<div className={styles.floContainer}>
							<ExternalAppInfoContext.Provider value={get(props, 'externalAppInfo', {})}>
								<Flo
									externalAppInfo={get(props, 'externalAppInfo')}
									floId={containerEl.getAttribute('flo-id')}
									floIndex={index}
								/>
							</ExternalAppInfoContext.Provider>
							<Notification
								type={get(notifications, `[${index}].type`, '')}
								message={get(notifications, `[${index}].message`, '')}
								canShow={get(notifications, `[${index}].canShow`, false)}
								onClose={() => onCloseNotification(index)}
								floIndex={index}
							/>
							<DialogComponent floIndex={index} />
						</div>
					</Portal>
				</Fragment>
			))}
		</div>
	);
};

export default FlosList;
