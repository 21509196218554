import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import styles from './LoginDialog.module.css';
import * as React from 'react';
import { useCallback, useState } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { sendOtpAction, validateOtp } from '../../Containers/Routes/Routes.reducers';
import { get } from 'lodash';
import OTPInput from '../OTPInput/OTPInput';
import { headerBrandImageSelector } from '../../Common/Common.selector';
import { IconLockExclamation } from '@tabler/icons-react';
import { getWindowDimensions } from '../../Common/Common.utils';

const LoginDialog = ({ floId, floIndex }: { floId: string; floIndex: number }) => {
	const [otpSent, setOtpSent] = useState(false);
	const [email, setEmail] = useState('');
	const [otp, setOtp] = useState('');
	const [disableResend, setDisableResend] = useState(false);
	const [isEmailValid, setIsEmailValid] = useState(false);
	const dispatch = useDispatch();
	const floDetails = useSelector((state) =>
		get(state, `floPage.flos.${floIndex}.floDetails`, '')
	);
	const floName = get(floDetails, 'heading') || get(floDetails, 'name');
	const brandImage = useSelector(headerBrandImageSelector(floIndex));

	const publishedFloId = useSelector((state) =>
		get(state, `floPage.flos.${floIndex}.floDetails.publishedFloId`, '')
	);

	const sendOtp = useCallback(
		(resend?: boolean) => {
			if (!isEmailValid) return;
			dispatch(
				sendOtpAction({
					email,
					floId,
					resend,
					cb: () => {
						setOtp('');
						setOtpSent(true);
					},
					floIndex,
				})
			);
		},
		[email, isEmailValid, floId, setOtpSent, setOtp]
	);

	const onInputChange = useCallback(
		(e: React.ChangeEvent) => {
			const inputEl = e.target as HTMLInputElement;
			const value = inputEl.value.trim();
			const emailValid = /^[\w-\.\+]+@([\w-]+\.)+[\w-]{2,}$/g.test(value);
			setIsEmailValid(emailValid);
			setEmail(value);
		},
		[setEmail, setIsEmailValid]
	);

	const onValidateOtp = useCallback(() => {
		dispatch(
			validateOtp({
				email,
				floId,
				otp,
				publishedFloId,
				floIndex,
			})
		);
		setOtp('');
	}, [email, floId, otp, publishedFloId]);

	const onButtonClick = useCallback(() => {
		if (otpSent) {
			onValidateOtp();
		} else {
			sendOtp();
		}
	}, [otpSent, email, onValidateOtp, sendOtp]);

	const onResendClick = useCallback(() => {
		sendOtp(true);
		setDisableResend(true);
		setTimeout(() => {
			setDisableResend(false);
		}, 30000);
	}, [sendOtp, setDisableResend]);
	const embeddedElements = document.querySelectorAll('floik-flo');
	const isEmbed = embeddedElements[floIndex].getAttribute('is-embed') === 'true';

	return (
		<Dialog
			open={true}
			scroll={'paper'}
			classes={{
				paper: styles.container,
				root: classNames(styles.dialogRoot, {
					[styles.isEmbed]: isEmbed,
					[styles.lessThan650]: getWindowDimensions(floIndex).width < 650,
				}),
			}}
			disablePortal={isEmbed}
			container={embeddedElements?.length ? embeddedElements[floIndex] : null}
		>
			<DialogContent className={styles.dialogContentWrapper}>
				<div className={styles.dialogTitleWrapper}>
					<img className={styles.logo} alt="logo" src={brandImage} />
					<div className={styles.dialogTitle}>
						{otpSent ? (
							'Enter OTP'
						) : (
							<span className={styles.dialogTitleWithIcon}>
								<IconLockExclamation size={32} className={styles.lockIcon} />
								{floName}
							</span>
						)}
					</div>
				</div>
				<div className={styles.dialogBody}>
					{otpSent ? (
						<div className={styles.otpInputWrapper}>
							<div className={styles.text}>
								Please enter the One Time Password(OTP) sent to you via mail
								{/*Enter the 6 digit code sent to{' '}*/}
								{/*<span className={styles.semiBold}>{email}</span>*/}
							</div>
							<OTPInput
								separator={''}
								value={otp}
								onChange={setOtp}
								length={6}
								inputType={'number'}
							/>
						</div>
					) : (
						<>
							<label className={styles.inputLabel}>Email</label>
							<input
								className={styles.input}
								onChange={onInputChange}
								placeholder="samsmith@gmail.com"
							></input>
						</>
					)}
				</div>
			</DialogContent>
			<DialogActions className={styles.dialogActions}>
				<Button
					className={classNames(styles.button, {
						[styles.disabled]: (!otpSent && !isEmailValid) || (otpSent && otp.length < 6),
					})}
					onClick={onButtonClick}
					variant="contained"
				>
					Continue
				</Button>
				{otpSent && (
					<div className={styles.footerText}>
						Didn't get the code?
						<Button
							variant="text"
							onClick={onResendClick}
							className={classNames(styles.resendButton, {
								[styles.disabled]: disableResend,
							})}
						>
							Resend
						</Button>
					</div>
				)}
			</DialogActions>
		</Dialog>
	);
};

export default LoginDialog;
