import React, { useEffect } from 'react';
import { COOKIE_PARAMS, runRoutesSagas } from './Routes.sagas';
import { useDispatch, useSelector } from 'react-redux';
import AuthenticatedRoute from '../AuthenticatedRoutes/AuthenticatedRoutes';
import {
	checkIsLoggedInAction,
	leaveRoutesAction,
	setExternalAppInfo,
} from './Routes.reducers';
import Loader from '../../Components/Loader/Loader';
import { getIsAuthenticated, getIsLoading } from './Routes.selector';
import Cookie from 'js-cookie';
import { get } from 'lodash';
import { externalAppInfoType } from '../../Common/Common.types';

const Routes = (props: {
	externalAppInfo?: externalAppInfoType;
	floSpaceId?: string;
}) => {
	const dispatch = useDispatch();
	const isAuthenticated = useSelector(getIsAuthenticated);
	const isLoading = useSelector(getIsLoading);

	useEffect(() => {
		runRoutesSagas();
		dispatch(checkIsLoggedInAction({}));
		return () => {
			dispatch(leaveRoutesAction(''));
		};
	}, []);

	useEffect(() => {
		if (props.externalAppInfo) {
			dispatch(setExternalAppInfo(props.externalAppInfo));
		}
	}, [props.externalAppInfo]);

	if (isLoading) return <Loader fullPage nodelay></Loader>;
	return (
		<AuthenticatedRoute
			externalAppInfo={get(props, 'externalAppInfo')}
			floSpaceId={get(props, 'floSpaceId')}
		/>
	);
};

export default Routes;
