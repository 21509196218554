import '../../styles.css';
import { BrowserRouter as Router } from 'react-router-dom';
import React, { Suspense } from 'react';
import Routes from '../Routes/Routes';
import { get } from 'lodash';
import { externalAppInfoType } from '../../Common/Common.types';

export default function App(
	props:
		| {
				externalAppInfo?: externalAppInfoType;
				floSpaceId?: string;
		  }
		| undefined
) {
	return (
		<div className="floik-container">
			<Router>
				<Suspense>
					<Routes
						key="routes"
						externalAppInfo={get(props, 'externalAppInfo')}
						floSpaceId={get(props, 'floSpaceId')}
					/>
				</Suspense>
			</Router>
		</div>
	);
}
