import moment from 'moment/moment';

export const formatDate = (inputDatetime: string): string => {
	const date = inputDatetime ? moment(inputDatetime) : moment();
	const currentDate = moment();
	//@ts-ignore
	const timeDiff = currentDate.diff(date);
	const timeDiffHours = moment.duration(timeDiff).valueOf();
	const isTimeMoreThan24Hours = timeDiffHours >= 1000 * 60 * 60 * 8;
	const dateTime = date.format('LT');
	const messageDate = moment(inputDatetime).local();
	const currentYear = moment().year() === messageDate.year();
	let dateString = isTimeMoreThan24Hours
		? `${moment(inputDatetime)
				.local()
				.format(`DD MMM${currentYear ? '' : ', YYYY'}`)}`
		: `${dateTime}`;
	return dateString;
};
