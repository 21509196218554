import React, { useCallback } from 'react';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	IconButton,
} from '@mui/material';
import { RootState } from '../../store';
import { get, isFunction, throttle } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import { DialogReducerType, hideDialogAction } from './Dialog.reducer';
import styles from './Dialog.module.css';
import classNames from 'classnames';

export const dialogSelector =
	(floIndex: number) =>
	(state: RootState): DialogReducerType =>
		get(state, `dialog.[${floIndex}]`, {}) as DialogReducerType;

const validProtocols = ['http', 'https', 'ftp', 'ssh', 'telnet', 'sftp', 'file', 'data'];

const pattern = new RegExp('^(' + validProtocols.join('|') + ')://');

const DialogComponent = ({ floIndex }: { floIndex: number }) => {
	const {
		visible,
		onConfirm,
		onCancel,
		header,
		body,
		confirmButtonText,
		confirmButtonType,
		cancelButtonText,
		showConfirm,
		showCancel,
		centerAlignFooter,
		headerClassName,
		classContainer,
		classDialogRoot,
		hideClosebutton,
		bodyClassName,
		classDialogConfirmButton,
		disablePortal,
		noBackdropClick,
		container,
		confirmButtonUrl,
		disableAutoFocus,
		classes,
	} = useSelector(dialogSelector(floIndex));
	const dispatch = useDispatch();
	const showAppComment = useSelector((state) =>
		get(state, 'floPage.appCommentPosition.show')
	);

	const embeddedElements = document.querySelectorAll('floik-flo');

	const handleClose = useCallback(
		(event?: unknown, reason?: 'backdropClick' | 'escapeKeyDown') => {
			if (noBackdropClick && reason === 'backdropClick') return;
			if (isFunction(onCancel)) onCancel();
			dispatch(hideDialogAction(floIndex));
		},
		[dispatch, onCancel, noBackdropClick]
	);

	const handleConfirm = useCallback(
		throttle(
			() => {
				if (isFunction(onConfirm)) onConfirm(dispatch);
				dispatch(hideDialogAction(floIndex));
			},
			1000,
			{ leading: true, trailing: false }
		),
		[dispatch, onConfirm]
	);
	let link = confirmButtonUrl;
	if (link === '#') {
		link = '';
	}
	if (link && !pattern.test(link)) {
		link = `//${link}`;
	}
	if (!visible || showAppComment) return <span />;
	return (
		<Dialog
			open={visible}
			scroll={'paper'}
			disablePortal={
				embeddedElements?.length ? embeddedElements[floIndex] : disablePortal
			}
			container={embeddedElements?.length ? embeddedElements[floIndex] : container}
			disableAutoFocus={disableAutoFocus}
			classes={{
				paper: classNames(styles.container, classContainer),
				root: classNames(styles.dialogRoot, classDialogRoot),
			}}
			onClose={handleClose}
		>
			{!hideClosebutton && (
				<IconButton
					className={styles.closeButton}
					aria-label="close"
					onClick={handleClose}
					sx={{
						position: 'absolute',
						right: 24,
						top: 24,
					}}
				>
					<CloseIcon className={classNames(styles.closeIcon, classes?.closeIcon)} />
				</IconButton>
			)}
			<div className={styles.contentWrapper}>
				{header && (
					<DialogTitle
						id="responsive-dialog-title"
						className={classNames(styles.title, headerClassName)}
					>
						{header}
					</DialogTitle>
				)}
				<DialogContent className={styles.dialogContent}>
					<DialogContentText className={classNames(styles.dialogMessage, bodyClassName)}>
						{body}
					</DialogContentText>
				</DialogContent>
				{(showCancel || showConfirm) && (
					<DialogActions
						classes={{
							root: classNames(styles.actions, {
								[styles.center]: centerAlignFooter,
							}),
						}}
					>
						{showCancel && (
							<Button
								autoFocus={!disableAutoFocus}
								disableFocusRipple
								disableElevation
								onClick={handleClose}
							>
								{cancelButtonText}
							</Button>
						)}
						{showConfirm && (
							<Button
								component={confirmButtonUrl ? 'a' : 'button'}
								href={link}
								target={'_blank'}
								disableFocusRipple
								disableElevation
								variant="contained"
								className={classNames(styles.confirmButton, classDialogConfirmButton, {
									[styles.deleteButton]: confirmButtonType === 'delete',
								})}
								onClick={handleConfirm}
								autoFocus={!disableAutoFocus}
							>
								{confirmButtonText}
							</Button>
						)}
					</DialogActions>
				)}
			</div>
		</Dialog>
	);
};

export default DialogComponent;
