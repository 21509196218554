import store from '../../store';
import sanitizeHtml from 'sanitize-html';
import { SagaIterator } from 'redux-saga';
import {
	all,
	call,
	debounce,
	ForkEffect,
	put,
	select,
	takeEvery,
	takeLatest,
} from 'redux-saga/effects';
import uniqId from 'uniqid';
// @ts-ignore
import API from '../../utils/API';
import {
	changePublishConfiguration,
	clearAnnotationsAction,
	getAnnotationsByTime,
	getAnnotationsMeta,
	getBrandHeaderImageAction,
	getBrandImageAction,
	getElements,
	getElementsConfig,
	getFloDetails,
	getFloSpaceDataAction,
	getFloTracks,
	initiateSSE,
	leaveFloPageAction,
	onSSEMessage,
	parseSubtitle,
	sendAnnotationAction,
	setAddComments,
	setAddFloReply,
	setAnnotationsByTime,
	setAnnotationsMeta,
	setCommentCount,
	setCropDataAction,
	setCtaAndHotspotTimes,
	setDecisionComment,
	setDeleteComment,
	setDeleteReply,
	setEditComment,
	setEditReply,
	setElements,
	setElementsConfig,
	setElementsFloGuide,
	setFloDetails,
	setFloErroredAction,
	setFloGuide,
	setFloOutputMetadata,
	setFloPrimaryTrack,
	setFloSpaceData,
	setFloTracks,
	setHotspotsGrouped,
	setHotspotsTimes,
	setIsFloSupportedInIphone,
	setReplyCount,
	setResolveComment,
	setSettings,
	setSseEventSource,
	setSubtitleList,
	setSwiftPlayImages,
	setTrimDataAction,
	setVideoDimension,
	trackClickAction,
	trackCompletionAction,
	trackCtaAction,
	trackHotspotAction,
	trackViewCountAction,
} from './Flo.reducers';
import { setErrorToast } from '../../Components/Notification/Toasts.reducers';
import { CancelSagas } from '../../utils/saga.utils';
import { AnnotationSnapshot } from '../../Components/Annotations/Annotations.types';
import {
	editorsConfigDataType,
	elementBeanListType,
	elementsDataType,
	floOutputMetadataType,
	floTrackType,
	publishedFloConfigurationType,
	publishElementsDataType,
	SSEDataType,
	SSEPayloadType,
	stepsElementType,
	TrimStateType,
} from './Flo.types';
import {
	cloneDeep,
	entries,
	filter,
	find,
	findIndex,
	forEach,
	get,
	groupBy,
	includes,
	isEmpty,
	isString,
	keys,
	map,
	merge,
	min,
	padStart,
	reduce,
	reject,
	set,
	sortBy,
	uniqBy,
	values,
} from 'lodash';
import {
	API_BASE,
	APP_ENDPOINT,
	APP_FLOSPACE_DATA_URL,
	APP_FLOSPACE_DATA_URL_DOC360,
	APP_FLOSPACE_DATA_URL_DOC360_WITH_BASE_PATH,
	EVENT_SOURCE_BASE,
} from '../../Common/Common.env';
import { hideLoader, showLoader } from '../../store/reducers/Loader.reducer';
import { floAnnotations, floDetailsSelector } from './Flo.selectors';
import {
	ANNOTATION_FCM_EVENT_ADD_ANNOTATION,
	ANNOTATION_FCM_EVENT_ADD_REPLY,
	ANNOTATION_FCM_EVENT_COUNT_COMMENT,
	ANNOTATION_FCM_EVENT_COUNT_REPLY,
	ANNOTATION_FCM_EVENT_DELETE_ANNOTATION,
	ANNOTATION_FCM_EVENT_DELETE_REPLY,
	ANNOTATION_FCM_EVENT_EDIT_ANNOTATION,
	ANNOTATION_FCM_EVENT_EDIT_REPLY,
	ANNOTATION_FCM_EVENT_FLO_ACTIVE,
	ANNOTATION_FCM_EVENT_FLO_CLOSED,
	ANNOTATION_FCM_EVENT_FLO_DELETED,
	ANNOTATION_FCM_EVENT_FLO_INVITE_ACCESS,
	ANNOTATION_FCM_EVENT_FLO_PRIVATE_ACCESS,
	ANNOTATION_FCM_EVENT_FLO_PUBLIC_ACCESS,
	ANNOTATION_FCM_EVENT_FLO_RENAMED,
	ANNOTATION_FCM_EVENT_FLO_UPDATED,
	ANNOTATION_FCM_EVENT_MARK_DECISION,
	ANNOTATION_FCM_EVENT_MARK_RESOLVED,
	FLOIK_OG_TAGS_LIST,
} from '../../Common/Common.constants';
import {
	getFloAttributeOrQueryParamValue,
	getFloAuthToken,
	getIsEmbed,
	getResourceHeadersForDoc360,
	getWrapperValue,
	ignoreSecurityCheck,
	isMicroApp,
	isSwiftPlay,
	parseVttFile,
	transformResourceGroupToUISourceList,
} from '../../Common/Common.utils';
import { hideDialogAction } from '../../Components/Dialog/Dialog.reducer';
import Cookie from 'js-cookie';
import {
	authSuccess,
	checkIsFloTokenValid,
	setBrandHeaderImage,
	setBrandImage,
	setFloAccessToken,
	updateExternalAppInfo,
} from '../../Containers/Routes/Routes.reducers';
import qs from 'qs';
import { analyticsLog } from '../../store/reducers/Log.reducer';
import { parseFloSpaceJson } from './flospace/flospaceParser';
import { scaleLinear } from 'd3-scale';
import { useSelector } from 'react-redux';

const getJSONResourceUrl = (index: number) => {
	const embeddedElements = document.querySelectorAll('floik-flo');
	if (embeddedElements[index]) {
		const deploymentId = embeddedElements[index].getAttribute('doc360-deployment-id');
		const basePathParam = embeddedElements[index].getAttribute('doc360-base-path') || '';
		return deploymentId
			? basePathParam
				? `${APP_FLOSPACE_DATA_URL_DOC360_WITH_BASE_PATH}${basePathParam}`
				: APP_FLOSPACE_DATA_URL_DOC360
			: APP_FLOSPACE_DATA_URL;
	}
	return APP_FLOSPACE_DATA_URL;
};

function* updateViewStatus({
	payload,
}: {
	payload: { id: string; restart?: boolean; floId: string; floIndex: number };
}): SagaIterator {
	try {
		const { id, restart = false, floId: payloadFloId, floIndex } = payload;
		const floDetails = yield select((state) =>
			get(state, `floPage.flos.${floIndex}.floDetails`)
		);
		const floId = get(floDetails, 'publishedFloId') || get(floDetails, 'floId');
		const queryString = qs.stringify({
			restart,
			token: !get(floDetails, 'publicResource')
				? getFloAuthToken(get(floDetails, 'floId'))
				: undefined,
		});

		// noAuth?: boolean,
		// 	getHeader?: boolean,
		// 	redirectOnError?: boolean,
		// 	customToken?: string,
		// 	floIndex?: number
		yield call(
			new API(
				{ headers: { 'floik-swift-play': isSwiftPlay(get(payload, 'floIndex')) } },
				false,
				false,
				false,
				'',
				get(payload, 'floIndex')
			).put,
			`${API_BASE}/v1/assistance/analytics/flo/${id || floId}/viewed${
				queryString ? '?' : ''
			}${queryString}`,
			{}
		);
	} catch (e) {
		// console.error(e);
		yield put(
			analyticsLog({
				level: 'error',
				code: get(e, 'code'),
				message: `updateViewStatus ${location.href}`,
				value: get(e, 'stack'),
			})
		);
	}
}

function* updateViewCompletedStatus({
	payload,
}: {
	payload: { partial?: boolean; floId: string; floIndex: number };
}): SagaIterator {
	try {
		const partial = get(payload, 'partial');
		const viewedPercentage = get(payload, 'viewedPercentage');
		const floDetails = yield select((state) =>
			get(state, `floPage.flos.${get(payload, 'floIndex')}.floDetails`)
		);
		const floId = get(floDetails, 'publishedFloId') || get(floDetails, 'floId');

		yield call(
			new API(
				{ headers: { 'floik-swift-play': isSwiftPlay(get(payload, 'floIndex')) } },
				false,
				false,
				false,
				'',
				get(payload, 'floIndex')
			).put,
			`${API_BASE}/v1/assistance/analytics/flo/${floId}/completed${
				!get(floDetails, 'publicResource')
					? `?token=${getFloAuthToken(get(floDetails, 'floId'))}`
					: ''
			}`,
			{
				partial,
				viewedPercentage,
			}
		);
	} catch (e) {
		// console.error(e);
		yield put(
			analyticsLog({
				level: 'error',
				code: get(e, 'code'),
				message: `updateViewCompletedStatus ${location.href}`,
				value: get(e, 'stack'),
			})
		);
	}
}

function* trackHotspot({
	payload,
}: {
	payload: { id: string; body: { [key: string]: any }; floId: string; floIndex: number };
}): SagaIterator {
	try {
		const { id, body } = payload;
		const floDetails = yield select((state) =>
			get(state, `floPage.flos.${get(payload, 'floIndex')}.floDetails`)
		);
		const floId = get(floDetails, 'publishedFloId') || get(floDetails, 'floId');
		const queryString = qs.stringify({
			elementId: id,
			token: !get(floDetails, 'publicResource')
				? getFloAuthToken(get(floDetails, 'floId'))
				: undefined,
		});
		yield call(
			new API(
				{ headers: { 'floik-swift-play': isSwiftPlay(get(payload, 'floIndex')) } },
				false,
				false,
				false,
				'',
				get(payload, 'floIndex')
			).put,
			`${API_BASE}/v1/assistance/analytics/flo/${floId}/hotspot-click?${queryString}`,
			body || {}
		);
	} catch (e) {
		// console.error(e);
		yield put(
			analyticsLog({
				level: 'error',
				code: get(e, 'code'),
				message: `trackHotspot ${location.href}`,
				value: get(e, 'stack'),
			})
		);
	}
}

function* trackClick({
	payload,
}: {
	payload: { body: { [key: string]: any }; floId: string; floIndex: number };
}): SagaIterator {
	try {
		const { body } = payload;
		const floDetails = yield select((state) =>
			get(state, `floPage.flos.${get(payload, 'floIndex')}.floDetails`)
		);
		const floId = get(floDetails, 'publishedFloId') || get(floDetails, 'floId');
		const queryString = qs.stringify({
			token: !get(floDetails, 'publicResource')
				? getFloAuthToken(get(floDetails, 'floId'))
				: undefined,
		});
		yield call(
			new API(
				{ headers: { 'floik-swift-play': isSwiftPlay(get(payload, 'floIndex')) } },
				false,
				false,
				false,
				'',
				get(payload, 'floIndex')
			).put,
			`${API_BASE}/v1/assistance/analytics/flo/${floId}/clicks${
				queryString ? `?${queryString}` : ''
			}`,
			body || {}
		);
	} catch (e) {
		// console.error(e);
		yield put(
			analyticsLog({
				level: 'error',
				code: get(e, 'code'),
				message: `trackClick ${location.href}`,
				value: get(e, 'stack'),
			})
		);
	}
}

function* trackCTA({
	payload,
}: {
	payload: {
		id: string;
		skip?: boolean;
		buttonProperties?: [];
		floId: string;
		floIndex: number;
	};
}): SagaIterator {
	try {
		const { id, skip, buttonProperties } = payload;
		const floDetails = yield select((state) =>
			get(state, `floPage.flos.${get(payload, 'floIndex')}.floDetails`)
		);
		const floId = get(floDetails, 'publishedFloId') || get(floDetails, 'floId');
		const queryString = qs.stringify({
			elementId: id,
			skip: skip || false,
			token: !get(floDetails, 'publicResource')
				? getFloAuthToken(get(floDetails, 'floId'))
				: undefined,
		});
		yield call(
			new API(
				{ headers: { 'floik-swift-play': isSwiftPlay(get(payload, 'floIndex')) } },
				false,
				false,
				false,
				'',
				get(payload, 'floIndex')
			).put,
			`${API_BASE}/v1/assistance/analytics/flo/${floId}/cta-click?${queryString}`,
			buttonProperties
		);
	} catch (e) {
		// console.error(e);
		yield put(
			analyticsLog({
				level: 'error',
				code: get(e, 'code'),
				message: `trackCTA ${location.href}`,
				value: get(e, 'stack'),
			})
		);
	}
}

export function* getBrandImage({
	payload,
}: {
	payload: {
		url: string;
		position: string;
		retry: number;
	};
}): SagaIterator {
	try {
		if (!payload.url) {
			return;
		}
		// @ts-ignore
		const imageUrl: string = yield new Promise((resolve, reject) => {
			const image = new Image();
			image.src = payload.url;
			image.onload = () => {
				resolve(payload.url);
			};
			image.onerror = () => {
				reject();
			};
		});
		yield put(
			setBrandImage({
				url: imageUrl,
				position: payload.position || 'bottom-right',
			})
		);
	} catch (e) {
		// console.error(e);
		yield put(
			analyticsLog({
				level: 'error',
				message: `getBrandImage ${location.href}`,
				value: get(e, 'stack'),
			})
		);
		if (get(payload, 'retry') < 3) {
			yield call(getBrandImage, {
				payload: {
					...payload,
					retry: get(payload, 'retry', 0) + 1,
				},
			});
		}
	}
}

export function* getBrandHeaderImage({
	payload,
}: {
	payload: {
		url: string;
		retry: number;
		floId: string;
		floIndex: number;
	};
}): SagaIterator {
	try {
		if (!payload.url) {
			return;
		}
		yield put(showLoader(`brandHeaderImage_${payload.floIndex}`));
		// @ts-ignore
		const imageUrl: string = yield new Promise((resolve, reject) => {
			const image = new Image();
			image.src = payload.url;
			image.onload = () => {
				resolve(payload.url);
			};
			image.onerror = () => {
				reject();
			};
		});
		yield put(
			setBrandHeaderImage({
				url: imageUrl,
			})
		);
	} catch (e) {
		// console.error(e);
		yield put(
			analyticsLog({
				level: 'error',
				message: `getBrandHeaderImage ${location.href}`,
				value: get(e, 'stack'),
			})
		);
		if (get(payload, 'retry') < 3) {
			yield call(getBrandHeaderImage, {
				payload: {
					...payload,
					retry: get(payload, 'retry', 0) + 1,
				},
			});
		}
	} finally {
		yield put(hideLoader(`brandHeaderImage_${payload.floIndex}`));
	}
}

function* getResourceShareInfo(
	floId: string,
	floSpaceId: string,
	floIndex: number
): SagaIterator {
	try {
		if (floSpaceId) {
			const url = `${APP_FLOSPACE_DATA_URL}/c/${floSpaceId}/fs/${floId}/f/f_sh_cfg.json`;
			const response = yield call(
				new API(undefined, true, undefined, false, '', floIndex).get,
				url
			);
			return { publicResource: get(response, 'publicResource', false) };
		}
		return {};
	} catch (e) {
		yield put(
			analyticsLog({
				level: 'error',
				message: `getResourceShareInfo ${location.href}`,
				value: get(e, 'stack'),
			})
		);
		return { publicResource: true };
	}
}

function* getFloSpaceData({
	payload: { floSpaceId, customToken, floId },
}: {
	payload: {
		floSpaceId: string;
		customToken?: string;
		floId: string;
	};
}): SagaIterator {
	try {
		if (!floSpaceId) return {};

		const isDev = APP_ENDPOINT === 'https://us.realmofdev.com';

		const embeddedElements = document.querySelectorAll('floik-flo');
		let deploymentId = '';
		let region;
		let domainUrl = APP_FLOSPACE_DATA_URL;
		if (embeddedElements[0] && !isDev) {
			region = embeddedElements[0].getAttribute('doc360-region');
			deploymentId = embeddedElements[0].getAttribute('doc360-deployment-id') || '';
			domainUrl = getJSONResourceUrl(0);
		}
		const url = `${domainUrl}/c/${floSpaceId}/fs/fls_cfg.json`;
		const response = yield call(
			new API(
				isMicroApp() && !isDev
					? {
							headers: {
								// 'x-d360-id': deploymentId.split('-')[0],
								'x-d360-id': deploymentId,
								'x-d360-region': region,
							},
					  }
					: undefined,
				!customToken,
				undefined,
				false,
				customToken
			).get,
			url
		);
		const floSpaceJson = parseFloSpaceJson(response);
		if (get(floSpaceJson, 'appConfig.customGlobalScriptEnabled')) {
			yield call(getFloSpaceScripts, floSpaceId);
		}
		if (get(floSpaceJson, 'branding.brandColor')) {
			try {
				const brandStyle = document.createElement('style');

				brandStyle.id = 'floikBrandStyle';
				brandStyle.type = 'text/css';
				brandStyle.innerHTML = `:root,
				:root [theme='dark'],
				:root [theme='light'] {
				 --local-themeColor: ${get(floSpaceJson, 'branding.brandColor')};
				 --global-themeColor: ${get(floSpaceJson, 'branding.brandColor')};
				 }`;
				document.head.appendChild(brandStyle);
			} catch (e) {
				// console.error(e);
				yield put(
					analyticsLog({
						level: 'error',
						message: `getFloDetailsSaga ${location.href}`,
						value: get(e, 'stack'),
					})
				);
			}
		}
		const waterMark = get(floSpaceJson, 'branding.watermarkConfig.imgPath');
		const waterPosition = get(floSpaceJson, 'branding.watermarkConfig.position');
		yield put(
			getBrandImageAction({
				retry: 0,
				url: waterMark || '',
				position: waterPosition,
			})
		);
		const headerImage =
			get(floSpaceJson, 'branding.headerConfigurationData.imgPath') || waterMark;
		yield put(
			getBrandHeaderImageAction({
				retry: 0,
				url: headerImage || '',
			})
		);
		if (!get(floSpaceJson, 'floSpaceId')) {
			floSpaceJson.flospaceId = floSpaceId;
		}
		floSpaceJson.isDomainValid =
			ignoreSecurityCheck() ||
			find(
				get(floSpaceJson, 'flospaceIntegrationScript.allowedDomains', []),
				(domain: string) => domain.includes(location.hostname)
			) ||
			includes(
				get(floSpaceJson, 'flospaceIntegrationScript.allowedDomains', []),
				location.hostname
			);
		yield put(setFloSpaceData(floSpaceJson));
	} catch (e) {
		yield put(
			analyticsLog({
				level: 'error',
				message: `getFloSpaceData ${location.href}`,
				value: get(e, 'stack'),
			})
		);
		yield put(
			setFloSpaceData({
				isDomainValid: false,
			})
		);
		return {};
	}
}

function* getFloSpaceScripts(floSpaceId: string): SagaIterator {
	try {
		if (!floSpaceId) return {};
		const url = `${APP_FLOSPACE_DATA_URL}/c/${floSpaceId}/fs/fls_cs.json`;
		const response = yield call(new API(undefined, true, undefined, false).get, url);
		for (let i = 0; i < get(response, 'customScript.length'); i++) {
			const scriptValue = get(response, `customScript[${i}].scriptValue`);
			if (scriptValue) {
				const doc = new DOMParser().parseFromString(scriptValue, 'text/html');
				for (let j = 0; j < doc.scripts.length; j++) {
					const script = doc.scripts[j];
					if (script) {
						const scriptTag = document.createElement('script');
						for (let k = 0; k < script.attributes.length; k++) {
							scriptTag.setAttribute(
								script.attributes[k].nodeName,
								script.attributes[k].nodeValue || ''
							);
						}
						scriptTag.innerText = script.innerText;
						document.body.append(scriptTag);
					}
				}
			}
		}
	} catch (e) {
		yield put(
			analyticsLog({
				level: 'error',
				message: `getFloSpaceScripts ${location.href}`,
				value: get(e, 'stack'),
			})
		);
	}
}

function* getFloDetailsSaga({
	payload: {
		customToken,
		isPreview,
		floId: payloadFloId,
		floIndex,
		floSpaceId: payloadFloSpaceId,
	},
}: {
	payload: {
		customToken?: string;
		isPreview?: boolean;
		floId?: string;
		floIndex: number;
		floSpaceId: string;
	};
}): SagaIterator {
	try {
		if (process.env.REACT_APP_ENABLE_HLS_CREDENTIALS !== 'true') {
			Cookie.remove('CloudFront-Key-Pair-Id');
			Cookie.remove('CloudFront-Signature');
			Cookie.remove('CloudFront-Policy');
		}
		const queryParam = reduce(
			entries(qs.parse(location.search.replace('?', ''))),
			(a, [key, val]) => ({
				...a,
				[key]: val === 'true',
			}),
			{}
		);
		const isEmbed = getIsEmbed(floIndex);

		let localData;
		if (process.env.NODE_ENV === 'development') {
			localData = localStorage.getItem('floik_json');
		}

		let parsedFloDetails = {};

		const embeddedElements = document.querySelectorAll('floik-flo');
		if (embeddedElements.length > 0 && floIndex >= 0) {
			const id = embeddedElements[floIndex].getAttribute('flo-id');
			// const url = `${APP_FLOSPACE_DATA_URL}/c/${idsList[0]}/fs/${idsList[1]}/f/f_cfg.json`;
			const fileName = isPreview ? 'f_p_cfg.json' : 'f_cfg.json';
			const region = embeddedElements[floIndex].getAttribute('doc360-region');
			const deploymentId =
				embeddedElements[floIndex].getAttribute('doc360-deployment-id') || '';
			let domainUrl = getJSONResourceUrl(floIndex);
			const url = `${domainUrl}/c/fs/pf/${id}/${fileName}`;

			parsedFloDetails = yield call(
				new API(
					isMicroApp()
						? {
								headers: {
									'x-d360-id': deploymentId,
									// 'x-d360-id': deploymentId.split('-')[0],
									'x-d360-region': region,
								},
						  }
						: undefined,
					!customToken,
					undefined,
					false,
					customToken,
					floIndex
				).get,
				url
			);
		} else {
			parsedFloDetails = JSON.parse(
				// @ts-ignore
				localData || document.getElementById('FLOIK_JSON')?.innerText
			);
		}

		yield put(showLoader(`flo_${floIndex}`));
		const floId = get(parsedFloDetails, 'floId', '');
		const floSpaceId = get(parsedFloDetails, 'flospaceId', '');
		parsedFloDetails = set(parsedFloDetails, 'id', floId);

		if (!ignoreSecurityCheck() && floSpaceId !== payloadFloSpaceId) {
			yield put(
				setFloErroredAction({
					error: {
						message:
							'Oops! Your current subscription or domain settings do not allow you to access this Flo.',
						errorType: 'flospaceError',
					},
					floId: payloadFloId,
					floIndex,
				})
			);
			return;
		}

		let resourceShareInfo = {};
		let flospaceData = {};

		if (isMicroApp()) {
			resourceShareInfo = {
				appConfig: {
					hidePoweredByFloik: true,
				},
			};
			yield put(setFloSpaceData(resourceShareInfo));
		} else if (!payloadFloSpaceId && (!embeddedElements.length || floIndex === 0)) {
			yield call(getFloSpaceData, { payload: { floSpaceId, customToken, floId } });
		}
		if (!isMicroApp()) {
			resourceShareInfo = yield call(getResourceShareInfo, floId, floSpaceId);
		}

		const publishedFloDetails = {
			...parsedFloDetails,
			...resourceShareInfo,
			flospaceId: floSpaceId,
		};

		const additionalValues = reduce(
			get(publishedFloDetails, 'floAdditionalConfigurations', []),
			(
				acc: { [key: string]: string | boolean },
				item: { key: string; value: string | boolean }
			) => {
				acc[item.key] = item.value;
				return acc;
			},
			{
				...queryParam,
				'auto-play': getFloAttributeOrQueryParamValue({
					floId: payloadFloId,
					key: 'auto-play',
					floIndex,
				}),
				'show-toc': getFloAttributeOrQueryParamValue({
					floId: payloadFloId,
					key: 'show-toc',
					floIndex,
				}),
				'show-flo-title': getFloAttributeOrQueryParamValue({
					floId: payloadFloId,
					key: 'show-flo-title',
					floIndex,
				}),
				'show-author': getFloAttributeOrQueryParamValue({
					floId: payloadFloId,
					key: 'show-author',
					floIndex,
				}),
				'is-embed': isEmbed,
				'skip-video': isSwiftPlay(floIndex),
				'hide-header':
					isEmbed ||
					isMicroApp() ||
					getFloAttributeOrQueryParamValue({
						floId: payloadFloId,
						key: 'hide-header',
						floIndex,
					}),
				'interactive-mode': publishedFloDetails.outputType === 'interactive demo',
				theme: getFloAttributeOrQueryParamValue({
					floId: payloadFloId,
					key: 'theme',
					floIndex,
				}),
				view: getFloAttributeOrQueryParamValue({
					floId: payloadFloId,
					key: 'view',
					floIndex,
				}),
				wrapper: getWrapperValue(
					getFloAttributeOrQueryParamValue({
						floId: payloadFloId,
						key: 'wrapper',
						floIndex,
					})
				),
			}
		);
		const createdBy = {
			name: get(publishedFloDetails, 'createdBy.name'),
			userProfile: {
				email: get(publishedFloDetails, 'createdBy.email'),
				name: get(publishedFloDetails, 'createdBy.name'),
				anonymous: get(publishedFloDetails, 'createdBy.anonymous'),
				userId: get(publishedFloDetails, 'createdBy.userId'),
				userName: get(publishedFloDetails, 'createdBy.name'),
				userProfile: {
					profilePicUrl: get(publishedFloDetails, 'createdBy.userProfile.profilePicUrl'),
					profilePreference: {
						avatarColor: {
							bgColor: get(
								publishedFloDetails,
								'createdBy.userProfile.profilePreference.avatarColor.bgColor'
							),
							fgColor: get(
								publishedFloDetails,
								'createdBy.userProfile.profilePreference.avatarColor.fgColor'
							),
						},
					},
				},
			},
		};
		publishedFloDetails.createdBy = createdBy;

		// const resourceDetails = get(
		// 	publishedFloDetails,
		// 	'floResources.resourceGroupDetailList[0].resources'
		// );
		const screenShareDetails = find(
			get(publishedFloDetails, 'floResources.resourceGroupDetailList'),
			['type', 'screenShare']
		);
		const videoDetails = find(
			get(publishedFloDetails, 'floResources.resourceGroupDetailList'),
			['type', 'video']
		);
		const resourceDetails = get(screenShareDetails || videoDetails, 'resources');
		const videoInfo = find(resourceDetails, ['resourceType', 'PUBLISHED_OUTPUT_VIDEO']);
		const videoResourceProperties = get(videoInfo, 'resourceProperties');
		const token = localStorage.getItem(`floik-${publishedFloDetails.floId}-token`) || '';

		const addOgTags = getFloAttributeOrQueryParamValue({
			floId: payloadFloId,
			key: 'add-og-tags',
			floIndex,
		});

		if (addOgTags) {
			forEach(FLOIK_OG_TAGS_LIST, (ogTag) => {
				const metaTag = document.createElement('meta');
				metaTag.setAttribute('property', ogTag.property);
				let content = ogTag.content;
				if (ogTag.property === 'og:image') {
					const ogImages = filter(
						resourceDetails,
						(resource) => get(resource, 'resourceType') === 'PUBLISHED_OUTPUT_PREVIEW_PNG'
					);
					const ogImage = find(ogImages, (image) =>
						get(image, 'url', '').includes('16x9')
					);
					content = get(ogImage || ogImages[0], 'url', '');
				}
				metaTag.setAttribute('content', content);
				document.head.append(metaTag);
			});
		}

		if (get(publishedFloDetails, 'publicResource', null) === false) {
			if (token) {
				yield put(
					checkIsFloTokenValid({
						floId: publishedFloDetails.floId,
						token,
						floIndex,
					})
				);
			} else {
				yield put(
					setFloAccessToken({
						data: {
							floAuthToken: '',
							tokenValid: false,
						},
						floIndex,
					})
				);
			}
		}

		yield put(
			setFloDetails({
				...additionalValues,
				...publishedFloDetails,
				floIndex,
				videoResourceProperties,
			})
		);
		if (includes(['video', 'interactive demo'], get(publishedFloDetails, 'outputType'))) {
			const voiceOverElem = find(
				get(publishedFloDetails, 'floElements'),
				(i) =>
					get(i, 'editorElementName') === 'voice-over' &&
					get(i, 'properties.voiceOverMode') === 'AI_VOICE'
			);
			yield put(
				getFloTracks({ floId, floSpaceId, hasVoiceOver: !!voiceOverElem, floIndex })
			);
		} else {
			const resource = find(get(publishedFloDetails, 'floSteps'), [
				'resourceDetail.status',
				'ready',
			]);
			yield put(
				getFloTracks({
					floId,
					floSpaceId,
					outputType: get(publishedFloDetails, 'outputType'),
					floIndex,
				})
			);
			const stepsFloElements = reduce(
				get(publishedFloDetails, 'floElements'),
				(
					acc: { [key: string]: { [key: string]: elementBeanListType[] } },
					item: elementBeanListType
				) => {
					const elementGroupName =
						item?.editorElementName === 'document-blur' ? 'blur' : item.editorElementName;
					let newItem = item;
					if (item?.editorElementName === 'image' || item?.editorElementName === 'text') {
						newItem = {
							...newItem,
							//@ts-ignore
							canvasRenderConfig: get(newItem, 'properties.canvasRenderConfig'),
						};
					}
					forEach(item.stepIds, (stepId) => {
						const newValue = get(acc, `${stepId}.${elementGroupName}`, []) || [];
						//@ts-ignore
						newValue.push(newItem);
						acc = set(acc, `${stepId}.${elementGroupName}`, newValue);
					});
					return acc;
				},
				{}
			);

			yield put(
				setElementsFloGuide({
					stepsFloElements,
					floElements: reduce(
						get(publishedFloDetails, 'floElements'),
						(acc, elem) => ({
							...acc,
							[elem.id]: elem,
						}),
						{}
					),
					floId: payloadFloId,
					floIndex,
				})
			);

			const steps = get(publishedFloDetails, 'floSteps');
			let newSteps = steps;

			yield put(
				setFloGuide({
					data: sortBy(newSteps, 'order').map((i) => {
						return {
							...i,
							title: sanitizeHtml(i.title),
						};
					}),
					floId: payloadFloId,
					floIndex,
				})
			);
		}

		if (get(publishedFloDetails, 'publicResource', null) === false) {
			//If token is valid and set in Local storage then load and call updateViewStatus
		} else {
			yield call(updateViewStatus, {
				payload: {
					id:
						get(publishedFloDetails, 'publishedFloId') ||
						get(publishedFloDetails, 'floId'),
					floId: get(publishedFloDetails, 'floId'),
					floIndex,
				},
			});
		}
	} catch (e: unknown) {
		// console.error('getFloDetailsSaga failed', get(e, 'response'));

		if (customToken) {
			yield put(
				updateExternalAppInfo({
					ignoreCustomToken: true,
				})
			);
			yield put(
				getFloDetails({
					isPreview,
					floId: payloadFloId,
					floIndex,
				})
			);
			return;
		}
		yield put(
			analyticsLog({
				level: 'error',
				message: `getFloDetailsSaga failed for flo: ${payloadFloId}, ${
					location.href
				} ${JSON.stringify(get(e, 'response'))}`,
				value: get(e, 'stack'),
			})
		);
		yield put(
			setFloErroredAction({
				error: e,
				floId: payloadFloId,
				floIndex,
			})
		);
		setTimeout(() => {
			window.doc360PreviewInstance?.onMessageFromMicroApp({
				type: 'floik_app_flo_steps_rendered',
				payload: {
					initialRenderComplete: true,
				},
			});
		}, 3000);
		// @ts-ignore
		if (![403, 404, 406].includes(get(e, 'response.status', 0))) {
			// yield put(setErrorToast({data: e, floIndex: get(payload, 'floIndex')}));
			console.log('error', e);
		}
		yield put(setSettings({ floId: payloadFloId, data: {}, floIndex }));
	} finally {
		yield put(hideLoader(`flo_${floIndex}`));
	}
}

function* parseVtt({
	payload,
}: {
	payload: { data: floTrackType[]; floId: string; floIndex: number };
}): SagaIterator {
	try {
		let customToken = yield select((state) =>
			get(state, 'routerContainer.externalAppInfo.token', '')
		);
		let customHeaders;

		if (isMicroApp() && customToken) {
			customHeaders = getResourceHeadersForDoc360(get(payload, 'floIndex'), customToken);
		}
		const requests = yield all(
			payload.data
				.filter((f) => f.subtitleUrl)
				.map((floTrackType) =>
					call(
						new API(
							isMicroApp() && customToken
								? {
										headers: customHeaders,
								  }
								: undefined,
							true,
							false,
							false,
							'',
							get(payload, 'floIndex')
						).get,
						floTrackType.subtitleUrl
					)
				)
		);
		for (let index = 0; index < requests.length; index++) {
			const response = requests[index];
			const vtt = parseVttFile(response);
			yield put(
				setSubtitleList({
					data: vtt,
					floId: get(payload, 'floId'),
					floIndex: get(payload, 'floIndex'),
				})
			);
		}
	} catch (e) {
		// @ts-ignore
	}
}

function* getFloTracksSaga({ payload }: { payload: any }): SagaIterator {
	try {
		yield put(showLoader(`flo_${get(payload, 'floIndex')}`));
		const floDetails = yield select((state) =>
			get(state, `floPage.flos.${get(payload, 'floIndex')}.floDetails`)
		);
		let cloneFloDetails = cloneDeep(floDetails);

		const resources =
			get(floDetails, `floResources.resourceGroupDetailList[0].resources`) || [];

		const hasVoiceOver = get(payload, 'hasVoiceOver');
		let floTracks = transformResourceGroupToUISourceList(
			cloneFloDetails.floResources.resourceGroupDetailList
		).map((item: floTrackType) => ({
			...item,
			uuid: uniqId(),
		}));

		const screenShareTrackIndex = findIndex(floTracks, ['type', 'screenShare']);
		const screenShareTracks = floTracks[screenShareTrackIndex];
		const voiceResources = values(get(screenShareTracks, 'elementVoiceResources', {}));
		if (get(screenShareTracks, 'backgroundMusicResource.url')) {
			const backgroundMusicElementId = get(
				screenShareTracks,
				'backgroundMusicResource.elementId'
			);
			let backgroundMusicElement = find(get(cloneFloDetails, 'floElements'), [
				'id',
				backgroundMusicElementId,
			]);
			const newVolume =
				(get(backgroundMusicElement, 'properties.volumeLevel') / 100) * 0.8;
			backgroundMusicElement = set(
				backgroundMusicElement,
				'properties.volumeLevel',
				newVolume
			);
			const newBackgroundMusic = merge(
				get(screenShareTracks, 'backgroundMusicResource'),
				backgroundMusicElement
			);
			floTracks = set(
				floTracks,
				`[${screenShareTrackIndex}].backgroundMusicResource`,
				newBackgroundMusic
			);
			yield put(showLoader(`flo_${get(payload, 'floIndex')}`));
			const audio = new Audio(get(screenShareTracks, 'backgroundMusicResource.url'));
			audio.oncanplaythrough = () => {
				store.dispatch(hideLoader(`flo_${get(payload, 'floIndex')}`));
			};
			audio.onerror = () => {
				store.dispatch(hideLoader(`flo_${get(payload, 'floIndex')}`));
			};
			voiceResources.push(get(screenShareTracks, 'backgroundMusicResource'));
		}
		if (voiceResources.length) {
			const mp3Promise = voiceResources
				.filter((item) => get(item, 'url'))
				.map((item) => {
					// @ts-ignore
					return fetch(get(item, 'url'), { mode: 'no-cors' });
				});
			// @ts-ignore
			yield Promise.all(mp3Promise);
			voiceResources.forEach((item) => {
				let preloadlocation = get(item, 'url');
				if (preloadlocation) {
					const preloadLink = document.createElement('link');
					preloadLink.href = preloadlocation;
					preloadLink.rel = 'preload';
					preloadLink.as = 'audio';
					document.head.appendChild(preloadLink);
				}
			});
			try {
				if ('serviceWorker' in navigator) {
					// @ts-ignore
					navigator?.serviceWorker?.controller?.postMessage({
						type: 'check_cache_for_mp3',
						data: map(voiceResources, (item) => get(item, 'url')),
					});
				}
			} catch (e) {
				console.error(e);
			}
		}

		if (hasVoiceOver && get(floTracks, 'length', 0) > 1) {
			floTracks = reject(floTracks, ['type', 'video']);
		}
		yield put(
			setFloTracks({
				tracks: floTracks,
				floId: get(payload, 'floId'),
				floIndex: get(payload, 'floIndex'),
			})
		);
		yield put(
			parseSubtitle({
				data: floTracks,
				floId: get(payload, 'floId'),
				floIndex: get(payload, 'floIndex'),
			})
		);
		const screeShareItem = find(floTracks, ['type', 'screenShare']);
		const videoItem = find(floTracks, ['type', 'video']);

		const primaryTrack = screeShareItem || videoItem;
		yield put(
			setFloPrimaryTrack({
				data: primaryTrack,
				floId: get(payload, 'floId'),
				floIndex: get(payload, 'floIndex'),
			})
		);
		if (payload.outputType === 'document') {
			return;
		}
		const images = get(primaryTrack, 'images');
		const sortedImages = sortBy(images, (image) => get(image, 'time'));
		yield put(
			setSwiftPlayImages({
				data: sortedImages,
				floId: get(payload, 'floId'),
				floIndex: get(payload, 'floIndex'),
			})
		);
		yield put(
			setVideoDimension({
				data: {
					width: get(primaryTrack, 'width'),
					height: get(primaryTrack, 'height'),
				},
				floId: get(payload, 'floId'),
				floIndex: get(payload, 'floIndex'),
			})
		);

		let hasHlsVideoConverted = true;
		yield put(
			getElements({
				floSpaceId: get(payload, 'floSpaceId'),
				floId: get(payload, 'floId'),
				floIndex: get(payload, 'floIndex'),
			})
		);

		yield put(
			setIsFloSupportedInIphone({
				data: hasHlsVideoConverted,
				floId: get(payload, 'floId'),
				floIndex: get(payload, 'floIndex'),
			})
		);
	} catch (e: unknown) {
		// console.error('flo tracks failed', e);
		yield put(
			analyticsLog({
				level: 'error',
				message: `flo tracks failed ${location.href} ${JSON.stringify(
					get(e, 'response')
				)}`,
				value: get(e, 'stack'),
			})
		);
		// yield put(setErrorToast({data: e, floIndex: get(payload, 'floIndex')}));
	} finally {
		yield put(hideLoader(`flo_${get(payload, 'floIndex')}`));
	}
}

const transformAnnotationMetaForUi = (annotations: any) => {
	if (!isEmpty(annotations)) {
		Object.entries(annotations).forEach(([key, value]) => {
			//@ts-ignore
			value['time'] = key;
		});
		return annotations;
	} else {
		return [];
	}
};

const transformAnnotationForUi = (annotations: any) => {
	const data = map(annotations, (value) => {
		const uiRenderConfig = get(value, 'uiRenderConfig');
		if (!uiRenderConfig) return;
		uiRenderConfig.id = get(value, 'id');
		uiRenderConfig.commentId = get(value, 'commentId');
		uiRenderConfig.createdBy = get(value, 'createdBy');
		return {
			id: get(value, 'id'),
			uid: get(value, 'uiId') || get(value, 'id'),
			time: get(value, 'time'),
			commentId: get(value, 'commentId'),
			createdBy: get(value, 'createdBy'),
			state: uiRenderConfig,
		};
	});

	return data.filter((i) => !!i);
};

function* getAnnotationsMetaSaga({
	payload,
}: {
	payload: { floId: string; floSpaceId: string; floIndex: number };
}) {
	try {
		const { floId } = payload;
		// @ts-ignore
		const response: { data: AnnotationSnapshot[] } = yield call(
			new API(undefined, false, false, false, '', get(payload, 'floIndex')).get,
			`${API_BASE}/v1/annotations/flo/${floId}/meta`
		);

		const annotationsMeta = get(response, 'data.timeAnnotation');
		yield put(
			setAnnotationsMeta(
				transformAnnotationMetaForUi({
					data: annotationsMeta,
					floId,
					floIndex: get(payload, 'floIndex'),
				})
			)
		);
	} catch (e: unknown) {
		// console.error(e);
		yield put(
			analyticsLog({
				level: 'error',
				message: `getAnnotationsMetaSaga ${location.href} ${JSON.stringify(
					get(e, 'response')
				)}`,
				value: get(e, 'stack'),
			})
		);
		yield put(setErrorToast({ data: e, floIndex: get(payload, 'floIndex') }));
	}
}

function* getAnnotationsByTimeSaga({
	payload,
}: {
	payload: {
		floId: string;
		time: number;
		onNavigate: any;
		commentId: string;
		floIndex: number;
	};
}) {
	try {
		const { floId, time, commentId, floIndex } = payload;
		// @ts-ignore
		const response: { data: AnnotationSnapshot[] } = yield call(
			new API(undefined, false, false, false, '', get(payload, 'floIndex')).get,
			`${API_BASE}/v1/annotations/flo/${floId}?time=${time}&pageSize=100`
		);

		yield put(
			setAnnotationsByTime({
				data: transformAnnotationForUi(response.data),
				commentId,
				floId,
				floIndex,
			})
		);
	} catch (e: unknown) {
		// console.error(e);
		yield put(
			analyticsLog({
				level: 'error',
				message: `getAnnotationsByTimeSaga ${location.href} ${JSON.stringify(
					get(e, 'response')
				)}`,
				value: get(e, 'stack'),
			})
		);
		yield put(setErrorToast({ data: e, floIndex: get(payload, 'floIndex') }));
	}
}

function* addAnnotationSaga({
	payload,
}: {
	payload: {
		data?: AnnotationSnapshot;
		time?: number;
		stepId?: string;
		question: string;
		name?: string;
		email?: string;
		floId: string;
		floIndex: number;
	};
}): SagaIterator {
	try {
		const annotation = yield select(
			(state) =>
				get(state, `floPage.flos.${get(payload, 'floIndex')}.annotations[0].state[0]`) ||
				payload.data
		);
		const floDetails = yield select((state) =>
			get(state, `floPage.flos.${get(payload, 'floIndex')}.floDetails`)
		);
		const publishedFloId = get(floDetails, 'publishedFloId');

		const data = {
			time: payload.time ?? get(annotation, 'time', 0),
			canvasRenderConfig: {
				canvasCoordinates: {
					x: get(annotation, 'x', 0),
					y: get(annotation, 'y', 0),
					width: get(annotation, 'width', 0),
					height: get(annotation, 'height', 0),
				},
				windowCoordinates: {
					width: get(annotation, 'windowSize.width', 0),
					height: get(annotation, 'windowSize.height', 0),
					screenTop: 0,
					screenLeft: 0,
				},
				closestElementBounds: {
					x: get(annotation, 'x', 0),
					y: get(annotation, 'y', 0),
					width: get(annotation, 'width', 0),
					height: get(annotation, 'height', 0),
				},
			},
			...payload,
			content: payload.question.trim(),
		};
		const query = qs.stringify({
			stepId: payload.stepId,
			token: !get(floDetails, 'publicResource')
				? getFloAuthToken(get(floDetails, 'floId'))
				: undefined,
		});
		// @ts-ignores
		yield call(
			new API(
				{ headers: { 'floik-swift-play': isSwiftPlay(get(payload, 'floIndex')) } },
				false,
				false,
				false,
				'',
				get(payload, 'floIndex')
			).put,
			`${API_BASE}/v1/assistance/analytics/flo/${publishedFloId}/comment?${query}`,
			data
		);
	} catch (e: unknown) {
		// console.error('***flo addAnnotationSaga failed error=', e);
		yield put(
			analyticsLog({
				level: 'error',
				code: get(e, 'code'),
				message: `flo addAnnotationSaga failed error ${location.href} ${JSON.stringify(
					get(e, 'response')
				)}`,
				value: get(e, 'stack'),
			})
		);
		yield put(setErrorToast({ data: e, floIndex: get(payload, 'floIndex') }));
	} finally {
		yield put(
			clearAnnotationsAction({ floId: payload.floId, floIndex: payload.floIndex })
		);
	}
}

export function* initiateSSESaga({
	payload,
}: {
	payload: {
		userId: string;
		floId: string;
		floIndex: number;
	};
}) {
	try {
		const { userId, floId, floIndex } = payload;
		const topic = 'notification_' + userId;
		const url = `${EVENT_SOURCE_BASE}/v1/fcm-manager/sse?topic=${topic}`;
		var source = new EventSource(url);

		if (!source) return;
		yield put(setSseEventSource({ data: source, floId, floIndex }));
		source.onerror = function (e) {
			console.log('source onerror=', e);
		};
		source.onmessage = function (e) {
			const isStringData = isString(e.data);
			const data = isStringData ? JSON.parse(e.data) : e.data;

			if (isEmpty(data)) return;
			store.dispatch(
				onSSEMessage({
					...data,
					floId,
					floIndex,
				})
			);
		};
	} catch (e) {
		// console.error('****initiateSSESaga error=', e);
		yield put(
			analyticsLog({
				level: 'error',
				message: `initiateSSESaga error ${location.href} ${JSON.stringify(
					get(e, 'response')
				)}`,
				value: get(e, 'stack'),
			})
		);
	}
}

export function* onSSEMessageSaga({
	payload,
}: {
	payload: SSEDataType & { floId: string; floIndex: number };
}): SagaIterator {
	try {
		const { payload: ssePayload, eventName, floId, floIndex } = payload;
		const {
			annotationId,
			count,
			resolvedBy,
			isResolved,
			parent,
			replyId,
			isDecision,
			decidedBy,
		} = ssePayload;
		const floDetails = yield select(floDetailsSelector(get(payload, 'floIndex')));
		const { floSpaceId } = floDetails || {};
		const isCurrentFlo = ssePayload.floId === floId;
		if (isCurrentFlo) {
			if (eventName === ANNOTATION_FCM_EVENT_ADD_ANNOTATION) {
				yield call(getAnnotationByIdSaga, {
					payload: { floId, annotationId, floSpaceId, floIndex },
				});
				if (isCurrentFlo && count)
					yield put(setCommentCount({ data: count, floId, floIndex }));
			} else if (eventName === ANNOTATION_FCM_EVENT_ADD_REPLY) {
				yield call(getReplyIdSaga, { payload: { ...ssePayload, floIndex } });
			} else if (eventName === ANNOTATION_FCM_EVENT_COUNT_REPLY) {
				yield put(setReplyCount({ ...ssePayload, floIndex }));
			} else if (eventName === ANNOTATION_FCM_EVENT_DELETE_ANNOTATION) {
				yield put(
					setDeleteComment({
						data: { commentId: get(ssePayload, 'commentId') },
						floId,
						floIndex,
					})
				);
			} else if (eventName === ANNOTATION_FCM_EVENT_DELETE_REPLY) {
				yield put(
					setDeleteReply({
						data: {
							parentId: get(ssePayload, 'parentCommentId'),
							commentId: get(ssePayload, 'replyId'),
						},
						floId,
						floIndex,
					})
				);
			} else if (eventName === ANNOTATION_FCM_EVENT_MARK_DECISION) {
				yield put(
					setDecisionComment({
						annotationId,
						isDecision: !!isDecision,
						isDecisionBy: {
							name: decidedBy,
						},
						parent,
						commentId: replyId,
						floId,
						floIndex,
					})
				);
			} else if (eventName === ANNOTATION_FCM_EVENT_MARK_RESOLVED) {
				yield put(
					setResolveComment({
						annotationId,
						isResolved: !!isResolved,
						isResolvedBy: {
							name: resolvedBy,
						},
						floId,
						floIndex,
					})
				);
			} else if (eventName === ANNOTATION_FCM_EVENT_EDIT_ANNOTATION) {
				yield call(getAnnotationByIdSaga, {
					payload: {
						floId,
						annotationId,
						type: 'edit',
						floSpaceId,
						floIndex: get(payload, 'floIndex'),
					},
				});
			} else if (eventName === ANNOTATION_FCM_EVENT_EDIT_REPLY) {
				yield call(getReplyIdSaga, {
					payload: { ...ssePayload, type: 'edit', floIndex },
				});
			}
		}

		if (eventName === ANNOTATION_FCM_EVENT_COUNT_COMMENT) {
			if (isCurrentFlo && count)
				yield put(setCommentCount({ data: count, floId, floIndex }));
		} else if (
			eventName === ANNOTATION_FCM_EVENT_FLO_PUBLIC_ACCESS ||
			eventName === ANNOTATION_FCM_EVENT_FLO_PRIVATE_ACCESS ||
			eventName === ANNOTATION_FCM_EVENT_FLO_INVITE_ACCESS ||
			eventName === ANNOTATION_FCM_EVENT_FLO_RENAMED ||
			eventName === ANNOTATION_FCM_EVENT_FLO_CLOSED ||
			eventName === ANNOTATION_FCM_EVENT_FLO_ACTIVE ||
			eventName === ANNOTATION_FCM_EVENT_FLO_DELETED ||
			eventName === ANNOTATION_FCM_EVENT_FLO_UPDATED
		) {
			// yield call(getFloUpdate, {
			// 	payload: { floId: ssePayload.floId, isCurrentFlo, floSpaceId },
			// });
		}
	} catch (e) {
		// console.error('***flo onSSEMessageSaga failed=', e);
		yield put(
			analyticsLog({
				level: 'error',
				message: `flo onSSEMessageSaga failed ${location.href} ${JSON.stringify(
					get(e, 'response')
				)}`,
				value: get(e, 'stack'),
			})
		);
		yield put(setErrorToast({ data: e, floIndex: get(payload, 'floIndex') }));
	}
}

function* getAnnotationByIdSaga({
	payload,
}: {
	payload: {
		floId: string;
		annotationId: string;
		type?: string;
		floSpaceId: string;
		floIndex: number;
	};
}): SagaIterator {
	try {
		const { floId, annotationId, type, floSpaceId, floIndex } = payload;
		const isTypeEdit = type === 'edit';
		const annotations = yield select(floAnnotations(floIndex));
		const lastAnnotationId =
			annotations.length && annotations[annotations.length - 1].annotationId;

		if (!isTypeEdit && lastAnnotationId === annotationId) return;
		let url = `${API_BASE}/v1/annotations/flo/${floId}/annotation/${annotationId}`;
		const response = yield call(
			new API(undefined, false, false, false, '', get(payload, 'floIndex')).get,
			url
		);
		const { data } = response;
		if (!data) return;
		const { commentRes } = data;
		const { content } = commentRes;

		data.content = content;
		data.annotationId = data.id;
		data.id = data.commentId;
		data.canSkipScrollTo = true;
		yield put(
			isTypeEdit
				? setEditComment({ ...data, floId, floIndex })
				: setAddComments({ data, floId, floIndex })
		);
		yield call(getAnnotationsMetaSaga, { payload: { floId, floSpaceId, floIndex } });
	} catch (e) {
		// console.error('flo getAnnotationByIdSaga failed=', e);
		yield put(
			analyticsLog({
				level: 'error',
				message: `flo getAnnotationByIdSaga failed ${location.href} ${JSON.stringify(
					get(e, 'response')
				)}`,
				value: get(e, 'stack'),
			})
		);
		yield put(setErrorToast({ data: e, floIndex: get(payload, 'floIndex') }));
	}
}

function* getReplyIdSaga({
	payload,
}: {
	payload: SSEPayloadType & { floIndex: number };
}): SagaIterator {
	try {
		const { floId, annotationId, replyId, parentCommentId, type, floIndex } = payload;
		const isTypeEdit = type === 'edit';
		const response = yield call(
			new API(undefined, false, false, false, '', get(payload, 'floIndex')).get,
			`${API_BASE}/v1/annotations/flo/${floId}/annotation/${annotationId}/comment/${replyId}`
		);
		const { data } = response;

		if (!data) return;
		data.parentCommentId = parentCommentId;
		data.commentId = replyId;
		yield put(
			isTypeEdit
				? setEditReply({
						...data,
						floId,
						floIndex,
				  })
				: setAddFloReply({ data, floId, floIndex })
		);
	} catch (e) {
		// console.error('****flo getReplyIdSaga failed=', e);
		yield put(
			analyticsLog({
				level: 'error',
				message: `fflo getReplyIdSaga failed ${location.href} ${JSON.stringify(
					get(e, 'response')
				)}`,
				value: get(e, 'stack'),
			})
		);
		yield put(setErrorToast({ data: e, floIndex: get(payload, 'floIndex') }));
	}
}

const SORT_PRIORITY = {
	image: 0,
	cta: 1,
	hotspot: 2,
	hotspots: 2,
	clicks: 3,
	click: 3,
};

function* getElementsSaga({ payload }: { payload: any }): SagaIterator {
	try {
		yield put(showLoader(`flo_${get(payload, 'floIndex')}`));
		let data = yield select((state) =>
			get(state, `floPage.flos.${get(payload, 'floIndex')}.floDetails.floElements`)
		);
		data = uniqBy(data, 'id');
		const duration = yield select((state) =>
			get(state, `floPage.flos.${get(payload, 'floIndex')}.duration`, 0)
		);
		const videoResourceProperties = yield select((state) =>
			get(
				state,
				`floPage.flos.${get(payload, 'floIndex')}.floDetails.videoResourceProperties`
			)
		);

		const outputType = yield select((state) =>
			get(state, `floPage.flos.${get(payload, 'floIndex')}.floDetails.outputType`)
		);
		const floTracks = yield select((state) =>
			get(state, `floPage.flos.${get(payload, 'floIndex')}.tracks`)
		);
		const screeShareItem = find(floTracks, ['type', 'screenShare']);
		const videoItem = find(floTracks, ['type', 'video']);
		const primaryTrack = screeShareItem || videoItem;
		const floImageElements = get(primaryTrack, 'floImageElements');

		const elements: {
			[key: string]: publishElementsDataType[];
		} = {};
		yield put(
			setElementsConfig({
				data,
				floId: get(payload, 'floId'),
				floIndex: get(payload, 'floIndex'),
			})
		);

		const cropData = find(data, ['editorElementName', 'crop']);
		let isCropped = !!cropData;
		const actualCropX = get(
			cropData,
			'properties.canvasRenderConfig.canvasCoordinates.x',
			0
		);
		const actualCropY = get(
			cropData,
			'properties.canvasRenderConfig.canvasCoordinates.y',
			0
		);

		const actualCropCanvasWidth = get(
			cropData,
			'properties.canvasRenderConfig.canvasCoordinates.width',
			0
		);
		const actualCropCanvasHeight = get(
			cropData,
			'properties.canvasRenderConfig.canvasCoordinates.height',
			0
		);
		const actualCropWindowWidth = get(
			cropData,
			'properties.canvasRenderConfig.windowCoordinates.width',
			0
		);
		const actualCropWindowHeight = get(
			cropData,
			'properties.canvasRenderConfig.windowCoordinates.height',
			0
		);

		let ctaAndHotspotTimes: stepsElementType[];

		const div = document.createElement('div');
		const timeMap: {
			[key: string | number]: number;
		} = {};

		const { linkedCtaImagesMap, imagesList } = reduce(
			data,
			(
				acc: {
					linkedCtaImagesMap: { [key: string]: string };
					imagesList: elementsDataType[];
				},
				dataItem
			) => {
				if (get(dataItem, 'properties.linkedCtaId')) {
					acc.linkedCtaImagesMap[get(dataItem, 'properties.linkedCtaId')] = get(
						dataItem,
						'id'
					);
				}
				if (get(dataItem, 'editorElementName') === 'image') {
					acc.imagesList.push(dataItem);
				}
				return acc;
			},
			{ linkedCtaImagesMap: {}, imagesList: [] }
		);

		const promisesList = imagesList.slice(0, 10).map((imageItem) => {
			return new Promise((resolve, reject) => {
				const image = new Image();
				const imageId = get(imageItem, 'properties.imageId', '');
				image.src = get(floImageElements, imageItem.id) || get(floImageElements, imageId);
				image.onload = resolve;
				image.onerror = resolve;
			});
		});
		//@ts-ignore
		yield Promise.all(promisesList);

		elements.elementsWithVoiceAnnotation = [];

		data.forEach((item: elementsDataType) => {
			const { editorElementName: section } = item;
			if (!section) return;
			elements[section] = elements[section] || [];
			const time: number = min([get(item, 'properties.time', 0), duration]);
			const timeRounded: number = Math.round(time * 100) / 100;
			if (get(item, 'properties.enableVoice') && get(item, 'properties.voiceConfig')) {
				///@ts-ignore
				elements.elementsWithVoiceAnnotation.push(item);
			}
			switch (section) {
				case 'text': {
					const canvasRenderConfig = get(item, 'properties.canvasRenderConfig');
					const clickWindowWidth = get(canvasRenderConfig, 'windowCoordinates.width', 0);
					const clickWindowHeight = get(
						canvasRenderConfig,
						'windowCoordinates.height',
						0
					);

					const scaleCropToTextX = scaleLinear(
						[0, actualCropWindowWidth],
						[0, get(canvasRenderConfig, 'windowCoordinates.width', 0)]
					);
					const scaleCropToTextY = scaleLinear(
						[0, actualCropWindowHeight],
						[0, get(canvasRenderConfig, 'windowCoordinates.height', 0)]
					);
					const cropX = scaleCropToTextX(actualCropX);
					const cropY = scaleCropToTextY(actualCropY);
					const cropCanvasWidth = scaleCropToTextX(actualCropCanvasWidth);
					const cropCanvasHeight = scaleCropToTextY(actualCropCanvasHeight);

					const clickWindowScaleX = scaleLinear(
						[cropX, cropX + cropCanvasWidth],
						[0, clickWindowWidth]
					);

					const clickWindowScaleY = scaleLinear(
						[cropY, cropY + cropCanvasHeight],
						[0, clickWindowHeight]
					);

					const clickWindowScaleWidth = scaleLinear(
						[0, cropCanvasWidth],
						[0, clickWindowWidth]
					);

					const clickWindowScaleHeight = scaleLinear(
						[0, cropCanvasHeight],
						[0, clickWindowHeight]
					);

					const pointerScaleX = scaleLinear(
						[0, clickWindowWidth],
						[0, videoResourceProperties.width]
					);
					const pointerScaleY = scaleLinear(
						[0, clickWindowHeight],
						[0, videoResourceProperties.height]
					);

					const clickPositionX = pointerScaleX(
						(isCropped ? clickWindowScaleX : (i: number): number => i)(
							get(canvasRenderConfig, 'canvasCoordinates.x', 0)
						)
					);
					const clickPositionY = pointerScaleY(
						(isCropped ? clickWindowScaleY : (i: number): number => i)(
							get(canvasRenderConfig, 'canvasCoordinates.y', 0)
						)
					);
					const newClickWidth = pointerScaleX(
						(isCropped ? clickWindowScaleWidth : (i: number): number => i)(
							get(canvasRenderConfig, 'canvasCoordinates.width', 0)
						)
					);
					const newClickHeight = pointerScaleY(
						(isCropped ? clickWindowScaleHeight : (i: number): number => i)(
							get(canvasRenderConfig, 'canvasCoordinates.height', 0)
						)
					);

					const fontSize = get(item, 'properties.fontSize', 8);
					const scaledFontSize = get(item, 'properties.scaledFontSize', 8);

					// Use the average of the scaling factors to maintain proportionality
					const averageScale = (clickWindowWidth / 600 + clickWindowHeight / 600) / 2;
					// Calculate the font size
					let newFontSize = fontSize * averageScale;

					let fontCropData = {};

					if (isCropped) {
						newFontSize = scaledFontSize;
						fontCropData = {
							fontCropWidth: cropCanvasWidth,
							fontCropHeight: cropCanvasHeight,
							fontEleWidth: get(canvasRenderConfig, 'windowCoordinates.width', 0),
							fontEleHeight: get(canvasRenderConfig, 'windowCoordinates.width', 0),
						};
					}

					const width = Math.abs(newClickWidth);
					const height = Math.abs(newClickHeight);

					const newCanvasRenderConfig = {
						canvasCoordinates: {
							x: clickPositionX,
							y: clickPositionY,
							width,
							height,
							angle: get(canvasRenderConfig, 'canvasCoordinates.angle', 0),
						},
						windowCoordinates: {
							width: videoResourceProperties.width,
							height: videoResourceProperties.height,
						},
					};

					const text = get(item, 'properties.text');
					const title = get(item, 'properties.stepTitle');
					const imageId = get(item, 'properties.imageId', '');
					// @ts-ignore
					div.innerHTML = title || text || '';
					const obj = {
						id: item.id || uniqId(),
						editorElementId: item.editorElementId,
						editorElementName: item.editorElementName,
						time,
						nonRoundedTime: time,
						behaviourType: get(item, 'properties.behaviourType'),
						originalTime:
							Math.round((get(item, 'properties.originalTime') ?? time) * 100) / 100,
						order: get(item, 'properties.order'),
						subElement: get(item, 'properties.subElement'),
						subElementName: get(item, 'properties.subElementName'),
						duration: get(item, 'properties.duration'),
						fontName: get(item, 'properties.fontName'),
						fontSize: newFontSize,
						cropped: isCropped,
						fontCropData: fontCropData,
						fontColor: get(item, 'properties.fontColor'),
						animation: get(item, 'properties.animation'),
						angle: get(item, 'properties.angle'),
						text: get(item, 'properties.text'),
						canvasRenderConfig: isCropped ? newCanvasRenderConfig : canvasRenderConfig,
						voiceConfig: get(item, 'properties.voiceConfig'),
						enableVoice: get(item, 'properties.enableVoice'),
						aspectRatio:
							get(item, 'properties.aspectRatio') ||
							get(item, 'properties.canvasRenderConfig.aspectRatio'),
						endTime: Math.round((get(item, 'properties.endTime') ?? time) * 100) / 100,
					};
					timeMap[
						Math.round((get(item, 'properties.originalTime') ?? timeRounded) * 100) / 100
					] = timeRounded;

					// @ts-ignore
					elements[section].push(obj);

					break;
				}
				case 'spotlight':
				case 'blur': {
					const canvasRenderConfig = get(item, 'properties.canvasRenderConfig');

					const clickWindowWidth = get(canvasRenderConfig, 'windowCoordinates.width', 0);
					const clickWindowHeight = get(
						canvasRenderConfig,
						'windowCoordinates.height',
						0
					);

					const scaleCropToBlurX = scaleLinear(
						[0, actualCropWindowWidth],
						[0, get(canvasRenderConfig, 'windowCoordinates.width', 0)]
					);
					const scaleCropToBlurY = scaleLinear(
						[0, actualCropWindowHeight],
						[0, get(canvasRenderConfig, 'windowCoordinates.height', 0)]
					);
					const cropX = scaleCropToBlurX(actualCropX);
					const cropY = scaleCropToBlurY(actualCropY);
					const cropCanvasWidth = scaleCropToBlurX(actualCropCanvasWidth);
					const cropCanvasHeight = scaleCropToBlurY(actualCropCanvasHeight);

					console.log([cropX, cropX + cropCanvasWidth], { clickWindowWidth });
					const clickWindowScaleX = scaleLinear(
						[cropX, cropX + cropCanvasWidth],
						[0, clickWindowWidth]
					);

					const clickWindowScaleY = scaleLinear(
						[cropY, cropY + cropCanvasHeight],
						[0, clickWindowHeight]
					);

					const clickWindowScaleWidth = scaleLinear(
						[0, cropCanvasWidth],
						[0, clickWindowWidth]
					);

					const clickWindowScaleHeight = scaleLinear(
						[0, cropCanvasHeight],
						[0, clickWindowHeight]
					);

					const pointerScaleX = scaleLinear(
						[0, clickWindowWidth],
						[0, videoResourceProperties.width]
					);
					const pointerScaleY = scaleLinear(
						[0, clickWindowHeight],
						[0, videoResourceProperties.height]
					);

					const clickPositionX = pointerScaleX(
						(isCropped ? clickWindowScaleX : (i: number): number => i)(
							get(canvasRenderConfig, 'canvasCoordinates.x', 0)
						)
					);
					const clickPositionY = pointerScaleY(
						(isCropped ? clickWindowScaleY : (i: number): number => i)(
							get(canvasRenderConfig, 'canvasCoordinates.y', 0)
						)
					);
					const newClickWidth = pointerScaleX(
						(isCropped ? clickWindowScaleWidth : (i: number): number => i)(
							get(canvasRenderConfig, 'canvasCoordinates.width', 0)
						)
					);
					const newClickHeight = pointerScaleY(
						(isCropped ? clickWindowScaleHeight : (i: number): number => i)(
							get(canvasRenderConfig, 'canvasCoordinates.height', 0)
						)
					);

					const width = Math.abs(newClickWidth);
					const height = Math.abs(newClickHeight);

					const newCanvasRenderConfig = {
						canvasCoordinates: {
							x: clickPositionX,
							y: clickPositionY,
							width,
							height,
						},
						windowCoordinates: {
							width: videoResourceProperties.width,
							height: videoResourceProperties.height,
						},
					};

					const textArray = find(
						get(
							item,
							'properties.clickMeta.additionalMeta.closestElemAttributes.attributes'
						),
						(i) => get(i, 0) === 'innerText'
					);
					const text = get(textArray, 1);
					const title = get(item, 'properties.stepTitle');
					const imageId = get(item, 'properties.imageId', '');
					// @ts-ignore
					div.innerHTML = title || text || '';
					const obj = {
						id: item.id || uniqId(),
						editorElementId: item.editorElementId,
						editorElementName: item.editorElementName,
						time,
						nonRoundedTime: time,
						originalTime:
							Math.round((get(item, 'properties.originalTime') ?? time) * 100) / 100,
						order: get(item, 'properties.order'),
						subElement: get(item, 'properties.subElement'),
						subElementName: get(item, 'properties.subElementName'),
						fitToScreen: get(item, 'properties.fitToScreen'),
						duration: get(item, 'properties.duration'),
						imageUrl: get(floImageElements, item.id) || get(floImageElements, imageId),
						imageId,
						values: get(item, 'properties.values'),
						text: div.innerText || get(text, '1') || 'Click here',
						canvasRenderConfig: newCanvasRenderConfig,
						retentionTimeInSec: get(item, 'retentionTimeInSec'),
						position: get(item, 'properties.position'),
						enableZoom: get(item, 'properties.enableZoom'),
						intensity: get(item, 'properties.intensity'),
						shape: get(item, 'properties.shape'),
						aspectRatio:
							get(item, 'properties.aspectRatio') ||
							get(item, 'properties.canvasRenderConfig.aspectRatio'),
						endTime: Math.round((get(item, 'properties.endTime') ?? time) * 100) / 100,
					};
					timeMap[
						Math.round((get(item, 'properties.originalTime') ?? timeRounded) * 100) / 100
					] = timeRounded;

					// @ts-ignore
					elements[section].push(obj);
					break;
				}
				case 'image': {
					const canvasRenderConfig = get(item, 'properties.canvasRenderConfig');
					const fitToScreen = get(item, 'properties.fitToScreen');
					const clickWindowWidth = get(canvasRenderConfig, 'windowCoordinates.width', 0);
					const clickWindowHeight = get(
						canvasRenderConfig,
						'windowCoordinates.height',
						0
					);

					const scaleCropToBlurX = scaleLinear(
						[0, actualCropWindowWidth],
						[0, get(canvasRenderConfig, 'windowCoordinates.width', 0)]
					);
					const scaleCropToBlurY = scaleLinear(
						[0, actualCropWindowHeight],
						[0, get(canvasRenderConfig, 'windowCoordinates.height', 0)]
					);
					const cropX = scaleCropToBlurX(actualCropX);
					const cropY = scaleCropToBlurY(actualCropY);
					const cropCanvasWidth = scaleCropToBlurX(actualCropCanvasWidth);
					const cropCanvasHeight = scaleCropToBlurY(actualCropCanvasHeight);

					const clickWindowScaleX = scaleLinear(
						[cropX, cropX + cropCanvasWidth],
						[0, clickWindowWidth]
					);

					const clickWindowScaleY = scaleLinear(
						[cropY, cropY + cropCanvasHeight],
						[0, clickWindowHeight]
					);

					const clickWindowScaleWidth = scaleLinear(
						[0, cropCanvasWidth],
						[0, clickWindowWidth]
					);

					const clickWindowScaleHeight = scaleLinear(
						[0, cropCanvasHeight],
						[0, clickWindowHeight]
					);

					const pointerScaleX = scaleLinear(
						[0, clickWindowWidth],
						[0, videoResourceProperties.width]
					);
					const pointerScaleY = scaleLinear(
						[0, clickWindowHeight],
						[0, videoResourceProperties.height]
					);

					const clickPositionX = pointerScaleX(
						(isCropped ? clickWindowScaleX : (i: number): number => i)(
							get(canvasRenderConfig, 'canvasCoordinates.x', 0)
						)
					);
					const clickPositionY = pointerScaleY(
						(isCropped ? clickWindowScaleY : (i: number): number => i)(
							get(canvasRenderConfig, 'canvasCoordinates.y', 0)
						)
					);
					const newClickWidth = pointerScaleX(
						(isCropped ? clickWindowScaleWidth : (i: number): number => i)(
							get(canvasRenderConfig, 'canvasCoordinates.width', 0)
						)
					);
					const newClickHeight = pointerScaleY(
						(isCropped ? clickWindowScaleHeight : (i: number): number => i)(
							get(canvasRenderConfig, 'canvasCoordinates.height', 0)
						)
					);

					const width = Math.abs(newClickWidth);
					const height = Math.abs(newClickHeight);

					const newCanvasRenderConfig = {
						canvasCoordinates: {
							x: clickPositionX,
							y: clickPositionY,
							width,
							height,
							angle: get(canvasRenderConfig, 'canvasCoordinates.angle', 0),
						},
						windowCoordinates: {
							width: videoResourceProperties.width,
							height: videoResourceProperties.height,
						},
					};

					const textArray = find(
						get(
							item,
							'properties.clickMeta.additionalMeta.closestElemAttributes.attributes'
						),
						(i) => get(i, 0) === 'innerText'
					);
					const text = get(textArray, 1);
					const title = get(item, 'properties.stepTitle');
					const imageId = get(item, 'properties.imageId', '');
					// @ts-ignore
					div.innerHTML = title || text || '';
					const obj = {
						id: item.id || uniqId(),
						editorElementId: item.editorElementId,
						editorElementName: item.editorElementName,
						time,
						nonRoundedTime: time,
						behaviourType: get(item, 'properties.behaviourType'),
						originalTime:
							Math.round((get(item, 'properties.originalTime') ?? time) * 100) / 100,
						order: get(item, 'properties.order'),
						subElement: get(item, 'properties.subElement'),
						subElementName: get(item, 'properties.subElementName'),
						fitToScreen: false,
						// fitToScreen: get(item, 'properties.fitToScreen'),
						duration: get(item, 'properties.duration'),
						imageUrl: get(floImageElements, item.id) || get(floImageElements, imageId),
						imageId,
						values: get(item, 'properties.values'),
						text: div.innerText || get(text, '1') || 'Click here',
						canvasRenderConfig: newCanvasRenderConfig,
						retentionTimeInSec: get(item, 'retentionTimeInSec'),
						position: get(item, 'properties.position'),
						enableZoom: get(item, 'properties.enableZoom'),
						intensity: get(item, 'properties.intensity'),
						shape: get(item, 'properties.shape'),
						voiceConfig: get(item, 'properties.voiceConfig'),
						enableVoice: get(item, 'properties.enableVoice'),
						aspectRatio:
							get(item, 'properties.aspectRatio') ||
							get(item, 'properties.canvasRenderConfig.aspectRatio'),
						endTime: Math.round((get(item, 'properties.endTime') ?? time) * 100) / 100,
						linkedCtaId: get(item, 'properties.linkedCtaId'),
					};
					timeMap[
						Math.round((get(item, 'properties.originalTime') ?? timeRounded) * 100) / 100
					] = timeRounded;

					if (get(obj, 'linkedCtaId')) {
						elements.linked_images = elements.linked_images || [];
						// @ts-ignore
						elements.linked_images.push(obj);
					} else {
						// @ts-ignore
						elements[section].push(obj);
					}
					break;
				}
				case 'hotspots':
				case 'clicks': {
					const canvasRenderConfig = get(item, 'properties.canvasRenderConfig');
					const clickWindowWidth = get(canvasRenderConfig, 'windowCoordinates.width', 0);
					const clickWindowHeight = get(
						canvasRenderConfig,
						'windowCoordinates.height',
						0
					);
					const scaleCropToPointerX = scaleLinear(
						[
							0,
							get(cropData, 'properties.canvasRenderConfig.windowCoordinates.width', 0),
						],
						[0, get(canvasRenderConfig, 'windowCoordinates.width', 0)]
					);
					const scaleCropToPointerY = scaleLinear(
						[
							0,
							get(cropData, 'properties.canvasRenderConfig.windowCoordinates.height', 0),
						],
						[0, get(canvasRenderConfig, 'windowCoordinates.height', 0)]
					);
					const cropX = scaleCropToPointerX(actualCropX);
					const cropY = scaleCropToPointerY(actualCropY);
					const cropCanvasWidth = scaleCropToPointerX(actualCropCanvasWidth);
					const cropCanvasHeight = scaleCropToPointerY(actualCropCanvasHeight);

					const clickWindowScaleX = scaleLinear(
						[cropX, cropX + cropCanvasWidth],
						[0, clickWindowWidth]
					);

					const clickWindowScaleY = scaleLinear(
						[cropY, cropY + cropCanvasHeight],
						[0, clickWindowHeight]
					);
					const clickWindowScaleWidth = scaleLinear(
						[cropX, cropX + cropCanvasWidth],
						[0, clickWindowWidth]
					);

					const clickWindowScaleHeight = scaleLinear(
						[cropY, cropY + cropCanvasHeight],
						[0, clickWindowHeight]
					);

					const pointerScaleX = scaleLinear(
						[0, clickWindowWidth],
						[0, videoResourceProperties.width]
					);
					const pointerScaleY = scaleLinear(
						[0, clickWindowHeight],
						[0, videoResourceProperties.height]
					);

					const clickPositionX = pointerScaleX(
						(isCropped ? clickWindowScaleX : (i: number): number => i)(
							get(canvasRenderConfig, 'canvasCoordinates.x', 0)
						)
					);
					const clickPositionY = pointerScaleY(
						(isCropped ? clickWindowScaleY : (i: number): number => i)(
							get(canvasRenderConfig, 'canvasCoordinates.y', 0)
						)
					);
					const newClickWidth = pointerScaleX(
						(isCropped ? clickWindowScaleWidth : (i: number): number => i)(
							get(canvasRenderConfig, 'canvasCoordinates.width', 0)
						)
					);
					const newClickHeight = pointerScaleX(
						(isCropped ? clickWindowScaleHeight : (i: number): number => i)(
							get(canvasRenderConfig, 'canvasCoordinates.height', 0)
						)
					);

					const newClickPositionX = clickPositionX;
					const newClickPositionY = clickPositionY;

					const cx = Math.abs(newClickPositionX);
					const cy = Math.abs(newClickPositionY);

					const width = Math.abs(newClickWidth);
					const height = Math.abs(newClickHeight);

					const newCanvasRenderConfig = {
						canvasCoordinates: {
							x: cx,
							y: cy,
							width,
							height,
						},
						windowCoordinates: {
							width: videoResourceProperties.width,
							height: videoResourceProperties.height,
						},
					};
					const textArray = find(
						get(
							item,
							'properties.clickMeta.additionalMeta.closestElemAttributes.attributes'
						),
						(i) => get(i, 0) === 'innerText'
					);
					const text = get(textArray, 1);
					const title = get(item, 'properties.stepTitle');
					const imageId = get(item, 'properties.imageId', '');
					// @ts-ignore
					div.innerHTML = title || text || '';
					const obj = {
						id: item.id || uniqId(),
						editorElementId: item.editorElementId,
						editorElementName: item.editorElementName,
						time,
						nonRoundedTime: time,
						behaviourType: get(item, 'properties.behaviourType'),
						originalTime:
							Math.round((get(item, 'properties.originalTime') ?? time) * 100) / 100,
						order: get(item, 'properties.order'),
						subElement: get(item, 'properties.subElement'),
						subElementName: get(item, 'properties.subElementName'),
						fitToScreen: get(item, 'properties.fitToScreen'),
						duration: get(item, 'properties.duration'),
						imageUrl: get(floImageElements, item.id) || get(floImageElements, imageId),
						imageId,
						voiceConfig: get(item, 'properties.voiceConfig'),
						enableVoice: get(item, 'properties.enableVoice'),
						values: get(item, 'properties.values'),
						text: div.innerText || get(text, '1') || 'Click here',
						canvasRenderConfig: newCanvasRenderConfig,
						retentionTimeInSec: get(item, 'retentionTimeInSec'),
						position: get(item, 'properties.position'),
						enableZoom: get(item, 'properties.enableZoom'),
						intensity: get(item, 'properties.intensity'),
						shape: get(item, 'properties.shape'),
						aspectRatio:
							get(item, 'properties.aspectRatio') ||
							get(item, 'properties.canvasRenderConfig.aspectRatio'),
						endTime: Math.round((get(item, 'properties.endTime') ?? time) * 100) / 100,
						linkedCtaId: get(item, 'properties.linkedCtaId'),
					};
					timeMap[
						Math.round((get(item, 'properties.originalTime') ?? timeRounded) * 100) / 100
					] = timeRounded;
					// @ts-ignore
					elements[section].push(obj);
					break;
				}
				case 'cta': {
					const obj = {
						id: item.id,
						editorElementId: item.editorElementId,
						editorElementName: item.editorElementName,
						time: timeRounded,
						nonRoundedTime: get(item, 'properties.time', -1),
						originalTime:
							Math.round(
								(get(item, 'properties.originalTime') ??
									get(item, 'properties.time', -1)) * 100
							) / 100,
						values: get(item, 'properties.values'),
						voiceConfig: get(item, 'properties.voiceConfig'),
						enableVoice: get(item, 'properties.enableVoice'),
						retentionTimeInSec: get(item, 'retentionTimeInSec'),
						subElement: get(item, 'properties.subElement'),
						subElementName: get(item, 'properties.subElementName'),
						position: get(item, 'properties.position'),
						positionOnScreen: get(item, 'properties.values.positionOnScreen'),
						systemGenerated: get(item, 'properties.systemGenerated'),
						floEmbedUrls: get(item, 'properties.floEmbedUrls'),
						linkedImageId:
							get(item, 'properties.linkedImageId') ||
							get(linkedCtaImagesMap, get(item, 'id')),
					};
					timeMap[
						Math.round((get(item, 'properties.originalTime') ?? timeRounded) * 100) / 100
					] = timeRounded;

					let subElementName = '';
					if (obj.time < 0.3) {
						subElementName = 'info_ctastart';
						obj.time = 0;
					} else if (obj.time > duration - 0.3 || obj.position === 'end') {
						subElementName = 'info_ctaend';
						// obj.time = duration - 0.3;
						if (get(obj, 'values')) {
							const buttons = get(obj, 'values.button', []);
							const isSkipButtonPresent = find(buttons, ['type', 'skip-n-continue']);
							if (!isSkipButtonPresent) {
								const newButtons = [
									...buttons,
									{
										floId: '',
										id: 'ui_restart_button',
										name: 'Restart',
										selectedFloName: '',
										type: 'skip-n-continue',
										url: '',
									},
								];
								// @ts-ignore
								obj.values = {
									...(obj.values || {}),
									button: newButtons,
								};
							}
						}
					}
					if (subElementName) {
						elements[subElementName] = elements[subElementName] || [];
						// @ts-ignore
						elements[subElementName].push(obj);
					} else {
						// @ts-ignore
						elements[section].push(obj);
					}
					break;
				}
			}
		});

		const endCtaTime = get(elements, 'info_ctaend', []).map((item) => ({
			id: item.id,
			type: 'cta',
			time: Math.round(item.time * 100) / 100,
			nonRoundedTime: item.time,
			originalTime: Math.round((item.originalTime ?? item.time) * 100) / 100,
			isEndCta: true,
		}));
		const startCtaTime = get(elements, 'info_ctastart', []).map((item) => ({
			id: item.id,
			type: 'cta',
			time: 0,
			originalTime: 0,
			isStartCta: true,
		}));
		const inBetweenCtaTimes = get(elements, 'cta', []).map((item) => ({
			id: item.id,
			type: 'cta',
			time: Math.round(item.time * 100) / 100,
			nonRoundedTime: item.time,
			originalTime: Math.round((item.originalTime ?? item.time) * 100) / 100,
		}));

		const imageElements = get(elements, 'image', []).map((item) => ({
			id: item.id,
			type: 'image',
			time: Math.round(item.time * 100) / 100,
			nonRoundedTime: item.time,
			originalTime: Math.round((item.originalTime ?? item.time) * 100) / 100,
			meta: {
				behaviourType: get(item, 'behaviourType', ''),
			},
		}));

		const textElements = get(elements, 'text', [])
			.filter((item) => get(item, 'behaviourType') === 'stayFor')
			.map((item) => ({
				id: item.id,
				type: 'text',
				time: Math.round(item.time * 100) / 100,
				nonRoundedTime: item.time,
				originalTime: Math.round((item.originalTime ?? item.time) * 100) / 100,
				meta: {
					behaviourType: get(item, 'behaviourType', ''),
				},
			}));

		const clickElements = get(elements, 'clicks', []).map((item) => ({
			id: item.id,
			type: 'clicks',
			time: Math.round(item.time * 100) / 100,
			nonRoundedTime: item.time,
			originalTime: Math.round((item.originalTime ?? item.time) * 100) / 100,
		}));

		ctaAndHotspotTimes = [
			...startCtaTime,
			...inBetweenCtaTimes,
			...imageElements,
			...textElements,
			...clickElements,
		];

		if (get(elements, 'hotspots.length', 0)) {
			const counts: { [key: string]: number } = {};
			elements.hotspots = map(
				sortBy(elements.hotspots, (element) => {
					return Number(`${element.time}${element.order || 0}`);
				}),
				(hotspot) => {
					const time = Math.round(min([hotspot.time, duration]) * 100) / 100;
					const originalTime =
						Math.round((hotspot.originalTime ?? hotspot.time) * 100) / 100;

					counts[`${time}`] = (counts[`${time}`] || 0) + 1;

					const order = counts[`${time}`];

					ctaAndHotspotTimes.push({
						id: hotspot.id,
						type: 'hotspot',
						time,
						nonRoundedTime: hotspot.time,
						originalTime: originalTime || time,
						order,
					});
					return {
						...hotspot,
						order,
						time,
					};
				}
			);
			const hotspotsGroup = groupBy(elements.hotspots, 'time');
			const hotspotsTimes = sortBy(
				keys(hotspotsGroup).map((i) =>
					padStart(String(Math.round(Number(i) * 1000)), 6, '0')
				)
			).map((i) => Number(i));
			yield put(
				setHotspotsGrouped({
					data: hotspotsGroup,
					floId: get(payload, 'floId'),
					floIndex: get(payload, 'floIndex'),
				})
			);
			yield put(
				setHotspotsTimes({
					data: hotspotsTimes,
					floId: get(payload, 'floId'),
					floIndex: get(payload, 'floIndex'),
				})
			);
		}
		const queryParam = reduce(
			entries(qs.parse(location.search.replace('?', ''))),
			(a, [key, val]) => ({
				...a,
				[key]: val === 'true',
			}),
			{}
		);
		const swiftPlay = isSwiftPlay(get(payload, 'floIndex'));
		if (swiftPlay && outputType === 'interactive demo') {
			ctaAndHotspotTimes = filter(ctaAndHotspotTimes || [], (item) => {
				const type = get(item, 'type');
				const behaviourType = get(item, 'meta.behaviourType', '');

				return (
					(type !== 'image' && type !== 'text') ||
					(!!behaviourType && behaviourType !== 'stayFor')
				);
			});
		}
		if (ctaAndHotspotTimes.length) {
			ctaAndHotspotTimes = sortBy(ctaAndHotspotTimes, (item) => {
				return `${padStart(String(Math.round(item.time * 1000)), 6, '0')}_${get(
					SORT_PRIORITY,
					item.type,
					3
				)}_${padStart(String(item.order ?? '999'), 3, '0')}`;
			});
			ctaAndHotspotTimes = [...ctaAndHotspotTimes, ...endCtaTime];
			yield put(
				setCtaAndHotspotTimes({
					data: ctaAndHotspotTimes,
					floId: get(payload, 'floId'),
					floIndex: get(payload, 'floIndex'),
				})
			);
		}
		yield put(
			setElements({
				data: elements,
				floId: get(payload, 'floId'),
				floIndex: get(payload, 'floIndex'),
			})
		);
		const images = yield select((state) =>
			get(state, `floPage.flos.${get(payload, 'floIndex')}.swiftPlayImages`)
		);
		const newImages = map(images, (image) => ({
			...image,
			time: timeMap[get(image, 'time')] ?? get(image, 'time'),
			actualTime: get(image, 'time'),
		}));

		yield put(
			setSwiftPlayImages({
				data: sortBy(newImages, (i) => i.time),
				floId: get(payload, 'floId'),
				floIndex: get(payload, 'floIndex'),
			})
		);
		yield put(hideLoader(`flo_${get(payload, 'floIndex')}`));
	} catch (e) {
		// console.error('****flo getReplyIdSaga failed=', e);
		yield put(hideLoader(`flo_${get(payload, 'floIndex')}`));
		yield put(
			analyticsLog({
				level: 'error',
				message: `flo getReplyIdSaga failed ${location.href} ${JSON.stringify(
					get(e, 'response')
				)}`,
				value: get(e, 'stack'),
			})
		);
		yield put(setErrorToast({ data: e, floIndex: get(payload, 'floIndex') }));
	}
}

function* getTrimValues({
	payload,
}: {
	payload: { config: elementBeanListType; floId: string; floIndex: number };
}) {
	const { trimEndTime, trimStartTime } = payload?.config?.properties || {};
	const startTime = (trimStartTime && parseFloat(trimStartTime.replace(/00:/g, ''))) || 0;
	const endTime = (trimEndTime && parseFloat(trimEndTime.replace(/00:/g, ''))) || 0;

	const trackConfig: TrimStateType = yield select((state) =>
		get(state, `floPage.flos.${get(payload, 'floIndex')}.postProcessConfigs.trim`)
	) || {};
	const { originalLength } = trackConfig || {};
	if (!originalLength) return;

	const rangeStart = (startTime / originalLength) * 100;
	const rangeEnd = (endTime / originalLength) * 100;
	const trim = {
		enable: false,
		timeValue: [startTime, endTime],
		values: [trimStartTime || '', trimEndTime || ''],
		rangeValues: [rangeStart, rangeEnd],
		originalLength: trackConfig.originalLength,
	};

	yield put(
		setTrimDataAction({
			data: trim,
			floId: get(payload, 'floId'),
			floIndex: get(payload, 'floIndex'),
		})
	);
}

function* getCropValues({
	payload,
}: {
	payload: { data: elementBeanListType; floId: string; floIndex: number };
}) {
	// @ts-ignore
	const floResources = yield select((state) =>
		get(state, `floPage.flos.${get(payload, 'floIndex')}.tracks`)
	);
	const dims = reduce(
		floResources,
		(
			acc,
			item
		): {
			[key: string]: {
				height: number;
				width: number;
			};
		} => {
			// @ts-ignore
			acc[item.type] = {
				width: item.width,
				height: item.height,
			};
			return acc;
		},
		{}
	);

	const data = {
		enable: false,
		values: {
			x: Number(get(payload, 'properties.canvasRenderConfig.canvasCoordinates.x', 0)),
			y: Number(get(payload, 'properties.canvasRenderConfig.canvasCoordinates.y', 0)),
			x2:
				Number(get(payload, 'properties.canvasRenderConfig.canvasCoordinates.x', 0)) +
				Number(get(payload, 'properties.canvasRenderConfig.canvasCoordinates.width', 0)),
			y2:
				Number(get(payload, 'properties.canvasRenderConfig.canvasCoordinates.y', 0)) +
				Number(get(payload, 'properties.canvasRenderConfig.canvasCoordinates.height', 0)),
			// @ts-ignore
			windowSize: dims.screenShare || dims.video,
		},
		// @ts-ignore
		videoSize: dims.screenShare || dims.video,
	};
	yield put(
		setCropDataAction({
			data,
			floId: get(payload, 'floId'),
			floIndex: get(payload, 'floIndex'),
		})
	);
}

export function* getElementsConfigSaga({
	payload,
}: {
	payload: {
		floId: string;
		floIndex: number;
	};
}) {
	try {
		const { floId } = payload;
		const response: { data: editorsConfigDataType } = yield call(
			new API(undefined, false, false, false, '', get(payload, 'floIndex')).get,
			`${API_BASE}/v1/elements/flo/${floId}`
		);
		const elementBeanList = get(response, 'data.elementBeanList');
		yield put(
			setElementsConfig({
				data: elementBeanList,
				floId: get(payload, 'floId'),
				floIndex: get(payload, 'floIndex'),
			})
		);
		let appliedTrimConfig = {};
		let appliedCropConfig = {};

		forEach(elementBeanList, function (item) {
			if (item.editorElementName === 'trim') appliedTrimConfig = item;
			if (item.editorElementName === 'crop') appliedCropConfig = item;
		});

		let isTrimmed = !isEmpty(appliedTrimConfig);
		let isCropped = !isEmpty(appliedCropConfig);

		if (isTrimmed) {
			yield call(getTrimValues, {
				payload: { config: appliedTrimConfig, floId, floIndex: get(payload, 'floIndex') },
			});
		}
		if (isCropped) {
			yield call(getCropValues, {
				payload: { data: appliedCropConfig, floId, floIndex: get(payload, 'floIndex') },
			});
		}
	} catch (e) {
		// console.error(e);
		yield put(
			analyticsLog({
				level: 'error',
				message: `getElementsConfigSaga ${location.href} ${JSON.stringify(
					get(e, 'response')
				)}`,
				value: get(e, 'stack'),
			})
		);
	}
}

export function* editTrimSaga({
	payload,
}: {
	payload: {
		floId: string;
		floIndex: number;
	};
}) {
	try {
		const { floId, floIndex } = payload;
		const trimConfig: TrimStateType = yield select((state) =>
			get(state, `floPage.flos.${floIndex}.postProcessConfigs.trim`)
		);
		const editorElement: elementsDataType[] = yield select((state) =>
			get(state, `floPage.flos.${floIndex}.editorsElements.Edit Video`)
		);
		const trimElement: elementBeanListType = find(editorElement, { name: 'trim' }) || {};

		const elementsConfig: elementBeanListType[] = yield select((state) =>
			get(state, `floPage.flos.${floIndex}.editorsElementsConfig`)
		);
		const trimElementConfig: elementBeanListType =
			find(elementsConfig, { editorElementName: 'trim' }) || {};

		const reqBody = {
			trimStartTime: get(trimConfig, 'values[0]', '0'),
			trimEndTime: get(trimConfig, 'values[1]', '0'),
		};

		if (!isEmpty(trimElementConfig)) {
			const response: { data: { id: string } } = yield call(
				new API(undefined, false, false, false, '', get(payload, 'floIndex')).put,
				`${API_BASE}/v1/elements/flo/${floId}?id=${trimElementConfig.id}`,
				reqBody
			);
		} else {
			const response: { data: { id: string } } = yield call(
				new API(undefined, false, false, false, '', get(payload, 'floIndex')).post,
				`${API_BASE}/v1/elements/flo/${floId}?editorElementId=${trimElement.id}`,
				[reqBody]
			);
		}
		yield put(
			setTrimDataAction({
				data: {
					...trimConfig,
					enable: false,
				},
				floId,
			})
		);
	} catch (e) {
		// console.error(e);
		yield put(
			analyticsLog({
				level: 'error',
				message: `editTrimSaga ${location.href} ${JSON.stringify(get(e, 'response'))}`,
				value: get(e, 'stack'),
			})
		);
	}
}

function* changePublishConfigurationSaga({
	payload,
}: {
	payload: {
		outputTypeId: string;
		publishConfigurationId: string;
		floId: string;
		enabled: boolean;
		floIndex: number;
	};
}): SagaIterator {
	try {
		const { outputTypeId, publishConfigurationId, floId, enabled, floIndex } = payload;
		const response = yield call(
			new API(undefined, false, false, false, '', get(payload, 'floIndex')).put,
			`${API_BASE}/v1/flo/${floId}/publish-configuration`,
			{
				outputTypeId,
				publishConfigurationId,
			}
		);
		const { data } = response;
		if (!data) return;

		const floOutputMetadata = yield select((state) =>
			get(state, `floPage.flos.${floIndex}.floDetails.floOutputMetadata`)
		);
		const newFloMetadata = JSON.parse(JSON.stringify(floOutputMetadata));
		const videoData = newFloMetadata.find((item: floOutputMetadataType) => {
			if (item.outputType === 'video' || item.outputType === 'interactive demo')
				return item;
		});
		if (isEmpty(videoData)) return;

		const { publishedFloConfiguration } = videoData;
		const publishedConfiguration = publishedFloConfiguration.find(
			(item: publishedFloConfigurationType) => {
				if (item.configurationId === publishConfigurationId) return item;
			}
		);
		if (isEmpty(publishedConfiguration)) return;
		publishedConfiguration.enabled = enabled;
		yield put(setFloOutputMetadata({ data: newFloMetadata, floId, floIndex }));
	} catch (e) {
		// console.error('****flo changePublishConfigurationSaga failed=', e);
		yield put(
			analyticsLog({
				level: 'error',
				message: `flo changePublishConfigurationSaga failed ${
					location.href
				} ${JSON.stringify(get(e, 'response'))}`,
				value: get(e, 'stack'),
			})
		);
		yield put(setErrorToast({ data: e, floIndex: get(payload, 'floIndex') }));
	}
}

export function* editCtaElementSaga({
	payload,
}: {
	payload: {
		isAddCta: boolean;
		floId: string;
		floIndex: number;
		time: number;
		ctaValues: {
			heading?: string;
			subheading?: string;
			button?: string;
			url?: string;
		};
	};
}) {
	try {
		const { floId, ctaValues, time, isAddCta, floIndex } = payload;
		const editorElement: elementsDataType[] = yield select((state) =>
			get(state, `floPage.flos.${floIndex}.editorsElements.Elements`)
		);
		const ctaElement: elementBeanListType = find(editorElement, { name: 'cta' }) || {};

		const elementsConfig: elementBeanListType[] = yield select((state) =>
			get(state, `floPage.flos.${floIndex}.editorsElementsConfig`)
		);
		const elementConfig: elementBeanListType =
			find(elementsConfig, { editorElementName: 'cta' }) || {};
		const reqBody = {
			time,
			ctaValues,
		};

		if (!isAddCta) {
			const response: { data: { id: string } } = yield call(
				new API(undefined, false, false, false, '', get(payload, 'floIndex')).put,
				`${API_BASE}/v1/elements/flo/${floId}?id=${elementConfig.id}`,
				reqBody
			);
		} else {
			const response: { data: { id: string } } = yield call(
				new API(undefined, false, false, false, '', get(payload, 'floIndex')).post,
				`${API_BASE}/v1/elements/flo/${floId}?editorElementId=${ctaElement.id}`,
				[reqBody]
			);
		}
		yield put(getElementsConfig({ floId, floIndex }));
		yield put(hideDialogAction(floIndex));
	} catch (e) {
		// console.error(e);
		yield put(
			analyticsLog({
				level: 'error',
				message: `editCtaElementSaga ${location.href} ${JSON.stringify(
					get(e, 'response')
				)}`,
				value: get(e, 'stack'),
			})
		);
	}
}

function* rootSagas(): Generator<ForkEffect> {
	const tasks = [
		// @ts-ignore
		yield takeEvery(getFloDetails.type, getFloDetailsSaga),
		// @ts-ignore
		yield takeLatest(authSuccess.type, getFloDetailsSaga),
		// @ts-ignore
		yield takeEvery(getFloTracks.type, getFloTracksSaga),
		// @ts-ignore
		yield takeEvery(getAnnotationsMeta.type, getAnnotationsMetaSaga),
		// @ts-ignore
		yield debounce(100, getAnnotationsByTime.type, getAnnotationsByTimeSaga),
		// @ts-ignore
		yield takeEvery(sendAnnotationAction.type, addAnnotationSaga),
		// @ts-ignore
		yield takeEvery(trackCtaAction.type, trackCTA),
		// @ts-ignore
		yield debounce(500, trackViewCountAction.type, updateViewStatus),
		// @ts-ignore
		yield takeEvery(trackHotspotAction.type, trackHotspot),
		// @ts-ignore
		yield takeEvery(trackClickAction.type, trackClick),
		// @ts-ignore
		yield takeEvery(trackCompletionAction.type, updateViewCompletedStatus),
		// @ts-ignore
		yield takeEvery(onSSEMessage.type, onSSEMessageSaga),
		// @ts-ignore
		yield takeEvery(initiateSSE.type, initiateSSESaga),
		// @ts-ignore
		yield takeEvery(getElements.type, getElementsSaga),
		// @ts-ignore
		yield takeEvery(getElementsConfig.type, getElementsConfigSaga),
		// @ts-ignore
		yield takeEvery(changePublishConfiguration.type, changePublishConfigurationSaga),
		// @ts-ignore
		yield takeEvery(getBrandImageAction.type, getBrandImage),
		// @ts-ignore
		yield takeEvery(getBrandHeaderImageAction.type, getBrandHeaderImage),
		// @ts-ignore
		yield takeEvery(parseSubtitle.type, parseVtt),
		// @ts-ignore
		yield takeLatest(getFloSpaceDataAction.type, getFloSpaceData),
	];
	// @ts-ignore
	yield takeEvery(leaveFloPageAction.type, CancelSagas, tasks);
}

export function runFloSagas() {
	// @ts-ignore
	store.runSaga(rootSagas);
}
