import { FloReducerType } from '../../Flo.reducers';
import { SwiftPlayImageType } from '../../Flo.types';

const SwiftPlayReducer = {
	setSwiftPlayImages(
		state: FloReducerType,
		{
			payload,
		}: {
			payload: { data: Array<SwiftPlayImageType>; floId: string; floIndex: string };
		}
	) {
		state.flos[payload.floIndex].swiftPlayImages = payload.data;
	},
};

export default SwiftPlayReducer;
