import { createSlice } from '@reduxjs/toolkit';
import {
	FloSpaceItem,
	Profile,
	RoutesReducerState,
	getFloSpaceType,
} from './Routes.types';
import { externalAppInfoType } from '../../Common/Common.types';

const initialState = Object.freeze({
	isLoading: false,
	isAuthenticated: false,
	anonymous: false,
	floikCode: '',
	theme: 'light',
	authToken: '',
	refreshToken: '',
	currentFloSpace: null,
	profile: null,
	externalAppInfo: {},
});

const routesReducers = {
	getProfileDetails(
		state: RoutesReducerState,
		{ payload }: { payload: { update: boolean } }
	) {},
	setProfileDetails(state: RoutesReducerState, { payload }: { payload: Profile }) {
		state.profile = payload;
	},
	getFloSpace(state: RoutesReducerState, { payload }: { payload: getFloSpaceType }) {},
	getAuth(state: RoutesReducerState) {
		state.isLoading = true;
	},
	setAnonymous(state: RoutesReducerState, { payload }: { payload: boolean }) {
		state.anonymous = payload;
	},
	setAuth(state: RoutesReducerState, { payload }: { payload: boolean }) {
		state.isLoading = false;
		state.isAuthenticated = payload;
	},
	setAuthToken(state: RoutesReducerState, { payload }: { payload: string }) {
		state.authToken = payload;
	},
	setCurrentFloSpace(state: RoutesReducerState, { payload }: { payload: FloSpaceItem }) {
		state.currentFloSpace = payload;
	},
	setExternalAppInfo(
		state: RoutesReducerState,
		{ payload }: { payload: externalAppInfoType }
	) {
		state.externalAppInfo = payload;
	},
	updateExternalAppInfo(
		state: RoutesReducerState,
		{ payload }: { payload: externalAppInfoType }
	) {
		state.externalAppInfo = {
			...state.externalAppInfo,
			...payload,
		};
	},
	onAuthSuccess(
		state: RoutesReducerState,
		{
			payload,
		}: {
			payload: Omit<RoutesReducerState, 'isLoading' | 'isAuthenticated'>;
		}
	) {
		state.isLoading = false;
		state.isAuthenticated = true;
		state.userName = payload.userName;
		state.userId = payload.userId;
		state.authToken = payload.authToken;
		state.refreshToken = payload.refreshToken;
	},
	checkIsLoggedIn(state: RoutesReducerState) {
		return state;
	},
	leaveRoutes(state: RoutesReducerState) {
		return initialState;
	},
	createAnonymousUser(state: RoutesReducerState) {
		state.isLoading = true;
	},
	logout(state: RoutesReducerState) {
		state.isLoading = false;
		state.isAuthenticated = false;
	},
	refreshToken(state: RoutesReducerState) {},
	getProperFloUrl(state: RoutesReducerState, { payload }: { payload: string }) {},
	updateTheme(state: RoutesReducerState, { payload }: { payload: string }) {},
	authSuccess(state: RoutesReducerState, { payload }: { payload: string }) {},
	updateThemeSuccess(state: RoutesReducerState, { payload }: { payload: string }) {
		state.theme = payload;
	},
	setBrandImage(
		state: RoutesReducerState,
		{
			payload,
		}: {
			payload: {
				url: string;
				position: string;
			};
		}
	) {
		state.brandImage = payload.url;
		state.brandPosition = payload.position;
	},
	setBrandHeaderImage(
		state: RoutesReducerState,
		{
			payload,
		}: {
			payload: {
				url: string;
			};
		}
	) {
		state.headerImage = payload.url;
	},
	sendOtpAction(
		state: RoutesReducerState,
		{
			payload,
		}: {
			payload: {
				floId: string;
				email: string;
			};
		}
	) {},
	validateOtp(
		state: RoutesReducerState,
		{
			payload,
		}: {
			payload: {
				floId: string;
				email: string;
				otp: string;
				floIndex: number;
			};
		}
	) {},
	setFloAccessToken(
		state: RoutesReducerState,
		{
			payload,
		}: {
			payload: {
				data: {
					tokenValid: boolean;
					floAuthToken: string;
				};
				floIndex: number;
			};
		}
	) {
		state.floAuthInfo = state.floAuthInfo || [];
		state.floAuthInfo[payload.floIndex] = payload.data;
	},
	checkIsFloTokenValid(
		state: RoutesReducerState,
		{
			payload,
		}: {
			payload: {
				token: string;
				floId: string;
				floIndex: number;
			};
		}
	) {},
};

export const routesContainer = createSlice({
	name: 'routesContainer',
	initialState,
	// @ts-ignore
	reducers: routesReducers,
});

export const authSuccess = routesContainer.actions.authSuccess;
export const setAuthAction = routesContainer.actions.setAuth;
export const setAuthTokenAction = routesContainer.actions.setAuthToken;
export const logoutAction = routesContainer.actions.logout;
export const setCurrentFloSpaceAction = routesContainer.actions.setCurrentFloSpace;
export const checkIsLoggedInAction = routesContainer.actions.checkIsLoggedIn;
export const onAuthSuccessAction = routesContainer.actions.onAuthSuccess;
export const createAnonymousUserAction = routesContainer.actions.createAnonymousUser;
export const setAnonymousAction = routesContainer.actions.setAnonymous;
export const leaveRoutesAction = routesContainer.actions.leaveRoutes;
export const refreshTokenAction = routesContainer.actions.refreshToken;
export const updateThemeAction = routesContainer.actions.updateTheme;
export const updateThemeSuccessAction = routesContainer.actions.updateThemeSuccess;
export const setBrandImage = routesContainer.actions.setBrandImage;
export const setBrandHeaderImage = routesContainer.actions.setBrandHeaderImage;
export const sendOtpAction = routesContainer.actions.sendOtpAction;
export const validateOtp = routesContainer.actions.validateOtp;
export const checkIsFloTokenValid = routesContainer.actions.checkIsFloTokenValid;
export const setFloAccessToken = routesContainer.actions.setFloAccessToken;
export const setExternalAppInfo = routesContainer.actions.setExternalAppInfo;
export const updateExternalAppInfo = routesContainer.actions.updateExternalAppInfo;
export default routesContainer.reducer;
