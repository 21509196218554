export const loginLoadingMessge = 'logging in...';
export const FloMessageMaxLength = 500;
export const FloNameMaxLength = 50;
export const UserNameMaxLength = 22;

export const AudioWaveStroke = '#FFFFFF';
export const AudioWaveFill = '#FFFFFF';
export const AudioWaveFillOpacity = 0.5;
export const AudioWaveStrokeOpacity = 0.5;
export const AudioWaveWidth = 100;
export const AudioWaveHeight = 500;
export const AudioWaveMargin = {
	top: 10,
	right: 30,
	left: 0,
	bottom: 0,
};

export const MIN_CROP_WIDTH = 640;
export const MIN_CROP_HEIGHT = 360;

export const PLAYBACK_SPEED_OPTIONS = [
	{ value: 2, title: '2x' },
	{ value: 1.5, title: '1.5x' },
	{ value: 1, title: '1x' },
	{ value: 0.5, title: '0.5x' },
];
export const MAX_MOBILE_VIEW_WIDTH = 450;
export const MAX_TABLET_VIEW_WIDTH = 650;
export const MAX_MOBILE_VIEW_HEIGHT_IN_LANDSCAPE = 576;
export const MOBILE_VIEW_CLICK_ACTIVITY_BORDER_SIZE = 100;
export const CLICK_ACTIVITY_BORDER_SIZE = 160;
