import { lazy } from 'react';

const SwiftplayInteractiveMod = lazy(
	() => import('../SwiftplayInteractiveMode/SwiftplayInteractiveMode')
);
const LazyLoadedSwiftPlayInteractiveVideo = (props: {
	hideHeader?: boolean;
	hideHeaderBecauseOfData?: boolean;
	themeColor: string;
	view: string;
	wrapper: string;
}) => <SwiftplayInteractiveMod {...props} />;

export default LazyLoadedSwiftPlayInteractiveVideo;
