import { combineReducers } from 'redux';
import RoutesContainerReducer from '../../Containers/Routes/Routes.reducers';
import floPageReducer from '../../Pages/Flo/Flo.reducers';
import loader from '../Loader/LoaderReducer';
import dialogReducer from '../../Components/Dialog/Dialog.reducer';
import toastsReducer from '../../Components/Notification/Toasts.reducers';
import RoutesReducer from './Routes.reducer';
import LoaderReducer from './Loader.reducer';
import videoDialogReducer from '../../Components/VideoDialog/VideoDialog.reducer';
import analyticsLog from './Log.reducer';

export default combineReducers({
	loader,
	routes: RoutesReducer,
	routerContainer: RoutesContainerReducer,
	floPage: floPageReducer,
	dialog: dialogReducer,
	videoDialog: videoDialogReducer,
	toasts: toastsReducer,
	loaders: LoaderReducer,
	log: analyticsLog,
});
