import React, { useEffect } from 'react';
import classNames from 'classnames';
import parentStyles from '../../Flo.module.css';
import styles from './Folders.module.css';
import { Box, Grid } from '@mui/material';
import {
	IconArrowUpRight,
	IconFileText,
	IconGif,
	IconMovie,
	IconPresentationAnalytics,
} from '@tabler/icons-react';
import { ReactComponent as InteractiveDemoIcon } from '../../../../Common/images/ID-Icon.svg';
import { useSelector } from 'react-redux';
import { get, map } from 'lodash';

const FloIcons = {
	video: IconMovie,
	document: IconFileText,
	gif: IconGif,
	mouse_pointer: IconPresentationAnalytics,
	'interactive demo': InteractiveDemoIcon,
	default: IconMovie,
};

const Folders = ({
	hideHeader,
	floId,
	floIndex,
}: {
	hideHeader?: boolean;
	floId: string;
	floIndex: number;
}) => {
	const folderName = useSelector(
		(state) =>
			get(state, `floPage.flos.${floIndex}.floDetails.heading`) ||
			get(state, `floPage.flos.${floIndex}.floDetails.name`) ||
			[]
	);

	useEffect(() => {
		try {
			const elem = document.querySelector('#floik-html-loader');
			if (elem) {
				document.body.removeChild(elem);
			}
		} catch (e) {
			console.error(e);
		}
	}, []);

	const folders = useSelector(
		(state) => get(state, `floPage.flos.${floIndex}.floDetails.subElements`) || []
	);
	return (
		<Grid
			item
			desktop={12}
			laptop={12}
			tablet={12}
			mobile={12}
			classes={{
				root: classNames(parentStyles.leftGridContainer, styles.container, {
					[parentStyles.gridWithoutHeader]: hideHeader,
				}),
			}}
		>
			<div className={classNames(styles.foldersContainer)}>
				<div
					className={classNames(styles.folderTitle, 'max-width-1032', {
						[styles.folderTitleWithoutHeader]: hideHeader,
					})}
				>
					{folderName}
				</div>
				<div
					className={classNames(styles.flosContainer, {
						[styles.flosContainerWithoutHeader]: hideHeader,
					})}
				>
					{map(
						folders,
						(folder: { name: string; type: string; url: string; outputType: string }) => {
							const Icon = get(FloIcons, get(folder, 'outputType')) || FloIcons.default;
							return (
								<Box
									component="a"
									href={get(folder, 'url')}
									target="_blank"
									className={classNames(styles.flos, 'max-width-1032')}
								>
									<div className={styles.floLeftSection}>
										<Icon
											className={styles[`style_${get(folder, 'type', 'default')}`]}
											size={20}
										/>
										<span className={styles.floName}>
											{get(folder, 'heading', '') || get(folder, 'name', '')}{' '}
										</span>
									</div>
									<div className={styles.floRightSection}>
										<IconArrowUpRight size={16} />
									</div>
								</Box>
							);
						}
					)}
				</div>
			</div>
		</Grid>
	);
};

export default Folders;
