import { RootState } from '../../store';
import { get } from 'lodash';

export const getIsAuthenticated = (state: RootState) =>
	get(state, 'routerContainer.isAuthenticated', true);

export const getIsLoading = (state: RootState) =>
	get(state, 'routerContainer.isLoading', true);

export const getTheme = (state: RootState) =>
	get(state, 'routerContainer.theme', 'light');
