import React, { lazy, useEffect } from 'react';
// import Instructions from './Instructions/Instructions';

const Instructions = lazy(() => import('./Instructions/Instructions'));

const GuideFlo = ({
	hideHeader,
	hideHeaderBecauseOfData,
	floId,
	floIndex,
}: {
	hideHeader?: boolean;
	hideHeaderBecauseOfData?: boolean;
	floId: string;
	floIndex: number;
}) => {
	useEffect(() => {
		try {
			const elem = document.querySelector('#floik-html-loader');
			if (elem) {
				document.body.removeChild(elem);
			}
		} catch (e) {
			console.error(e);
		}
	}, []);

	return (
		<Instructions
			hideHeader={hideHeader}
			hideHeaderBecauseOfData={hideHeaderBecauseOfData}
			floId={floId}
			floIndex={floIndex}
		/>
	);
};

export default GuideFlo;
