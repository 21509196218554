import React, { ReactNode, useState } from 'react';
import { Dialog, DialogActions, DialogContent, Button, Typography } from '@mui/material';
import styles from './SnackBar.module.css';
import classNames from 'classnames';

const Snackbar = ({
	open,
	onClose,
	duration = 3000,
	children,
	container,
	disablePortal,
	isEmbed,
}: {
	onClose: () => void;
	open: boolean;
	duration: number;
	children: ReactNode;
	container?: Element | null;
	disablePortal?: boolean;
	isEmbed?: boolean;
}) => {
	// Automatically close the snackbar after the specified duration
	React.useEffect(() => {
		if (open) {
			const timer = setTimeout(() => {
				onClose();
			}, duration);

			// Clean up the timer when the component unmounts or when open changes
			return () => clearTimeout(timer);
		}
	}, [open, duration, onClose]);

	return (
		<Dialog
			open={open}
			onClose={onClose}
			aria-labelledby="custom-snackbar"
			aria-describedby="custom-snackbar-description"
			disablePortal={disablePortal}
			container={container}
			classes={{
				root: classNames(styles.dialogRoot, { [styles.isEmbed]: isEmbed }),
				paper: styles.container,
			}}
		>
			<DialogContent className={styles.dialogContent}>{children}</DialogContent>
		</Dialog>
	);
};

export default Snackbar;
