import React, { useCallback, useEffect } from 'react';
import { isMicroApp } from '../../Common/Common.utils';

const AppObserver = () => {
	useEffect(() => {
		const targetElement = document.querySelector('[data-bs-theme]'); // Selects the element with any data-bs-theme attribute

		if (targetElement) {
			setTheme(targetElement);
			// Create a MutationObserver instance with a callback function
			const observer = new MutationObserver((mutationsList) => {
				for (let mutation of mutationsList) {
					if (
						mutation.type === 'attributes' &&
						mutation.attributeName === 'data-bs-theme'
					) {
						setTheme(targetElement);
					}
				}
			});

			// Set up the configuration for the observer to monitor attribute changes
			const config = {
				attributes: true, // Watch for attribute changes
				childList: false, // Don't observe child node changes
				subtree: false, // Don't observe descendants
			};

			observer.observe(targetElement, config);

			// Cleanup function to disconnect the observer when the component is unmounted
			return () => {
				observer.disconnect();
			};
		} else {
			console.error('Target element with data-bs-theme not found.');
		}
	}, []);

	const setTheme = useCallback((targetElement: any) => {
		const theme = targetElement.getAttribute('data-bs-theme') || 'light';
		const microElements = document.querySelectorAll('[doc360-app="true"]');
		// Iterate through each element and set 'theme' attribute to 'dark'
		microElements.forEach((microElement) => {
			microElement.setAttribute('theme', theme); // Set to dark or light
		});
	}, []);

	return null;
};

export default AppObserver;
