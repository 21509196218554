import { call, takeEvery } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import API from '../../utils/API';
import { analyticsLog } from '../reducers/Log.reducer';
import { API_BASE } from '../../Common/Common.env';
import { isMicroApp } from '../../Common/Common.utils';

const disableLogs = isMicroApp();

function* analyticsLogSaga({
	payload,
}: {
	payload: {
		level?: string;
		message?: string;
		value?: unknown;
		code?: string;
	};
}): SagaIterator {
	try {
		console.log('log>>', payload);
		if (payload.code !== 'ERR_CANCELED' && !disableLogs) {
			yield call(new API().post, `${API_BASE}/v1/global/client/log`, {
				eventType: 'app',
				level: payload.level || 'info',
				timeStamp: new Date(),
				message: payload.message + ` ${JSON.stringify(payload.value || '')}`,
				payload: payload.value,
			});
		}
	} catch (e) {
		console.log(e);
	}
}

export default function* AnalyticsLogSagas() {
	const tasks = [
		// @ts-ignore
		yield takeEvery(analyticsLog.type, analyticsLogSaga),
	];
}
