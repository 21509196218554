import { trim } from 'lodash';

export const API_BASE = trim(
	process.env.REACT_APP_API_ENDPOINT || 'https://api.signoff.ai'
);
export const APP_ENDPOINT = trim(
	process.env.REACT_APP_ENDPOINT || 'https://us.signoff.ai'
);
export const APP_WEBSITE_URL = trim(
	process.env.REACT_APP_WEBSITE_URL || 'https://www.signoff.ai'
);

export const APP_FLOSPACE_DATA_URL = trim(
	process.env.REACT_APP_FLOSPACE_DATA_URL || 'https://dz7w2mkx73qy2.cloudfront.net'
);
export const EVENT_SOURCE_BASE = trim(
	process.env.REACT_APP_EVENT_SOURCE_API_ENDPOINT || 'https://hermes.signoff.ai'
);
export const WEBSITE_PRICING_PAGE = trim(
	process.env.REACT_APP_WEBSITE_PRICING_PAGE || 'https://floik.com/pricing'
);

export const PUBLISH_ASSETS_BASE_URL = trim(
	process.env.REACT_APP_PUBLISH_ASSETS_BASE_URL || 'https://signoff.ai/exe'
);
export const APP_FLOSPACE_DATA_URL_DOC360 = trim(
	process.env.REACT_APP_FLOSPACE_DATA_URL_DOC360 || 'https://dz7w2mkx73qy2.cloudfront.net'
);
export const APP_FLOSPACE_DATA_URL_DOC360_WITH_BASE_PATH = trim(
	process.env.REACT_APP_FLOSPACE_DATA_URL_DOC360_WITH_BASE_PATH ||
		'https://d32xs6ofrajxuq.cloudfront.net'
);
