export const appName = 'Floik';
export const cancelButtonText = 'Cancel';
export const confirmButtonText = 'Confirm';
export const defaultDropdownSelectText = 'Select';
export const page404ContentTitle = 'Ooops! You weren’t <br/> supposed to see this';
export const page404ContentText =
	'The page you are looking for no longer exist.<br/>Please refresh the page and remember: you havn’t seen anything';
export const page404HomeButton = 'Go to homepage';
export const page500ContentTitle = 'Ooops! You weren’t <br/> supposed to see this';
export const page500ContentText =
	'The page you are looking for no longer exist.<br/>Please refresh the page and remember: you havn’t seen anything';
export const page500HomeButton = 'Go to homepage';
export const POP_UP_402_FINGER_PRINT_USER_TITLE_TEXT = 'Oh ho! Please login to continue';
export const POP_UP_402_LOGGED_IN_USER_TITLE_TEXT = 'Upgrade your plan';
export const POP_UP_402_FINGER_PRINT_USER_SUB_TITLE_TEXT =
	'Oh ho! Looks like you have exhausted usage. Please login to unlock capabilities';
export const POP_UP_402_LOGGED_IN_USER_SUB_TITLE_TEXT =
	'Oh ho! Looks like your subscription has expired. View other available plans';
export const PAGE_403_CONTENT_TITLE = 'Sorry, you don’t have access to this Flo';
export const PAGE_403_REDIRECT_BTN_TEXT = 'Go to Inbox';

// Below are constants

export const HEADER_INBOX_ICON_TOOLTIP = 'Inbox';
export const HEADER_LOGO_TEXT = 'Floik';
export const HEADER_CREATE_FLO_BUTTON_TEXT = 'Create Flo';
export const HEADER_CREATE_FLO_BUTTON_TOOLTIP = 'Create Flo';
export const HEADER_NOTIFICATION_ICON_TOOLTIP = '';
export const HEADER_AVATAR_ICON_TOOLTIP = 'My account';
export const HEADER_LOGIN_BUTTON_TEXT = 'Login';
export const HEADER_LOGIN_BUTTON_TOOLTIP = 'Login';
export const PROFILE_MENU_EDIT_TEXT = 'Edit profile';
export const PROFILE_MENU_LOGOUT_TEXT = 'Logout';
export const PROFILE_MENU_THEME_ICON_TEXT = 'Change theme';
export const PROFILE_POPUP_TITLE_TEXT = "Hi, let your team know it's you";
export const PROFILE_POPUP_LOGGED_IN_SUB_TITLE_TEXT = 'You are currently logged in as ';
export const PROFILE_POPUP_GUEST_SUB_TITLE_TEXT = 'You have not signed in';
export const PROFILE_POPUP_NAME_TITLE_TEXT = 'Name';
export const PROFILE_POPUP_INPUT_BOX_PLACEHOLDER_TEXT = 'Enter your name';
export const PROFILE_POPUP_AVATAR_TITLE_TEXT = 'Choose an avatar';
export const PROFILE_POPUP_CLOSE_BUTTON_TEXT = 'Close';
export const PROFILE_POPUP_SKIP_BUTTON_TEXT = 'Skip';
export const PROFILE_POPUP_SAVE_BUTTON_TEXT = 'Save changes';
export const LOGIN_PAGE_TITLE_TEXT = '';
export const LOGIN_PAGE_GOOGLE_SSO_BUTTON_TEXT = '';
export const LOGIN_PAGE_CONSENT_TEXT_TEXT = '';
export const WELCOME_PAGE_TITLE_TEXT = 'Floik';
export const WELCOME_PAGE_SUB_TITLE_TEXT = 'Welcome to Floik';
export const FLO_PAGE_EMPTY_SCREEN_TITLE_TEXT =
	'Create your first Flo and collaborate asynchronously!';
export const FLO_PAGE_EMPTY_SCREEN_SUB_TITLE_TEXT = '';
export const FLO_PAGE_EMPTY_SCREEN_CREATE_FLO_BUTTON_TEXT = 'Create Flo';
export const INBOX_DRAWER_ALL_TAB_EMPTY_SCREEN_TITLE_TEXT =
	'Get faster and contextual feedback now!';
export const INBOX_DRAWER_MY_FLO_TAB_EMPTY_SCREEN_TITLE_TEXT =
	'Get faster and contextual feedback now!';
export const INBOX_DRAWER_SHARED_WITH_ME_TAB_EMPTY_SCREEN_TITLE_TEXT = 'No shared Flos';
export const INBOX_DRAWER_ALL_TAB_EMPTY_SCREEN_CLOSED_TITLE_TEXT =
	'You dont have any closed Flos. \nContinue collaborating on your active Flos.';
export const INBOX_DRAWER_SHARED_WITH_ME_TAB_EMPTY_SCREEN_CLOSED_TITLE_TEXT =
	'You dont have any closed Flos. \nContinue collaborating on your active Flos.';
export const INBOX_DRAWER_MY_FLO_TAB_EMPTY_SCREEN_CLOSED_TITLE_TEXT =
	'You dont have any closed Flos. \nContinue collaborating on your active Flos.';
export const INBOX_DRAWER_ALL_TAB_EMPTY_SCREEN_SUB_TITLE_TEXT =
	'Say bye to scheduling woes. Collaborate asynchronously.';
export const INBOX_DRAWER_MY_FLO_TAB_EMPTY_SCREEN_SUB_TITLE_TEXT =
	'Say bye to scheduling woes. Collaborate asynchronously.';
export const INBOX_DRAWER_SHARED_WITH_ME_TAB_EMPTY_SCREEN_SUB_TITLE_TEXT =
	'Say bye to scheduling woes. Collaborate asynchronously.';
export const INBOX_DRAWER_ALL_TAB_EMPTY_SCREEN_BUTTON_TEXT = 'Create Flo';
export const INBOX_DRAWER_MY_FLO_TAB_EMPTY_SCREEN_BUTTON_TEXT = 'Create Flo';
export const INBOX_DRAWER_SHARED_WITH_ME_TAB_EMPTY_SCREEN_BUTTON_TEXT = 'Create Flo';
export const INBOX_DRAWER_TAB_1_TEXT = 'All Flos';
export const INBOX_DRAWER_TAB_2_TEXT = 'My Flos';
export const INBOX_DRAWER_TAB_3_TEXT = 'Shared with me';
export const INBOX_DRAWER_SHOW_CLOSED_FLO_TOGGLE_TEXT = 'Show closed Flos';
export const INBOX_FLO_COUNT = 'Total Flos: ';
export const INBOX_DRAWER_FLO_ROW_FILM_STRIP_ICON_TOOLTIP = 'Duration';
export const INBOX_DRAWER_FLO_ROW_CREATED_BY_COLUMN_TEXT = 'Created by';
export const INBOX_DRAWER_FLO_ROW_CREATED_ON_COLUMN_TEXT = 'Created on';
export const INBOX_DRAWER_FLO_ROW_COMMENT_COUNT_SINGULAR_ICON_TOOLTIP = 'comment added';
export const INBOX_DRAWER_FLO_ROW_COMMENT_COUNT_PLURAL_ICON_TOOLTIP = 'comments added';
export const INBOX_DRAWER_FLO_ROW_DECISION_COUNT_SINGULAR_ICON_TOOLTIP = 'decision made';
export const INBOX_DRAWER_FLO_ROW_DECISION_COUNT_PLURAL_ICON_TOOLTIP = 'decisions made';
export const INBOX_DRAWER_FLO_ROW_FLO_STATUS_COLUMN_TEXT = '';
export const INBOX_DRAWER_FLO_ROW_PRIVACY_ICON_TOOLTIP = '';
export const INBOX_DRAWER_FLO_ROW_SHARE_ICON_TOOLTIP = 'Share Flo';
export const INBOX_DRAWER_FLO_ROW_DELETE_ICON_TOOLTIP = 'Delete Flo';
export const INBOX_DRAWER_FLO_ROW_LEAVE_ICON_TOOLTIP = 'Leave Flo';
export const INBOX_DRAWER_FLO_ROW_ACTION_MENU_SETTINGS_TEXT = 'Settings';
export const INBOX_DRAWER_FLO_ROW_ACTION_MENU_SHARE_FLO_TEXT = 'Share Flo';
export const INBOX_DRAWER_FLO_ROW_ACTION_MENU_DELETE_FLO_TEXT = 'Delete Flo';
export const INBOX_DRAWER_FLO_ROW_ACTION_MENU_LEAVE_FLO_TEXT = 'Leave Flo';
export const INBOX_DRAWER_PAGINATION_ICON_TEXT = 'Load more';
export const SHARE_POPUP_TITLE_TEXT = 'Share Flo';
export const SHARE_POPUP_SUB_TITLE_TEXT = 'Public';
export const SHARE_POPUP_SHARE_WITH_COLLABORATOR_TEXT = 'Share with collaborators';
export const SHARE_POPUP_SHARE_WITH_COLLABORATORS = 'Collaborators';
export const SHARE_POPUP_COPY_LINK_BUTTON_TEXT = 'Copy link';
export const SHARE_POPUP_COPIED_LINK_BUTTON_TEXT = 'Copied';
export const SHARE_POPUP_EMBED_BUTTON_TEXT = '</> Copy Embed Code';
export const SHARE_POPUP_SHARE_SUB_TITLE_1_TEXT = 'Share settings';
export const SHARE_POPUP_PUBLIC_ACCESS_RADIO_BUTTON_TEXT =
	'Allow access to only those who have commented';
export const SHARE_POPUP_PRIVATE_FLO_RADIO_BUTTON_TEXT = 'Allow access only to me'; // Get rid of it
export const SHARE_POPUP_SEND_LINK_BTN_TEXT = 'Share link';
export const SHARE_POPUP_SENDING_LINK_BTN_TEXT = 'Sending';
export const SHARE_POPUP_ADD_COLLAB_BTN_TEXT = 'Add';
export const SHARE_POPUP_ADDING_COLLAB_BTN_TEXT = 'Adding';
export const SHARE_POPUP_MAIL_INPUT_BOX_PLACEHOLDER_TEXT = 'Enter email addresses';
export const SHARE_POPUP_COLLABORATOR_PROFILE_TOOLTIP = 'Share Flo';
export const SHARE_POPUP_PRIVATE_FLO_TEXT =
	'*Your flo is private, use above settings to make it public';
export const SETTINGS_POPUP_TITLE_TEXT = 'Settings';
export const CLONE_FLO_POPUP_TITLE_TEXT = 'Clone Flo?';
export const CLONE_FLO_POPUP_SUB_TITLE_TEXT = 'Are you sure you want to clone this Flo?';
export const SETTINGS_POPUP_FLO_SUB_TITLE_1_TEXT = 'Flo status';
export const SETTINGS_POPUP_FLO_CLOSE_RADIO_BUTTON_TEXT = 'Mark Flo as closed';
export const DELETE_FLO_POPUP_TITLE_TEXT = 'Delete Flo?';
export const DELETE_FOLDER_POPUP_TITLE_TEXT = 'Delete Folder?';
export const DELETE_FLO_POPUP_SUB_TITLE_TEXT =
	'Are you sure you want to delete this Flo permanently?';

export const DELETE_FOLDER_POPUP_SUB_TITLE_TEXT =
	'Are you sure you want to delete this Folder permanently?';
export const DELETE_FLO_POPUP_DELETE_FLO_BUTTON_TEXT = 'Delete Flo';
export const DELETE_FOLDER_POPUP_DELETE_FLO_BUTTON_TEXT = 'Delete Folder';
export const FLO_STATUS_POPUP_CLOSED_TITLE_TEXT = 'Reopen this Flo for feedback';
export const FLO_STATUS_POPUP_ACTIVE_TITLE_TEXT = 'Close this Flo';
export const FLO_STATUS_POPUP_ACTIVE_BTN_TEXT = 'Confirm';
export const FLO_STATUS_POPUP_CLOSED_BTN_TEXT = 'Close Flo';
export const FLO_STATUS_POPUP_CLOSED_SUB_TITLE_TEXT =
	'Yay! Looks like you got all the feedback you were looking for. \n Closing this Flo will disable comment access for all collaborators. '; //Next line
export const FLO_STATUS_POPUP_ACTIVE_SUB_TITLE_TEXT =
	'Oh! Looks like this needs some more discussion. \nChanging status of this Flo to Active state will enable comment access for all collaborators.';
export const LEAVE_FLO_POPUP_TITLE_TEXT = 'Leave Flo?';
export const LEAVE_FOLDER_POPUP_TITLE_TEXT = 'Leave Folder?';
export const LEAVE_FLO_POPUP_SUB_TITLE_TEXT = 'Are you sure you want to leave this Flo?';
export const LEAVE_FOLDER_POPUP_SUB_TITLE_TEXT =
	'Are you sure you want to leave this Folder?';
export const LEAVE_FLO_POPUP_LEAVE_FLO_BUTTON_TEXT = 'Leave Flo';
export const LEAVE_FOLDER_POPUP_LEAVE_FLO_BUTTON_TEXT = 'Leave Folder';
export const FLO_PAGE_TOOL_BAR_RECTANGLE_INACTIVE_ANNOTATION_ICON_TOOLTIP =
	'Click to add Annotations';
export const FLO_PAGE_TOOL_BAR_RECTANGLE_ACTIVE_ANNOTATION_ICON_TOOLTIP =
	'Click to hide annotations';
export const FLO_PAGE_TOOL_BAR_MORE_OPTIONS_TOOLTIP = 'More options';
export const FLO_PAGE_TOOL_BAR_SHARE_FLO_TOOLTIP = 'Share Flo';
export const FLO_PAGE_TOOL_BAR_RECTANGLE_ANNOTATION_ICON_TOOLTIP =
	'Add rectangle annotation';
export const FLO_PAGE_TOOL_BAR_SHARE_ICON_TOOLTIP = 'Share Flo';
export const FLO_PAGE_TOOL_BAR_STATUS_DEFAULT = 'Flo status';
export const FLO_PAGE_TOOL_BAR_STATUS_ACTIVE = 'Open for comments';
export const FLO_PAGE_TOOL_BAR_STATUS_CLOSED = 'Closed for collaboration';
export const FLO_PAGE_TOOL_BAR_STATUS_DRAFT = 'Draft';
export const FLO_PAGE_TOOL_BAR_STATUS_PROCESSING = 'Processing';

export const FLO_PAGE_TOOL_BAR_SETTINGS_ICON_TOOLTIP = 'Flo settings';
export const FLO_PAGE_TOOL_BAR_FLO_STATUS_TEXT = '';
export const FLO_PAGE_TOOL_BAR_ELLIPSIS_MENU_DELETE_FLO_TEXT = 'Delete Flo';
export const FLO_PAGE_TOOL_BAR_ELLIPSIS_MENU_LEAVE_FLO_TEXT = 'Leave Flo';
export const FLO_PAGE_VIDEO_CONTROL_BAR_PLAY_ICON_TOOLTIP = '';
export const FLO_PAGE_VIDEO_CONTROL_BAR_PAUSE_ICON_TOOLTIP = '';
export const FLO_PAGE_VIDEO_CONTROL_BAR_SPEAKER_ICON_TOOLTIP = '';
export const FLO_PAGE_VIDEO_CONTROL_BAR_RESOLUTION_SELECTION_TOOLTIP = '';
export const FLO_PAGE_PROCESSING_TITLE = 'Almost there';
export const FLO_PAGE_PROCESSING_SUB_TITLE =
	'We are processing your flo. This might take few seconds.';
export const COMMENTS_SECTION_TAB_1_TEXT = 'Discussion';
export const COMMENTS_SECTION_TAB_2_TEXT = 'Outcomes'; //Remove this
export const COMMENTS_SECTION_COMMENTS_TAB_COMMENT_SINGULAR_COUNT_TITLE_TEXT = 'comment';
export const COMMENTS_SECTION_COMMENTS_TAB_COMMENT_PLURAL_COUNT_TITLE_TEXT = 'comments';
export const COMMENTS_SECTION_COMMENTS_TAB_AVATAR_ICON_TOOLTIP = '';
export const COMMENTS_SECTION_COMMENTS_TAB_VIDEO_TIME_TOOLTIP =
	'Click to seek to video time';
export const COMMENTS_SECTION_COMMENTS_TAB_DATE_TIME_TOOLTIP = '';
export const COMMENTS_SECTION_COMMENTS_TAB_DECISION_ICON_TOOLTIP = 'Mark it as decision';
export const COMMENTS_SECTION_COMMENTS_TAB_RESOLVED_ICON_TOOLTIP = 'Mark it as resolved';
export const COMMENTS_SECTION_COMMENTS_TAB_MORE_ICON_TOOLTIP = 'More options';
export const COMMENTS_SECTION_COMMENTS_TAB_REPLY_NOW_TEXT = 'Reply now';
export const COMMENTS_SECTION_COMMENTS_TAB_COMMENT_INPUT_BOX_PLACEHOLDER_TEXT =
	'Ask a question or share feedback';
export const COMMENTS_SECTION_COMMENTS_TAB_COMMENT_REPLY_INPUT_BOX_PLACEHOLDER_TEXT =
	'Reply to a comment or share feedback';
export const COMMENTS_SECTION_COMMENTS_TAB_COMMENT_SEND_BUTTON_TOOLTIP =
	'Ask a question or share feedback';
export const COMMENTS_SECTION_COMMENTS_TAB_COMMENT_SEND_REPLY_BUTTON_TOOLTIP =
	'Reply to a comment or share feedback';
export const COMMENTS_SECTION_COMMENTS_TAB_EMPTY_SCREEN_TITLE_TEXT =
	'Click anywhere on the video to ask a question or give feedback';
export const COMMENTS_SECTION_COMMENTS_TAB_COMMENT_INPUT_LEAVE_WARNING =
	'Complete the pending comment';

export const GENERAL_ERRORS_SCREEN_RESOLUTION_TEXT =
	'Use full screen for better experience';
export const GENERAL_TAB_TEXT = '';

export const NEW_FLO_TITLE_TEXT = 'Recording settings';
export const NEW_FLO_SCREEN_OPTION_TEXT = 'Screen only';
export const NEW_FLO_CAMERA_OPTION_TEXT = 'Camera only';
export const NEW_FLO_SCREEN_CAMERA_OPTION_TEXT = 'Screen + camera';
export const NEW_FLO_SCREENSHOT_OPTION_TEXT = 'Screenshot';
export const NEW_FLO_CONTINUE_BTN_TEXT = 'Resume';
export const NEW_FLO_CONTINUE_BTN_TOOLTIP = 'Resume recording';
export const NEW_FLO_PAUSE_BTN_TEXT = 'Pause';
export const NEW_FLO_PAUSE_BTN_TOOLTIP = 'Pause recording';
export const NEW_FLO_END_BTN_TEXT = 'Done';
export const NEW_FLO_END_BTN_TOOLTIP = 'Finish recording';
export const NEW_FLO_NAME_INPUT_MANDATORY_PLACEHOLDER = 'Enter Flo name';
export const NEW_FLO_NAME_INPUT_PLACEHOLDER = 'Flo name (optional)';
export const NEW_FLO_SAVE_BTN_TEXT = 'Save Flo';
export const NEW_FLO_DISCARD_BTN_TEXT = 'Delete';
export const EDIT_FLO_DISCARD_BTN_TEXT = 'Discard';
export const NEW_FLO_START_FLO_BTN_TEXT = 'Start recording';
export const NEW_FLO_DISCARD_POP_UP_TITLE_TEXT = 'Delete this Flo?';
export const NEW_FLO_DISCARD_POP_UP_SUB_TITLE_TEXT =
	'Are you sure you want to permanently delete this recording?';
export const NEW_FLO_DISCARD_POP_UP_BTN_TYPE = 'delete';
export const NEW_FLO_DISCARD_POP_UP_BTN_TEXT = 'Delete';

export const EDIT_FLO_DISCARD_POP_UP_TITLE_TEXT = 'Discard the changes?';
export const EDIT_FLO_DISCARD_POP_UP_SUB_TITLE_TEXT =
	'Are you sure you want to discard changes?';
export const EDIT_FLO_DISCARD_POP_UP_BTN_TYPE = 'delete';
export const EDIT_FLO_DISCARD_POP_UP_BTN_TEXT = 'Discard';

export const NEW_FLO_PROCESS_FLO_LOADER_TITLE_TEXT = 'Processing the Flo';
export const NEW_FLO_SAVE_FLO_LOADER_TITLE_TEXT = 'Saving the Flo';
export const NEW_FLO_DISCARD_FLO_LOADER_TITLE_TEXT = 'Discarding the Flo';
export const NEW_FLO_PROCESS_FLO_LOADER_SUB_TITLE_TEXT = 'This might take few seconds';
export const NEW_FLO_SAVE_FLO_LOADER_SUB_TITLE_TEXT = 'This might take a few seconds';
export const NEW_FLO_DISCARD_FLO_LOADER_SUB_TITLE_TEXT = 'This might take a few seconds';
export const NEW_FLO_PERMISSION_TEXT = 'Choose your devices';
export const NEW_FLO_PERMISSIONS_POP_UP_TITLE_TEXT = 'Permission needed';
export const NEW_FLO_PERMISSIONS_POP_UP_SUB_TITLE_TEXT =
	'Allow Floik browser permissions to create a Flo';
export const NEW_FLO_RECORDING_TITLE_TEXT = 'Recording';
export const NEW_FLO_PAUSED_TITLE_TEXT = 'Paused';
export const NEW_FLO_SCREEN_SHARE_ENDED_ALERT =
	"'Recording stopped because screen share has been ended'";
export const NEW_FLO_PERMISSIONS_ERROR_POP_UP_TITLE_TEXT = 'Error';
export const NEW_FLO_PERMISSIONS_ERROR_POP_UP_SUB_TITLE_TEXT =
	'Failed to gain access Microphone / Camera, please enable to create flo.';

export const NEW_FLO_COUNTDOWN_TITLE = 'Recording starts in';
export const NEW_FLO_COUNTDOWN_SUB_TITLE = 'Take a deep breath';
export const HEADER_CREATED_BY_PREFIX_TEXT = 'by';

export const ANNOTATION_FCM_EVENT_ADD_ANNOTATION = 'add_annotation';
export const ANNOTATION_FCM_EVENT_ADD_REPLY = 'add_reply';
export const ANNOTATION_FCM_EVENT_DELETE_ANNOTATION = 'delete_annotation';
export const ANNOTATION_FCM_EVENT_DELETE_REPLY = 'delete_reply';
export const ANNOTATION_FCM_EVENT_MARK_DECISION = 'mark_decision';
export const ANNOTATION_FCM_EVENT_MARK_RESOLVED = 'mark_resolved';
export const ANNOTATION_FCM_EVENT_COUNT_COMMENT = 'count_comment';
export const ANNOTATION_FCM_EVENT_COUNT_REPLY = 'count_reply';
export const ANNOTATION_FCM_EVENT_FLO_UPDATED = 'flo_updated';
export const ANNOTATION_FCM_EVENT_FLO_DELETED = 'flo_deleted';
export const ANNOTATION_FCM_EVENT_FLO_DURATION = 'flo_duration';
export const ANNOTATION_FCM_EVENT_EDIT_ANNOTATION = 'edit_annotation';
export const ANNOTATION_FCM_EVENT_EDIT_REPLY = 'edit_reply';

export const ANNOTATION_FCM_EVENT_FLO_PUBLIC_ACCESS = 'flo_toggle_public_access';
export const ANNOTATION_FCM_EVENT_FLO_PRIVATE_ACCESS = 'flo_toggle_private_access';
export const ANNOTATION_FCM_EVENT_FLO_INVITE_ACCESS = 'flo_toggle_invite_access';
export const ANNOTATION_FCM_EVENT_FLO_RENAMED = 'flo_renamed';
export const ANNOTATION_FCM_EVENT_FLO_CLOSED = 'flo_closed';
export const ANNOTATION_FCM_EVENT_FLO_ACTIVE = 'flo_active';
export const FLO_PAGE_VIDEO_DURATION = 'Time';
export const FLO_PAGE_VIDEO_PLAYBACK_RATE = 'Speed';
export const FLO_PAGE_VIDEO_QUALITY = 'Qaulity';
export const FLO_PAGE_VIDEO_VOLUME = 'Volume';
export const FLO_PAGE_VIDEO_PLAY = 'Play';
export const FLO_PAGE_VIDEO_NEXT = 'Next';
export const FLO_PAGE_VIDEO_PREVIOUS = 'Previous';
export const FLO_PAGE_ID_RESTART = 'Restart';
export const FLO_PAGE_SHARE_FLO_TOOLTIP = 'Share Flo';
export const FLO_PAGE_SHARE_LINK_TOOLTIP = 'Share link';
export const FLO_PAGE_SHARE_LINK_HEADING = 'Share';
export const FLO_PAGE_SHARE_LINK_POPUP_CLOSE_TOOLTIP = 'Close';
export const FLO_LINK_COPIED_MESSAGE = 'Flo link copied to clipboard';
export const FLO_PAGE_VIDEO_MUTE = 'Mute';
export const FLO_PAGE_VIDEO_MINIMIZE = 'Minimize';
export const FLO_PAGE_VIDEO_MORE = 'More';
export const FLO_PAGE_VIDEO_MAXIMIZE = 'Maximize';
export const FLO_PAGE_VIDEO_UNMUTE = 'Unmute';
export const FLO_PAGE_VIDEO_PAUSE = 'Pause';
export const FLO_PAGE_ADD_CTA = 'Add CTA';
export const FLO_PAGE_UPDATE_CTA = 'Upddate CTA';
//  Discard this recording and start over
// You dont have any shared Flos
// Collaborate asynchronously
export const EXTENSION_SAVE_TOKEN = 'floik/popup/extension-save-token';
export const PUBLISHED_FLO_COMMENT_PLACEHOLDER_QUESTION = 'Enter your question here...';
export const PUBLISHED_FLO_COMMENT_PLACEHOLDER_NAME = 'Enter your name';
export const PUBLISHED_FLO_COMMENT_PLACEHOLDER_EMAIL = 'Enter your email';
export const PUBLISHED_FLO_COMMENT_QUESTION_LABEL = 'Ask a question *';
export const PUBLISHED_FLO_COMMENT_NAME_LABEL = 'Name';
export const PUBLISHED_FLO_COMMENT_EMAIL_LABEL = 'Email';
export const PUBLISHED_FLO_VIDEO_BANNER =
	'Click and drag anywhere on the video to leave comment';
export const SYNTHESISED_AI_VOICE = 'AI_VOICE';
export const DIMENSIONS_ERROR_MESSAGE =
	'The available space is not enough to render the Flo properly.';
export const FAILED_TO_LOAD_SWIFT_IMAGE =
	'Error loading Flo. Check your network connection and refresh the page.';
export const SWIFT_PLAY_FLO_LOADING_TEXT = 'Loading Flo...';

export const FLO_START_BUTTON_TEXT = 'Start now';
export const FLOIK_WEBSITE_LINK = 'https://www.floik.com/';
export const IMAGE_LOAD_TIMEOUT = 3000;
export const SWIFT_PLAY_IMAGES_LOAD_SIZE = 10;
export const IMAGE_NEXT_BUTTON_TOOLTIP = 'Next';

export const FLO_ELEMENT_BLUR_TYPE = 'blur';
export const FLO_ELEMENT_TEXT_TYPE = 'text';
export const FLO_ELEMENT_SPOTLIGHT_TYPE = 'spotlight';

export const FLOIK_OG_TAGS_LIST = [
	{
		property: 'og:image',
		content: '',
	},
	{
		property: 'og:image:type',
		content: 'image/png',
	},
	{
		property: 'og:image:height',
		content: '655',
	},
	{
		property: 'og:image:width',
		content: '1280',
	},
];
